import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import styled from 'styled-components';

export const CustomCheckbox = ({ errorMessage, children, ...inputProps }) => {
  return (
    <Wrapper>
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          {...inputProps}
        />
        {children}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

CustomCheckbox.propTypes = {
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
