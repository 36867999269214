import PropTypes from 'prop-types';
import { fontSizes } from 'style/fontSizes';
import styled from 'styled-components';

const ExpandedRow = ({ expandedRowText, expandedRowDateTime }) => {
  return (
    <Wrapper>
      <Text>{expandedRowText}</Text>
      <DateTime>{expandedRowDateTime}</DateTime>
    </Wrapper>
  );
};

ExpandedRow.propTypes = {
  expandedRowText: PropTypes.any,
  expandedRowDateTime: PropTypes.any,
};

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.inputFocusColor};
`;

const DateTime = styled.div`
  font-size: ${fontSizes.xs};
  line-height: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.lightBlackColor};
  white-space: nowrap;
`;

const Text = styled.div`
  font-size: ${fontSizes.md};
  line-height: 18px;
  color: ${(props) => props.theme.colors.table.lightBlackColor};
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
`;

export default ExpandedRow;
