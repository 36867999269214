import PropTypes from 'prop-types';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import buttonTypes from 'utils/constants/buttonTypes';

const CustomButton = ({
  children,
  color,
  backgroundColor,
  width,
  height,
  border,
  onClick,
  className,
  style,
  type,
  disabled,
  isRounded,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickOnButton = async () => {
    if (typeof onClick === 'function' && !isLoading) {
      try {
        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  return (
    <CButton
      isLoading={isLoading}
      isRounded={isRounded}
      disabled={disabled || isLoading}
      width={width}
      height={height}
      border={border}
      className={`
        ${type}
        ${className}
      `}
      onClick={onClickOnButton}
      backgroundColor={backgroundColor}
      color={color}
      style={style}
    >
      <Content className="p-1">
        {isLoading ? (
          <Spinner
            size={'sm'}
            type="border"
          />
        ) : (
          children
        )}
      </Content>
    </CButton>
  );
};

CustomButton.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  onClick: PropTypes.func,
  width: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(Object.values(buttonTypes)),
  disabled: PropTypes.bool,
  isRounded: PropTypes.bool,
};

export default CustomButton;

const CButton = styled.button`
  position: relative;
  display: flex;
  outline: none;
  border-radius: ${(props) => (props.isRounded ? '50%' : '6px')};
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  overflow: hidden;
  justify-content: center;
  padding: 0px;
  transition: 0.1s;
  border: 0px;
  border: ${(props) => props.border || ''};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  background: ${(props) => props.backgroundColor || ''};
  color: ${(props) => props.color || ''};
  letter-spacing: 0.03rem;
  font-weight: 700;
  opacity: ${(props) => (props.isLoading ? '0.75' : '1')};

  :hover {
    opacity: 0.75;
  }

  // When anyone type button are disabled
  :disabled {
    background: ${(props) => `${props.theme.colors.disabled} !important`};

    color: ${(props) => `${props.theme.colors.textSecondary} !important`};

    box-shadow: none !important;
    border: none !important;

    :hover {
      opacity: 1;
    }
  }

  // Standard type
  &.${buttonTypes.standard} {
    box-shadow: 0px 1px 6px rgba(14, 18, 62, 0.15);

    color: ${(props) => props.theme.colors.buttons.standard.color};
    background-color: ${(props) =>
      props.theme.colors.buttons.standard.background};
  }

  // Outline standard type
  &.${buttonTypes.outlineStandard} {
    color: ${(props) => props.theme.colors.textPrimary};
    background-color: ${(props) =>
      props.theme.colors.buttons.outlineStandard.background};
    border: 1px solid;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    border-color: ${(props) =>
      props.theme.colors.buttons.outlineStandard.borderColor};
  }

  // Primary type
  &.${buttonTypes.primary} {
    /* box-shadow: 0px 1px 6px rgba(14, 18, 62, 0.15); */

    background: ${(props) => props.theme.colors.lightBlueColor};
    color: ${(props) => props.theme.colors.whiteColor};
  }

  // Secondary type
  &.${buttonTypes.secondary} {
    background-color: ${(props) => props.theme.colors.darkBlueColor};
    color: ${(props) => props.theme.colors.whiteColor};
    //border: none;
    outline: none;
    box-shadow: none;
  }

  &.${buttonTypes.white} {
    background-color: ${(props) => props.theme.colors.whiteColor};
    color: ${(props) => props.theme.colors.darkBlueColor};
    outline: none;
    box-shadow: none;
  }

  // Outline Secondary type
  &.${buttonTypes.outlineSecondary} {
    color: ${(props) => props.theme.colors.buttons.outlineSecondary.color};
    box-shadow: none;
    border: 1px solid;
    border-color: ${(props) =>
      props.theme.colors.buttons.outlineSecondary.borderColor};
    background: ${(props) =>
      props.theme.colors.buttons.outlineSecondary.background};

    :hover {
      background-color: ${(props) =>
        props.theme.colors.buttons.outlineSecondary.hoveredBg};

      opacity: 1;
    }
  }

  // Warning type
  &.${buttonTypes.warning} {
    box-shadow: 0 1px 6px rgba(14, 18, 62, 0.15);
    background-color: ${(props) => props.theme.colors.dangerColor};
    color: ${(props) => props.theme.colors.whiteColor};
  }
`;

const Content = styled.div`
  width: 100%;
`;
