import api from 'api';
import { usePollListStore } from 'store/PollListStore';
const getUrlPrefix = (flatId) => {
  return '/flats/' + flatId + '/polls';
};
export const getPollsApi = async (flatId, page, limit, status) => {
  const url = getUrlPrefix(flatId);
  const res = await api.get(
    `${url}?page=${page}&limit=${limit}&status=${status}`
  );
  usePollListStore.setState({ pollsList: res.data });
  return res.data;
};

export const postVoteApi = async (flatId, pollId, voteValue) => {
  const url = getUrlPrefix(flatId);
  const res = await api.post(`${url}/${pollId}/options`, {
    option_type: voteValue.value,
  });
  return res.data;
};
