const buttonTypes = {
  // standard: 'standard',
  outlineStandard: 'outline-standard',
  primary: 'primary',
  secondary: 'secondary',
  // outlineSecondary: 'outlineSecondary',
  warning: 'warning',
  white: 'white',
};

export default buttonTypes;
