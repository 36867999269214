import ScheduleUpdateModal from 'components/Modals/ScheduleUpdateModal';
import PollDetailsModal from '../../components/Modals/PollDetailsModal';
import TermsModal from '../../components/Modals/TermsModal';
import ProblemsModal from '../../components/Modals/ProblemsModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import ChangePasswordModal from '../../components/Modals/ChangePasswordModal';
import ForgotPasswordModal from '../../components/Modals/ForgotPasswordModal';
import ChoseFlatModal from 'components/Modals/ChooseFlatModal';
import AddFlatModal from 'components/Modals/AddFlatModal';

//  Here we saved modal type names - and we used one of that value on modalToggle(type) function - when we want to open modal.
export const modalTypeNames = {
  scheduleUpdateModal: 'scheduleUpdateModal',
  pollDetailsModal: 'pollDetailsModal',
  termsModal: 'termsModal',
  problemsModal: 'problemsModal',
  confirmationModal: 'confirmationModal',
  changePasswordModal: 'changePasswordModal',
  forgotPasswordModal: 'forgotPasswordModal',
  chooseFlatModal: 'chooseFlatModal',
  addFlatModal: 'addFlatModal',
};

// Here we map the modalTypeName -> with -> the ModalComponent which handle selected type name
export const ModalTypes = {
  [modalTypeNames.scheduleUpdateModal]: ScheduleUpdateModal,
  [modalTypeNames.pollDetailsModal]: PollDetailsModal,
  [modalTypeNames.termsModal]: TermsModal,
  [modalTypeNames.problemsModal]: ProblemsModal,
  [modalTypeNames.confirmationModal]: ConfirmationModal,
  [modalTypeNames.changePasswordModal]: ChangePasswordModal,
  [modalTypeNames.forgotPasswordModal]: ForgotPasswordModal,
  [modalTypeNames.forgotPasswordModal]: ForgotPasswordModal,
  [modalTypeNames.chooseFlatModal]: ChoseFlatModal,
  [modalTypeNames.addFlatModal]: AddFlatModal,
};
