const SvgComponent = (props) => (
    <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.52633 3.17299H9.47708V1.80641C9.47708 1.74871 9.45304 1.69582 9.40497 1.64775C9.35688 1.59966 9.30399 1.57562 9.24629 1.57562H4.75712C4.69702 1.57562 4.64354 1.59966 4.59666 1.64775C4.54977 1.69582 4.52633 1.74871 4.52633 1.80641V3.17299ZM0.668199 11.4734C0.490074 11.4734 0.338573 11.411 0.213698 11.2861C0.0888109 11.1612 0.0263672 11.0097 0.0263672 10.8316V7.84605H2.64415V8.14894C2.64415 8.29436 2.69582 8.41785 2.79915 8.51942C2.90249 8.62098 3.02578 8.67176 3.16902 8.67176C3.31227 8.67176 3.43548 8.62098 3.53865 8.51942C3.64182 8.41785 3.6934 8.29436 3.6934 8.14894V7.84605H10.3064V8.14894C10.3064 8.29436 10.3581 8.41785 10.4614 8.51942C10.5648 8.62098 10.688 8.67176 10.8313 8.67176C10.9745 8.67176 11.0977 8.62098 11.2009 8.51942C11.3041 8.41785 11.3557 8.29436 11.3557 8.14894V7.84605H13.9734V10.8316C13.9734 11.0097 13.9109 11.1612 13.7858 11.2861C13.6608 11.411 13.5106 11.4734 13.3352 11.4734H0.668199ZM0.0263672 7.22225V4.45543C0.0263672 4.09837 0.150842 3.79536 0.399792 3.54641C0.648742 3.29746 0.950786 3.17299 1.30592 3.17299H3.47708V1.80559C3.47708 1.44989 3.60092 1.14776 3.84859 0.899211C4.09625 0.650649 4.3991 0.526367 4.75712 0.526367H9.24629C9.60176 0.526367 9.90397 0.650842 10.1529 0.899792C10.4019 1.14874 10.5263 1.45095 10.5263 1.80641V3.17299H12.6947C13.0493 3.17299 13.3511 3.29746 13.6 3.54641C13.849 3.79536 13.9734 4.09837 13.9734 4.45543V7.22225H11.3557V6.49749C11.3557 6.35207 11.3042 6.22797 11.2012 6.12521C11.0982 6.02245 10.9753 5.97107 10.8326 5.97107C10.6871 5.97107 10.563 6.02245 10.4604 6.12521C10.3577 6.22797 10.3064 6.35207 10.3064 6.49749V7.22225H3.6934V6.49749C3.6934 6.35207 3.6419 6.22797 3.53892 6.12521C3.43593 6.02245 3.31307 5.97107 3.17033 5.97107C3.02483 5.97107 2.90075 6.02245 2.7981 6.12521C2.69547 6.22797 2.64415 6.35207 2.64415 6.49749V7.22225H0.0263672Z"
            fill={props?.fill ? props.fill : 'black'}
        />

    </svg>
);

export default SvgComponent;
