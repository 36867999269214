import { useModalStore } from 'store/ModalStore';
import { useState } from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const TermsModal = () => {
    const toggleModal = useModalStore((state) => state.toggleModal);
    const [status, setStatus] = useState('getting status...');

    const modalProps = useModalStore((state) => state.modalProps);
    const modalName = modalProps?.modalName;

    return (
        <Wrapper>
            <ModalHeader>
                <div className="modal-title">
                    { modalName }
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="modal-text">
                    <div className="text-wrapper">
                        <p>
                            Hvala što ste posetili naš internet sajt Troškovi.com. Vaš pristup, kao i korišćenje ovog našeg internet sajta (u daljem tekstu: internet sajt) podleže ovim uslovima korišćenja i važećim zakonskim propisima koji uređuju ovu oblast. Pristupom i korišćenjem internet sajta, prihvatate bez ograničenja, ove uslove korišćenja. Ukoliko ne prihvatate ove uslove korišćenja bez ograničenja, molimo Vas da napustite internet sajt.
                        </p>
                        <p>
                            Korišćenjem mobilne aplikacije Troskovi (u daljem tekstu: mobilna aplikacija) i internet sajta, automatski prihvatate sve aktuelne uslove korišćenja.
                            Korisnici su dužni da redovno čitaju uslove korišćenja, te se smatra da su korisnici kontinuiranim korišćenjem Troskovi.com sajta, mobilne aplikacije ili bilo kojeg njihovog dela, u svakom trenutku upoznati s aktuelnim uslovima korišćenja, te da su ih razumeli u celosti.
                            Ni jedan deo Troskovi.com sajta ili mobilne aplikacije ne sme se koristiti u nezakonite svrhe, ni za promovisanje istih.
                        </p>
                        <h4>
                            Vlasništvo sadržaja
                        </h4>
                        <p>
                            Internet sajt, mobilna aplikacija i svi tekstovi, logotipi, grafika, slike, izveštaji i ostali materijal na ovom internet sajtu i mobilnoj aplikaciji (u daljem tekstu: Sadržaj), su u vlasništvu firme Troškovi.com, ili su na internet sajt postavljeni uz dozvolu vlasnika ili ovlašćenog nosioca prava. Korišćenje Sadržaja, osim na način opisan u ovim uslovima korišćenja, bez pisane dozvole vlasnika Sadržaja je strogo zabranjeno. Troškovi.com će zaštiti svoja autorska prava, svoja prava intelektualne svojine i ostala srodna prava, kao i druga prava, u najvećoj meri dozvoljenoj zakonom, uključujući i krivično gonjenje.
                        </p>
                        <h4>
                            Vaša upotreba internet sajta i mobilne aplikacije
                        </h4>
                        <p>
                            Troškovi.com čuva privatnost svih korisnika ovog internet sajta kao i mobilne aplikacije i štiti lične podatke korisnika internet sajta i mobilne aplikacije. Promene na internet sajtu i mobilnoj aplikaciji se mogu napraviti periodično u bilo kom trenutku i bez obaveštenja. Međutim, Troškovi.com se ne obavezuje da redovno ažurira informacije sadržane na ovom internet sajtu i mobilnoj aplikaciji. Takođe, Troškovi.com ne garantuje da će internet sajt i mobilna aplikacija funkcionisati bez prekida ili grešaka, da će nedostaci biti blagovremeno ispravljani ili da su internet sajt i mobilna aplikacija kompatibilni sa vašim hardverom ili softverom.
                        </p>
                        <h4>
                            Izuzeće od odgovornosti
                        </h4>
                        <p>
                            Internet sajt i mobilnu aplikacija koristite na sopstveni rizik. Troškovi.com nije odgovoran za materijalnu ili nematerijalnu štetu, direktnu ili indirektnu koja nastane iz korišćenja ili je u nekoj vezi sa korišćenjem internet sajta, mobilne aplikacije ili njihovog Sadržaja. Sva izračunavanja i izveštaji koji se nalaze na sajtu su informativnog karaktera. Sajt ne garantuje za tačnost podataka i ne snosi nikakvu odgovornost.
                        </p>
                        <h4>
                            Linkovi sa internet sajtovima trećih lica
                        </h4>
                        <p>
                            Internet sajt i mobilna aplikacija mogu sadržati linkove drugih internet sajtova čiji vlasnik ili korisnik nije Troškovi.com. Takvi linkovi su obezbeđeni isključivo da bi Vama pružili što više informacija. Troškovi.com ne kontroliše i nije odgovoran za rad, sadržaj, politiku privatnosti ili bezbednost ovih sajtova. Troškovi.com ne kontroliše sadržaj ili proizvode ili usluge dostupne na ovim internet sajtovima. Ako uspostavite vezu sa takvim internet sajtovima preko linka na našem internet sajtu, to činite na sopstveni rizik i bez saglasnosti firme Troškovi.com.
                        </p>
                        <h4>
                            Izmena uslova korišćenja
                        </h4>
                        <p>
                            Troškovi.com može u svakom trenutku izmeniti ove uslove korišćenja tako što će ažurirati ovaj tekst. Vi ćete automatski biti obavezani novim uslovima korišćenja sadržanim u izmenama, te bi iz tog razloga trebalo da periodično posetite ovu stranicu radi upoznavanja sa trenutno važećim uslovima korišćenja.
                        </p>
                    </div>
                </div>
            </ModalBody>
        </Wrapper>
    );
};

TermsModal.propTypes = {
    terms: PropTypes.any,
};

const Wrapper = styled.div`
  .modal-body {
    flex-direction: column;
    display: flex;
    gap: 12px;
    font-weight: 500;
  }
`;

const ModalHeader = styled.div`
  border-bottom: 0;

    .modal-title {
      padding: 2rem 3rem 1rem 3.6rem;
      font-size: 22px;
      font-weight: 500;
      border-radius: 8px 8px 0 0;
      border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
    }
`;

const ModalBody = styled.div`
  font-size: 0.875rem;
  flex-direction: column;
  display: flex;
  gap: 12px;
  font-weight: 500;
  padding: 2rem 3.6rem 3rem 3.6rem;
  height: 600px;
  
  .modal-text {
    background-color: ${(props) => props.theme.colors.whiteColor};
    overflow-y: auto;
    font-size: 16px;
    
    .text-wrapper {
      background-color: ${(props) => props.theme.colors.inputFocusColor};
      margin-right: .5rem;
      padding: 1rem;
    }
    
    h4 {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

export default TermsModal;
