import api from 'api';
export const getUserFlats = async () => {
  const res = await api.get(`/profile/list`);
  const responseData = res.data.data;
  const buildingNames = Object.keys(responseData);
  const flatsWithBuildingAddress = buildingNames.flatMap((buildingAddress) => {
    const buildingFlats = responseData[buildingAddress];
    const buildingFlatsWithAddress = buildingFlats.map((flat) => ({
      ...flat,
      buildingAddress: buildingAddress,
    }));
    return buildingFlatsWithAddress;
  });
  return flatsWithBuildingAddress;
};

export const addFlatToResident = async (hashCode) => {
  const res = await api.post(`/profile/add-new-flat`, { code: hashCode });
  return res;
};
