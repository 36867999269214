import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getRoutesAsArray } from 'routes';
import GlobalStyle from 'style/GlobalStyle';
import { lightTheme } from 'style/themes';
import { ThemeProvider } from 'styled-components';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Page from 'components/Page';

export default function App() {
  const routes = getRoutesAsArray();

  const renderRoutes = () => {
    return routes.map((route) => {
      return (
        <Route
          key={route.name}
          path={route.path}
          element={
            <Page
              key={route.name}
              routeProtection={route.routeProtection}
              component={route.component}
              path={route.path}
              name={route.name}
              headline={route.headline}
              Actions={route.Actions}
              dashboardBoxes={route.dashboardBoxes}
              FooterActions={route.FooterActions}
              FooterInfo={route.FooterInfo}
            />
          }
        />
      );
    });
  };

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyle />
          <Routes>{renderRoutes()}</Routes>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}
