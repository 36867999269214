export const problemTypes = [
    {
        type: "1",
        text: "Komunalna policija",
        subtypes: [
            {
                type: "2",
                text: "Buka",
            },
            {
                type: "3",
                text: "Ljubimci",
            },
            {
                type: "4",
                text: "Parking",
            },
        ],
    },
    {
        type: "2",
        text: "Instalacije",
        subtypes: [
            {
                type: "5",
                text: "Struja",
            },
            {
                type: "6",
                text: "Voda",
            },
            {
                type: "7",
                text: "Kanalizacija",
            },
        ],
    },
    {
        type: "3",
        text: "Lift",
        subtypes: [
            {
                type: "8",
                text: "Svetlo",
            },
            {
                type: "9",
                text: "Vrata",
            },
            {
                type: "10",
                text: "Zastoj u radu",
            },
        ],
    },
    {
        type: "4",
        text: "Uređaji i oprema",
        subtypes: [
            {
                type: "11",
                text: "Interfon",
            },
            {
                type: "12",
                text: "Oprema za video nadzor",
            },
            {
                type: "13",
                text: "Grejna tela",
            },
            {
                type: "14",
                text: "Pumpa",
            },
        ],
    },
    {
        type: "5",
        text: "Zgrada",
        subtypes: [
            {
                type: "15",
                text: "Fasada",
            },
            {
                type: "16",
                text: "Krov",
            },
            {
                type: "17",
                text: "Podstanica",
            },
            {
                type: "18",
                text: "Oluk",
            },
        ],
    },
    {
        type: "6",
        text: "Ostalo",
    },
];