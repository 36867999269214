import pxToRem from 'utils/helpers/pxToRem';
import SidebarIcon from 'assets/side/SidebarIcon';
import Sidebar from '../Sidebar';
import CustomModal from 'components/Common/CustomModal';
import { useModalStore } from 'store/ModalStore';
import { breakpoints } from 'style/breakpoints';
import { useGlobalStore } from 'store/GlobalStore';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import { TenatPersonalInfoBox } from '../../Common/CustomBox/TenatPersonalInfoBox';
import PropTypes from 'prop-types';
import { dashboardBoxes as dashboardBoxesTypes } from 'utils/constants/dashboardBoxes';
import { TenantInfo } from 'components/Common/CustomBox/TenantInfoBox';
import { TenantDebtCustomBox } from '../../Common/CustomBox/TenantDebtCustomBox';
import { TenantDebtGraph } from '../../Common/CustomBox/TenantDebtGraph';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { getDashboardApi } from 'api/dashboard';
import { useDashboardStore } from 'store/DashboardStore';
import { StatisticsBox } from '../../Common/StatisticsBox';
import accountBalanceIcon from '../../../assets/img/account_balance.svg';
import notificationIcon from '../../../assets/img/notification.svg';
import tenantsIcon from '../../../assets/img/tenants.svg';
import problemsIcon from '../../../assets/img/problems_profile.svg';
import { useProblemsStore } from '../../../store/ProblemsStore';
import { getEtableApi } from '../../../api/eTable';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';
import { getProblemsApi } from 'api/problems';

export const MainLayout = ({
  children,
  headline,
  Actions,
  name,
  dashboardBoxes,
  FooterActions,
  FooterInfo,
}) => {
  const expandedSize = pxToRem(230);
  const notExpandedSize = pxToRem(90);
  const isModalOpened = useModalStore((state) => state.isOpen);
  const setSelectedFlatId = useGlobalStore((store) => store.setSelectedFlatId);
  const changeIsExpanded = useGlobalStore((state) => state.changeIsExpanded);
  const isExpanded = useGlobalStore((state) => state.isExpanded);
  const selectedFlatId = useSelectedFlatId();
  const [isLoading, setIsLoading] = useState(false);
  const [
    fillStore,
    statistics,
    flat,
    building,
    showAccountBalance,
    accountBalance,
  ] = useDashboardStore((state) => [
    state.fillStore,
    state.statistics,
    state.flat,
    state.building,
    state.showAccountBalance,
    state.accountBalance,
  ]);

  const [notificationCount, setNotificationCount] = useState();
  const [problemCount, setProblemCount] = useState(0);
  const roundedBalance = parseFloat(accountBalance).toFixed(2);
  const problems = useProblemsStore.getState().problems;
  const getNotificationCount = async (flatId) => {
    const res = await getEtableApi(flatId);
    setNotificationCount(res?.count);
  };

  const getProblemsCount = async (flatId) => {
    const res = await getProblemsApi(flatId);
    setProblemCount(res?.count);
  };

  useEffect(() => {
    if (selectedFlatId) {
      getNotificationCount(selectedFlatId);
      getProblemsCount(selectedFlatId);
    }
  }, [selectedFlatId]);

  const getDashboardData = async (flatId) => {
    setIsLoading(true);
    const res = await getDashboardApi(flatId);
    fillStore(res.data);
    setSelectedFlatId(res.data.flat.id);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!statistics || flat.id !== selectedFlatId) {
      getDashboardData(selectedFlatId);
    }
  }, [selectedFlatId, statistics]);

  const pageHeadline = headline ?? name;
  const shouldDisplayBox = (type) => dashboardBoxes?.includes(type);
  const currentDebt = (statistics?.debitsSum - statistics?.paymentsSum).toFixed(
    2
  );
  return (
    <Wrapper>
      <CustomModal />
      <Layout isModalOpened={isModalOpened}>
        <Side
          expandedSize={expandedSize}
          notExpandedSize={notExpandedSize}
          isSideExpanded={isExpanded}
          className="filter"
        >
          <ToggleButton onClick={() => changeIsExpanded()}>
            <SidebarIcon
              className={`selectable ${isExpanded ? '' : 'rotate'}`}
            />
          </ToggleButton>
          <Sidebar isSideExpanded={isExpanded} />
        </Side>

        <Main
          expandedSize={expandedSize}
          notExpandedSize={notExpandedSize}
          isSideExpanded={isExpanded}
        >
          <Content>
            {isLoading || !flat || !selectedFlatId ? (
              <LoadingScreen>
                <Spinner />
              </LoadingScreen>
            ) : (
              <>
                <HeaderWrapper>
                  <div>{pageHeadline}</div> <div>{Actions}</div>
                </HeaderWrapper>

                <PageBoxes>
                  {shouldDisplayBox(dashboardBoxesTypes.personalInfo) && (
                    <TenatPersonalInfoBox
                      name={flat?.fullName}
                      address={`${building?.address}/${flat?.displayFlatId}`}
                    />
                  )}

                  {shouldDisplayBox(dashboardBoxesTypes.debt) && (
                    <TenantDebtCustomBox sumDebt={+currentDebt} />
                  )}

                  {shouldDisplayBox(dashboardBoxesTypes.debtGraph) && (
                    <TenantDebtGraph
                      percentage={Math.round(statistics?.paymentsSumPercentage)}
                    />
                  )}
                  {shouldDisplayBox(dashboardBoxesTypes.infoBox) && (
                    <TenantInfo
                      members={flat?.members}
                      oldDebt={flat?.oldDebit}
                      email={flat?.ownerEmail}
                      debt={+flat?.oldDebit + +currentDebt}
                      size={flat?.size}
                    />
                  )}
                  {shouldDisplayBox(dashboardBoxesTypes.statisticsBox) && (
                    <StatisticsWrapper>
                      <SquareWrapper>
                        <AccountBalanceStatisticsSquare
                          title="Broj prijavljenih problema"
                          info={problemCount || 0}
                          icon={problemsIcon}
                        />
                        <AccountBalanceStatisticsSquare
                          title="Ukupan broj stanara"
                          info={building?.population}
                          icon={tenantsIcon}
                        />
                      </SquareWrapper>
                      {showAccountBalance ? (
                        <AccountBalanceStatistics
                          title="Stanje tekućeg računa stambene zajednice"
                          info={roundedBalance}
                          icon={accountBalanceIcon}
                        />
                      ) : (
                        <AccountBalanceStatistics
                          title="Broj obaveštenja u stambenoj zajednici"
                          info={notificationCount}
                          icon={notificationIcon}
                        />
                      )}
                    </StatisticsWrapper>
                  )}
                </PageBoxes>
                <ContentWrapper>{children}</ContentWrapper>
                <div>{FooterActions}</div>
                <div>{FooterInfo}</div>
              </>
            )}
          </Content>
        </Main>
      </Layout>
    </Wrapper>
  );
};

MainLayout.propTypes = {
  headline: PropTypes.string,
  Actions: PropTypes.element,
  name: PropTypes.string,
  dashboardBoxes: PropTypes.array,
  FooterActions: PropTypes.element,
  FooterInfo: PropTypes.element,
};

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;
const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
  position: relative;
  z-index: 1;
`;

const Layout = styled.div`
  display: flex;
  filter: ${(props) => (props.isModalOpened ? 'blur(2px)' : '')};
`;

const Side = styled.div`
  width: ${(props) => (props.isSideExpanded ? pxToRem(230) : pxToRem(90))};
  position: fixed;
  z-index: 5;
  height: 100%;
  transition: width 0.3s ease-in-out;

  @media ${breakpoints.sm} {
    width: ${pxToRem(90)};
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  background-color: ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 50%;
  width: ${pxToRem(28)};
  height: ${pxToRem(28)};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  top: 50px;
  transform: scale(1);
  z-index: 1;
  right: -10px;

  .rotate {
    transform: rotate(180deg);
  }

  * {
    transition: transform 0.2s ease-out;
    width: 24px;
  }

  @media ${breakpoints.sm} {
    display: none;
  }
`;

const Main = styled.div`
  background: ${(props) => props.theme.colors.backgroundColor};
  width: 100%;
  z-index: 4;
  margin-left: ${(props) =>
    props.isSideExpanded ? pxToRem(230) : pxToRem(90)};
  transition: margin-left 0.1s ease-in-out;
  min-height: 100vh;

  @media ${breakpoints.sm} {
    margin-left: ${pxToRem(90)};
  }
`;

const Content = styled.div`
  width: 100%;
  padding: ${pxToRem(24)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  background: ${(props) => props.theme.colors.whiteColor};
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
`;

const HeaderWrapper = styled.h3`
  margin: 50px 0 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoints.md} {
    flex-direction: column;
    grid-gap: 1rem;
  }
`;

const PageBoxes = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  div {
    border-radius: 8px;
    height: 194px;
  }

  @media ${breakpoints.xl} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${breakpoints.md} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const AccountBalanceStatistics = styled(StatisticsBox)({
  paddingTop: '6px',
  paddingBottom: '6px',
  fontSize: '16px',
  maxWidth: '100%',
});

const AccountBalanceStatisticsSquare = styled(StatisticsBox)({
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '48%',
  height: '124px',
  position: 'relative',
  '& h2': {
    fontSize: '48px',
    position: 'absolute',
    left: '15px',
    top: '15px',
  },
  '& p': {
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    bottom: '12px',
  },
  '& img': {
    width: '46px',
    height: '46px',
    maxWidth: 'none',
    maxHeight: 'none',
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
});

const StatisticsWrapper = styled.section`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(12)};

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
  }
`;

const SquareWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 124px !important;
`;
