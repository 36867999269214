const SvgComponent = (props) => (
	<svg
		width="7"
		height="12"
		viewBox="0 0 7 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M6.5248 11.4499C6.35814 11.5833 6.1748 11.6541 5.9748 11.6624C5.7748 11.6708 5.5998 11.5999 5.4498 11.4499L0.524804 6.52493C0.441471 6.44159 0.383138 6.35826 0.349804 6.27493C0.316471 6.19159 0.299804 6.09993 0.299804 5.99993C0.299804 5.89993 0.316471 5.80826 0.349804 5.72493C0.383138 5.64159 0.441471 5.55826 0.524804 5.47493L5.4748 0.524928C5.60814 0.391595 5.77897 0.324928 5.9873 0.324928C6.19564 0.324928 6.3748 0.399928 6.5248 0.549927C6.6748 0.699928 6.7498 0.879095 6.7498 1.08743C6.7498 1.29576 6.6748 1.47493 6.5248 1.62493L2.1498 5.99993L6.5248 10.3749C6.6748 10.5249 6.74564 10.6999 6.7373 10.8999C6.72897 11.0999 6.65814 11.2833 6.5248 11.4499Z"
			fill="white"
		/>
	</svg>
);

export default SvgComponent;
  