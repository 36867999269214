import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const uniqueAuthStoreName = 'automodules-auth-store';

const createInitState = () => {
    const state = {};
    state['flat'] = null;

    return state;
};

export const useProblemsStore = create(
    persist(
        (set) => ({
            ...createInitState(),
            setState: (key, value) =>
                set(() => ({
                    [key]: value,
                })),

            removeState: (key) =>
                set(() => ({
                    [key]: null,
                })),

            fillStore: (obj) => set(obj),

            reset: () => set(() => createInitState()),
        }),
        {
            name: uniqueAuthStoreName,
        }
    )
);
