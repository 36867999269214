import CustomInput from 'components/Common/CustomInput';
import styled from 'styled-components';
import inputVariants from 'utils/constants/inputVariants';
import MailIcon from 'assets/auth/inputs/MailIcon';
import pxToRem from 'utils/helpers/pxToRem';
import { useEffect, useState } from 'react';
import CustomButton from 'components/Common/CustomButton';
import buttonTypes from 'utils/constants/buttonTypes';
import { postLoginApi } from 'api/auth';
import { useAuthStore } from 'store/AuthStore';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import useEnterKeyPress from 'utils/hooks/useEnterKeyPress';
import {
  sessionExpiredMessage,
  sessionExpiredParam,
} from 'utils/constants/auth';
import { fontSizes } from 'style/fontSizes';
import { breakpoints } from 'style/breakpoints';
import ArrowLogo from 'assets/img/login_arrow.svg';
import ImageButton from 'assets/img/login_image_button.svg';
import { Link } from 'react-router-dom';
import {
  isValidEmail,
  maxLength,
  minLength,
  nestValidators,
  validateFormAndReturnErrorsObjectOrNull,
  validateFormInput,
} from 'utils/helpers/validation';
import { useModalStore } from 'store/ModalStore';
import { modalTypeNames } from 'utils/constants/modalTypes';
import parseServerError from 'utils/helpers/parseServerError';
import ErrorMessage from 'components/Common/ErrorMessage';
import { CustomCheckbox } from '../../../components/Common/CustomCheckbox';
import { useGlobalStore } from 'store/GlobalStore';

const Login = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const setSelectedFlatId = useGlobalStore((store) => store.setSelectedFlatId);

  const searchParams = new URLSearchParams(location.search);

  const sessionExpired = searchParams.get(sessionExpiredParam);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');

  const [saveToken, removeToken, accessToken] = useAuthStore((state) => [
    state.saveToken,
    state.removeToken,
    state.token,
  ]);

  const onInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const inputErrorMessage = validateFormInput(
      FORM_VALIDATORS,
      inputName,
      inputValue
    );
    setErrors((prev) => ({ ...prev, [inputName]: inputErrorMessage }));

    return setFormData({
      ...formData,
      [inputName]: inputValue,
    });
  };

  const onLogin = async () => {
    const validationErrors = validateFormAndReturnErrorsObjectOrNull(
      formData,
      FORM_VALIDATORS
    );
    validationErrors ? setErrors(validationErrors) : setErrors({});
    if (validationErrors) return;

    try {
      setError('');
      const res = await postLoginApi(formData.email, formData.password);

      if (res) {
        setSelectedFlatId(undefined);
        saveToken(res.token);
      } else {
        removeToken();
      }
    } catch (err) {
      const errorMessage = parseServerError(err);
      setError(errorMessage);
    }
  };

  const toggleModal = useModalStore((state) => state.toggleModal);
  const onForgotPasswordClick = () => {
    toggleModal(modalTypeNames.forgotPasswordModal, {
      modalName: 'Zaboravljena lozinka',
    });
  };

  useEnterKeyPress(() => {
    onLogin();
  });

  useEffect(() => {
    if (accessToken) {
      navigate(routes.dashboard.path, { replace: true });
    }
  }, [accessToken]);

  return (
    <Wrapper>
      <LoginHeader>
        <h2>Uloguj se</h2>
        <p>
          Nov si na Troskovima, nemas nalog?
          <Link to={'/auth/register'}>
            <img
              src={ArrowLogo}
              alt="arrow right logo"
            />
            Kreiraj novi nalog
          </Link>
        </p>
      </LoginHeader>

      {sessionExpired && <p>{sessionExpiredMessage}</p>}
      <Form>
        <CustomInput
          label="Email Adresa"
          placeholder="Email Adresa"
          type="text"
          name="email"
          variant={inputVariants.auth}
          Icon={MailIcon}
          iconHeigh={pxToRem(13)}
          iconWidth={pxToRem(16)}
          onChange={onInput}
          value={formData.email}
          height={pxToRem(44)}
          errorMessage={errors.email}
        />

        <CustomInput
          label="Šifra"
          placeholder="Šifra"
          type="password"
          name="password"
          variant={inputVariants.auth}
          iconHeigh={pxToRem(13)}
          iconWidth={pxToRem(17)}
          onChange={onInput}
          value={formData.password}
          height={pxToRem(44)}
          errorMessage={errors.password}
        />
      </Form>
      <CustomButton
        height={pxToRem(44)}
        className="mt-4"
        type={buttonTypes.primary}
        onClick={() => onLogin()}
      >
        <Image
          src={ImageButton}
          alt="button arrow image"
        />
        Uloguj se
      </CustomButton>

      <ErrorMessage>{error}</ErrorMessage>
      <LoginFooter>
        <CustomCheckbox
          label="Ostani ulogovan"
          type="checkbox"
          name="checkbox"
        />
        <label>Ostani ulogovan</label>
        <a
          href="#"
          onClick={() => onForgotPasswordClick()}
        >
          Zaboravljena Lozinka?
        </a>
      </LoginFooter>
    </Wrapper>
  );
};

const FORM_VALIDATORS = {
  email: isValidEmail(),
  password: nestValidators(
    minLength(8, 'Lozinka mora imati 8 karaktera'),
    maxLength(16, 'Lozinka ne sme imati više od 16 karaktera')
  ),
};

const Wrapper = styled.div``;

const LoginHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;

  h2 {
    font-weight: 800;
    color: ${(props) => props.theme.colors.blackColor};
    text-align: center;
    font-size: 22px;
  }

  p {
    color: ${(props) => props.theme.colors.blackColor};
    font-size: ${fontSizes.sm};
    margin-bottom: 0;

    @media ${breakpoints.sm} {
      text-align: center;
    }
  }

  a {
    display: block;
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-size: ${fontSizes.sm};
    font-weight: bold;
    text-align: end;
    padding-top: 3px;

    @media ${breakpoints.sm} {
      text-align: center;
    }

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }

  img {
    margin-right: 4px;
    margin-bottom: 3px;
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
  }
`;

const Form = styled.div`
  margin-top: ${pxToRem(42)};
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

const Image = styled.img`
  position: absolute;
  left: 20px;
`;

const LoginFooter = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 1rem 0;

  a {
    font-size: ${fontSizes.sm};
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-weight: 600;

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }

  label {
    font-size: ${fontSizes.sm};
    color: ${(props) => props.theme.colors.lightBlackColor};
    margin-left: 5px !important;
    margin-right: auto !important;
  }
`;

export default Login;
