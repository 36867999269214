import { useModalStore } from 'store/ModalStore';
import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CustomButton from 'components/Common/CustomButton';
import {fontSizes} from "../../style/fontSizes";

const ConfirmationModal = () => {
  const toggleModal = useModalStore((state) => state.toggleModal);
  const [status, setStatus] = useState('getting status...');

  const modalProps = useModalStore((state) => state.modalProps);
  const confirmationMessage = modalProps?.confirmationMessage;
  const onConfirm = modalProps?.onConfirm;

  return (
    <Wrapper>
      <div className="confirmation-text">{confirmationMessage}</div>
      <div className="actions">
        <CustomButton
          type="primary"
          height="50px"
          onClick={onConfirm}
        >
          Da
        </CustomButton>
        <CustomButton
          type="secondary"
          height="50px"
          onClick={toggleModal}
        >
          Ne
        </CustomButton>
      </div>
    </Wrapper>
  );
};

ConfirmationModal.propTypes = {
  confirmationMessage: PropTypes.any,
  onConfirm: PropTypes.func,
};

const Wrapper = styled.div`
  font-size: 0.875rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem 3.6rem 3rem 3.6rem;
  border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 8px 8px 0 0;
  
  .confirmation-text {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  
  .actions {
    display: flex;
    gap: 1rem;
    font-size: ${fontSizes.lg};
    font-weight: 600;
  }
`;

export default ConfirmationModal;
