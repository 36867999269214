import { create } from 'zustand';

const createInitState = () => {
  const state = {};
  state['bills'] = [];

  return state;
};

export const useBillsStore = create((set) => ({
  ...createInitState(),
  setState: (key, value) =>
    set(() => ({
      [key]: value,
    })),

  removeState: (key) =>
    set(() => ({
      [key]: null,
    })),

  fillStore: (obj) => set(obj),

  reset: () => set(() => createInitState()),
}));
