import { useState } from 'react';
import styled from 'styled-components';
import CustomButton from 'components/Common/CustomButton';
import CustomInput from "../Common/CustomInput";
import inputVariants from "../../utils/constants/inputVariants";
import pxToRem from "../../utils/helpers/pxToRem";
import buttonTypes from "../../utils/constants/buttonTypes";
import {postForgotPassword} from "../../api/userProfile";
import TickMark from "../../assets/img/tick_mark_icon.svg";
import VoteImage from "../../assets/img/vote_icon.svg";
import { isValidEmail, validateFormAndReturnErrorsObjectOrNull, validateFormInput } from 'utils/helpers/validation';
import parseServerError from 'utils/helpers/parseServerError';

const ForgotPasswordModal = () => {
 
    const [serverError,setServerError]=useState("")
    const [submitted, setSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
    });

    const [errors, setErrors] = useState({});

    const onForgotPassword = async () => {
        const obj = {
            email: "",
        };

        obj.email = formData.email;
        const validationErrors = validateFormAndReturnErrorsObjectOrNull(
            formData,
            FORM_VALIDATORS
        );
        validationErrors ? setErrors(validationErrors) : setErrors({});
        if (validationErrors) return;
        try{
            const res = await postForgotPassword(obj);
            setSubmitted(true);
        }
        catch(err){
            setServerError(parseServerError(err))
        }
    }

    const onInput = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        const inputErrorMessage = validateFormInput(
          FORM_VALIDATORS,
          inputName,
          inputValue
        )   
        setErrors((prev) => ({ ...prev, [inputName]: inputErrorMessage }));

        return setFormData({
            ...formData,
            [inputName]: inputValue,
        });
    };

    return (
        <Wrapper>
            {submitted ? (
                <div className="wrapper-success">
                    <img className="tick-mark" src={TickMark} alt="tick mark image"/>
                    <img className="vote-icon" src={VoteImage} alt="vote image"/>
                    <div className="text-wrapper">
                        <h4>
                            Hvala
                        </h4>
                        <h5>
                            Kliknite na link u email-u koji Vam je stigao
                        </h5>
                    </div>
                </div>
            ) : (
                <Form>
                    <CustomInput
                        label="Unesite svoj email"
                        type="email"
                        name="email"
                        variant={inputVariants.auth}
                        onChange={onInput}
                        height={pxToRem(44)}
                        errorMessage={errors.email ?? serverError}
                    />
                    <CustomButton
                        height={pxToRem(44)}
                        className="mt-4"
                        type={buttonTypes.primary}
                        onClick={() => onForgotPassword()}
                    >
                        Pošalji
                    </CustomButton>
                </Form>
            )}
        </Wrapper>
    );
};

const FORM_VALIDATORS = {
    email: isValidEmail(),
  };

const Wrapper = styled.div`
  font-size: 0.875rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 3.6rem 3rem 3.6rem;
  border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 8px 8px 0 0;
  
    .wrapper-success {
    display: flex;
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    padding: .5rem;
    width: 100%;
    position: relative;
    
    .tick-mark {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    
    .vote-icon {
      margin-left: 1rem;
      margin-right: auto;
      padding-right: 2.5rem;
      border-right: 2px solid ${(props) => props.theme.colors.whiteColor};
    }
    
    .text-wrapper {
      margin-left: .5rem;
      padding: 0.5rem;
      width: 70%;
      text-align: center;
      background-color: ${(props) => props.theme.colors.whiteColor};
      
      h4 {
        font-size: 21px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.lightBlueColor};
      }
      
      h5 {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.darkBlueColor};
      }
    }
    
    p {
      margin: 0;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;


export default ForgotPasswordModal;
