import PropTypes from 'prop-types';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import routes from 'routes';
import { fontSizes } from 'style/fontSizes';
import styled, { useTheme } from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import Logo from 'assets/img/Logo.svg';
import { breakpoints } from 'style/breakpoints';
import { useDashboardStore } from 'store/DashboardStore';
import routeProtectionTypes from 'utils/constants/routeTypes';

const Sidebar = ({ isSideExpanded }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const showIncomeOutcomes=useDashboardStore(store=>store.showIncomesOutcomes)
  const isCurrentLocation = (route) => {
    return routes[route].path === location.pathname;
  };
  const userHasAccessToRoute = (routeProtection) => {
    return !(routeProtection === routeProtectionTypes.fondRoute && !showIncomeOutcomes);
  }

  return (
    <Wrapper>
      <LogoPlace isSideExpanded={isSideExpanded}>
          <Link to={'/'}>
              <img src={Logo} alt="logo login icon" />
          </Link>
      </LogoPlace>
      <MenuBar>
        {Object.keys(routes).map(
          (route) =>
            !routes[route].notInMainMenu && userHasAccessToRoute(routes[route].routeProtection) && (
              <MenuItem key={route}>
                <MenuItemLink
                  isSideExpanded={isSideExpanded}
                  onClick={() => navigate(routes[route].path)}
                  isActive={isCurrentLocation(route)}
                  to={routes[route].path}
                  title={
                      !isSideExpanded || window.matchMedia(breakpoints.sm).matches
                      ? routes[route].headline || routes[route].name
                      : undefined
                  }
                >
                  <Icon className="icon-align">
                    {routes[route].icon &&
                      routes[route].icon({
                        width: pxToRem(18),
                        height: pxToRem(18),
                        fill: isCurrentLocation(route)
                          ? theme.colors.lightBlueColor
                          : theme.colors.whiteColor,
                      })}
                  </Icon>

                  <Text
                    className={`${
                      isSideExpanded ? 'd-flex text-nowrap' : 'd-none'
                    }`}
                  >
                    {routes[route].name}
                  </Text>
                </MenuItemLink>
              </MenuItem>
            )
        )}
      </MenuBar>
      <CopyrightPlace isSideExpanded={isSideExpanded}>
        <span>Copyright © 2023</span>
        <span className="bolded">Troskovi.com</span>
      </CopyrightPlace>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  isSideExpanded: PropTypes.bool,
};

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.darkBlueColor};
  height: 100%;
  padding-top: 24px;
  width: 100%;
  color: ${(props) => props.theme.colors.whiteColor};;
  display: flex;
  flex-direction: column;
`;

const MenuBar = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.darkBlueColor};
  
  & > :last-child {
    flex-direction: column;
  }

  & > :last-child::before {
    content: "";
    display: inline-block;
    width: 80%;
    height: 1px;
    margin: 2rem auto;
    background-color: ${(props) => props.theme.colors.lightBlueColor};
    cursor: default;
  }
`;

const MenuItem = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuItemLink = styled.span`
  padding: 10px;
  text-decoration: none;
  width: min(192px, 100%);
  display: flex;
  width: 100%;
  gap: 22px;
  padding-left: ${(props) => (props.isSideExpanded ? '20px' : '36px')};
  transition: width 0.1s ease-in-out;

  font-family: 'Lato',serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(17)};
  letter-spacing: 0.05em;

  color: ${(props) =>
    props.isActive
      ? props.theme.colors.whiteColor
      : props.theme.colors.whiteColor};

  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.activeLinkColor
      : props.theme.colors.darkBlueColor};

  :hover {
    color: ${(props) =>
      props.isActive
        ? props.theme.colors.whiteColor
        : props.theme.colors.whiteColor};

    opacity: ${(props) => (props.isActive ? 1 : 0.7)};
    font-weight: 600;
  }
  position: relative;

  &:before {
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
    background: ${(props) => props.theme.colors.lightBlueColor};
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.428);
  }

  @media ${breakpoints.sm} {
    padding-left: 36px;
  }
`;

const Icon = styled.div``;
const Text = styled.div`
  display: flex;
  width: 100%;

  @media ${breakpoints.sm} {
    display: none !important;
  }
`;

const CopyrightPlace = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: ${fontSizes.xs};
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
  gap: 2px;
  flex-wrap: wrap;
  display: ${(props) => (props.isSideExpanded ? 'flex' : 'none')};

  .bolded {
    color: ${(props) => props.theme.colors.lightBlueColor};
    padding-left: 5px;
  }
`;

const LogoPlace = styled.div`
    display: flex;
    justify-content: start;
    margin-bottom: 50px;
    padding-left: 20px;
    margin-top: -12px;
    z-index: 5;
    width: 100px;

    a {
      display: ${(props) => (props.isSideExpanded ? 'flex' : 'none')};
    }

  @media ${breakpoints.sm} {
    a {
      display: none !important;
    }
  }
`;

export default Sidebar;
