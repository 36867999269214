import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import pxToRem from '../../utils/helpers/pxToRem';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { breakpoints } from '../../style/breakpoints';
import { useDebouncedCallback } from 'use-debounce';
import { useModalStore } from '../../store/ModalStore';
import { getFondApi } from '../../api/fond';
import { useFondStore } from '../../store/FondStore';
import transformDate from '../../utils/constants/transformDate';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as IncomeHeaderIcon } from '../../assets/img/income_icon.svg';
import { ReactComponent as InflowHeaderIcon } from '../../assets/img/inflow_icon.svg';
import { useDashboardStore } from 'store/DashboardStore';
import routes from 'routes';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

const Fond = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInflow =
    !searchParams.get('type') || searchParams.get('type') === 'inflow';
  const [currentType, setCurrentType] = useState('inflow');
  const selectedFlatId = useSelectedFlatId();
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const tableHeaders = [
    {
      name: 'createdAt',
      displayName: 'Datum',
      hasOrder: true,
    },
    {
      name: 'value',
      displayName: 'Vrednost',
      hasOrder: true,
    },
    {
      name: 'bankFileId',
      displayName: 'Broj izvoda',
      hasOrder: true,
    },
    {
      name: 'partnerName',
      displayName: 'Partner',
      hasOrder: true,
    },
    {
      name: 'comment',
      displayName: 'Komentar',
      hasOrder: true,
    },
    {
      name: 'income',
      displayName: 'Tip',
      hasOrder: true,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);

  const fillStore = useFondStore((state) => state.fillStore);

  const getFondData = async (type) => {
    setCurrentType(type);

    if (type !== currentType) {
      paginationData.page = 1;
    }

    const res = await getFondApi(
      selectedFlatId,
      paginationData.page,
      paginationData.pageSize.value,
      type
    );

    const data = res.data.map((item) => {
      return {
        ...item,
        createdAt: transformDate(item.createdAt),
        value: item.value.toFixed(2),
        income: item.income ? 'Priliv' : 'Odliv',
      };
    });

    useFondStore.setState({ fond: data });
    const fond = useFondStore.getState().fond; // Accessing the 'bills' state from useDashboardStore

    setPaginationData((prevData) => ({
      ...prevData,
      totalRecords: res.count,
      pageNumbers: Math.ceil(fond.length / paginationData.pageSize.value),
    }));

    setFondList(fond);

    setIsLoading(false);

    return fond;
  };

  // TODO: Use this from API.
  const [fondList, setFondList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [paginationData, setPaginationData] = useState({
    // Current page
    page: 1,
    // Total number of records from db
    totalRecords: 0,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: 0,
    // Per page !
    pageSize: pageSizeOptions[0],

    // Sorting info
    sortBy: '',
    sortOrder: '',

    // Filter info - search
    filter: '',
  });

  useEffect(() => {
    setIsLoading(true);

    getFondData(isInflow ? 1 : 0);
  }, [
    paginationData.page,
    paginationData.pageSize.value,
    paginationData.sortBy,
    paginationData.sortOrder,
    paginationData.filter,
    isInflow,
  ]);

  const onChangePage = (value) => {
    setPaginationData({
      ...paginationData,
      page: value,
    });

    window.scrollTo(0, 0);
  };

  const onChangeOrder = (value) => {
    setPaginationData({
      ...paginationData,
      sortBy: value.type,
      sortOrder: value.headerName,
    });
  };

  const debouncedChangeFilter = useDebouncedCallback((e) => {
    onChangeFilter(e.target.value);
  }, 500);

  const onChangeFilter = (value) => {
    setPaginationData({ ...paginationData, filter: value });
  };

  const onChangePageSize = (value) => {
    setPaginationData({ ...paginationData, pageSize: value, page: 1 });
  };

  const showIncomeOutcomes = useDashboardStore(
    (store) => store.showIncomesOutcomes
  );
  if (showIncomeOutcomes === undefined) return <Spinner />;
  if (showIncomeOutcomes === false)
    return <Navigate to={routes.dashboard.path} />;

  return (
    <Wrapper>
      <PageHeader>
        <TableHeaderInfo>
          <div>{isInflow ? <IncomeHeaderIcon /> : <InflowHeaderIcon />}</div>
          <h2>{isInflow ? 'Prilivi' : 'Odlivi'}</h2>
        </TableHeaderInfo>
        <TableHeaderAction></TableHeaderAction>
      </PageHeader>

      {isLoading ? (
        <SpinnerPlace>
          <Spinner
            color="secondary"
            type="border"
          />
        </SpinnerPlace>
      ) : (
        <CustomTable
          headers={tableHeaders}
          items={fondList}
          isStriped
          paginationData={{
            page: paginationData.page,
            pageNumbers: paginationData.pageNumbers,
            pageSize: paginationData.pageSize.value,
            totalRecords: paginationData.totalRecords,
          }}
          onChangePage={onChangePage}
          onClickOnOrder={onChangeOrder}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: 0;
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const TableHeaderAction = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-right: ${pxToRem(12)};

  @media ${breakpoints.md} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SpinnerPlace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

export default Fond;
