const SvgComponent = (props) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.91675 13.6666C1.58341 13.6666 1.29175 13.5416 1.04175 13.2916C0.791748 13.0416 0.666748 12.75 0.666748 12.4166V1.58331C0.666748 1.24998 0.791748 0.958313 1.04175 0.708313C1.29175 0.458313 1.58341 0.333313 1.91675 0.333313H16.0834C16.4167 0.333313 16.7084 0.458313 16.9584 0.708313C17.2084 0.958313 17.3334 1.24998 17.3334 1.58331V12.4166C17.3334 12.75 17.2084 13.0416 16.9584 13.2916C16.7084 13.5416 16.4167 13.6666 16.0834 13.6666H1.91675ZM9.00008 7.37498L16.0834 2.72915V1.58331L9.00008 6.12498L1.91675 1.58331V2.72915L9.00008 7.37498Z"
      fill={props?.fill ? props.fill : '#C1CBD7'}
    />
  </svg>
);

export default SvgComponent;
