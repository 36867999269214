import { fontSizes } from 'style/fontSizes';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  font-size: ${fontSizes.sm};
  padding: 2px;
  color: ${(props) => props.theme.colors.warning};
`;

export default ErrorMessage;
