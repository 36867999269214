import AuthLayout from 'components/Layouts/AuthLayout';
import { MainLayout } from 'components/Layouts/MainLayout';
import Topbar from 'components/Layouts/Topbar';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import { useAuthStore } from 'store/AuthStore';
import routeProtectionTypes from 'utils/constants/routeTypes';

const Page = ({
  routeProtection,
  component,
  dashboardBoxes,
  headline,
  Actions,
  name,
  FooterActions,
  FooterInfo,
}) => {
  // Check if user logged in
  const isAuthenticated = useAuthStore((state) => state.token !== null);
  const location = useLocation();
  const { pathname } = location;
  // Check if logged in user role is Administrator

  // If route is protected - and user not logged in - redirect on login
  if (
    routeProtection === routeProtectionTypes.protectedRoute &&
    !isAuthenticated
  ) {
    return <Navigate to={routes.logIn.path} />;
  }
  // If route is only for admins:
  if (routeProtection === routeProtectionTypes.adminRoute) {
    // User not logged in - redirect to login
    if (!isAuthenticated) {
      return <Navigate to={routes.logIn.path} />;
    }
  }

  // Show handler component for route if everything is okay
  const authPrefix = '/auth';
  const isOnAuthPage = pathname.includes(authPrefix);
  return (
    <>
      <Topbar />
      {isOnAuthPage ? (
        <AuthLayout>{component()}</AuthLayout>
      ) : (
        <MainLayout
          headline={headline}
          Actions={Actions}
          name={name}
          dashboardBoxes={dashboardBoxes}
          FooterActions={FooterActions}
          FooterInfo={FooterInfo}
        >
          {component()}
        </MainLayout>
      )}
    </>
  );
};

Page.propTypes = {
  component: PropTypes.func,
  routeProtection: PropTypes.oneOf(Object.keys(routeProtectionTypes)),
  name: PropTypes.string,
  headline: PropTypes.string,
  Actions: PropTypes.element,
  dashboardBoxes: PropTypes.array,
  FooterActions: PropTypes.element,
  FooterInfo: PropTypes.element,
};

export default Page;
