import DebitIcon from "../../../../assets/img/tenantDebtIcon.svg";
import ExclamationMark from "../../../../assets/img/exclamationMarkIcon.svg";
import styled from "styled-components";
import {fontSizes} from "../../../../style/fontSizes";
import PropTypes from "prop-types";
import {lightTheme} from "../../../../style/themes";

export const TenantDebtCustomBox = ({sumDebt}) => {
    const debt= sumDebt==='-0.00' ? '0.00' : sumDebt
    const currentDebtText = sumDebt > 0
        ? 'trenutno dugovanje'
        : sumDebt === 0
        ? 'trenutno stanje'
        :'trenutno ste u pretplati'
    return (
        <TenantDebt>
            <section>
                <img src={DebitIcon} alt="tenant debit icon"/>
                <p>
                    {currentDebtText}
                </p>
            </section>
            <h1>
                {debt} RSD
            </h1>
            <aside style={{backgroundColor: sumDebt > 0 ? lightTheme.colors.darkBlueColor : lightTheme.colors.lightBlueColor}}>
                <h2>
                    {sumDebt > 0
                        ? 'Molimo Vas da izmirite račun!'
                        : 'Hvala što izmirujete svoje obaveze!'
                    }
                </h2>
                {sumDebt > 0 && (
                    <img src={ExclamationMark} alt="exclamation mark icon"/>
                    )
                }

            </aside>
        </TenantDebt>
    )
}

TenantDebtCustomBox.propTypes = {
  sumDebt: PropTypes.number,
  messageDebt: PropTypes.string,
  display: PropTypes.string
}

const TenantDebt = styled.div`
  background: ${(props) => props.theme.colors.whiteColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  section {
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0 0;
    justify-content: center;
    position: relative;
    
    p {
      font-size: ${fontSizes.md};
      font-weight: bold;
      color: ${(props) => props.theme.colors.lightBlackColor};
      margin: 0;
      align-self: center;
      text-transform: uppercase;
      letter-spacing: 1.8px;
    }
    
    img {
     position: absolute;
     left: 1rem;
    }
  }
  
  h1 {
    font-size: 36px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.lightBlackColor};
    text-align: center;
    margin: 1rem 0;
  }

  aside {
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.colors.darkBlueColor};
    padding: 1rem 0;
    border-radius: 0 0 8px 8px;
    
    h2 {
      color: ${(props) => props.theme.colors.whiteColor};
      font-size: ${fontSizes.lg};
      font-weight: 500;
      align-self: center;
      margin: 0 1rem 0 0;
    }
  }
`;
