import { modalTypeNames } from 'utils/constants/modalTypes';
import { create } from 'zustand';

export const useModalStore = create((set) => ({
  isOpen: false,
  type: modalTypeNames.testModalName,
  size: undefined,
  setSize: (size) => set((state) => ({ ...state, size })),
  modalProps: {},
  toggleModal: (type = null, modalProps = {}) =>
    set((state) => ({
      isOpen: type ? true : false,
      type: type,
      size: undefined,
      modalProps,
    })),
}));
