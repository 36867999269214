const SvgComponent = (props) => (
    <svg
        width="20"
        height="17"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
    <path
        d="M10.005 10.8942C11.0347 10.8942 11.9118 10.5136 12.636 9.75249C13.3603 8.99132 13.7224 8.07209 13.7224 6.9948C13.7224 5.9175 13.3586 5 12.6311 4.24232C11.9035 3.48463 11.0248 3.10579 9.99503 3.10579C8.96525 3.10579 8.08823 3.48636 7.36398 4.24752C6.63972 5.00869 6.27759 5.92791 6.27759 7.0052C6.27759 8.0825 6.64138 9 7.36895 9.75768C8.09654 10.5154 8.97521 10.8942 10.005 10.8942ZM9.99276 9.77116C9.25494 9.77116 8.63021 9.50101 8.11855 8.96071C7.60692 8.42039 7.3511 7.76429 7.3511 6.99242C7.3511 6.22056 7.60933 5.56699 8.12579 5.03172C8.64227 4.49647 9.26942 4.22884 10.0072 4.22884C10.7451 4.22884 11.3698 4.49899 11.8814 5.03929C12.3931 5.57961 12.6489 6.23571 12.6489 7.00758C12.6489 7.77944 12.3907 8.43301 11.8742 8.96828C11.3577 9.50353 10.7306 9.77116 9.99276 9.77116ZM10.0013 14C7.80926 14 5.81924 13.358 4.03126 12.074C2.24326 10.7901 0.899504 9.09871 0 7C0.899504 4.90129 2.24282 3.20995 4.02994 1.92596C5.81705 0.641986 7.80663 0 9.99868 0C12.1907 0 14.1808 0.641986 15.9687 1.92596C17.7567 3.20995 19.1005 4.90129 20 7C19.1005 9.09871 17.7572 10.7901 15.9701 12.074C14.1829 13.358 12.1934 14 10.0013 14ZM9.99596 12.8654C11.8957 12.8654 13.6388 12.334 15.2252 11.2712C16.8116 10.2083 18.0208 8.78462 18.853 7C18.0208 5.21538 16.8129 3.79166 15.2292 2.72883C13.6455 1.66601 11.9038 1.1346 10.004 1.1346C8.10428 1.1346 6.3612 1.66601 4.77479 2.72883C3.1884 3.79166 1.97425 5.21538 1.13234 7C1.97425 8.78462 3.18707 10.2083 4.77078 11.2712C6.35449 12.334 8.09622 12.8654 9.99596 12.8654Z"
        fill={props?.fill ? props.fill : '#000000'}
    />
</svg>
);

export default SvgComponent;
