import styled from "styled-components"
import { fontSizes } from "style/fontSizes"

export const Copyright = () => {
    return (
        <CopyrightWrapper>
            <CopyrightText>
                Copyright © 2023
            </CopyrightText>
            <CopyrightEmail>
                Troskovi.com
            </CopyrightEmail>
        </CopyrightWrapper>
    )
}

const CopyrightWrapper = styled.p`
    font-size: ${fontSizes.sm};
    display: block;
    margin-bottom: 0;
    padding: 8px 0;
`

const CopyrightText = styled.span`
    color: ${(props) => props.theme.colors.whiteColor};
    padding-right: 5px;
`

const CopyrightEmail = styled.span`
    color: ${(props) => props.theme.colors.lightBlueColor};
`
