import { useEffect } from 'react';

// callback handler on clicking on enter key
const useEnterKeyPress = (callback) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);
};

export default useEnterKeyPress;
