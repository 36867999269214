import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const uniqueGlobalStoreName = 'global-store';

export const useGlobalStore = create(
  persist(
    (set) => ({
      isExpanded: true,
      selectedFlatId: undefined,
      changeIsExpanded: () =>
        set((state) => ({
          ...state,
          isExpanded: !state.isExpanded,
        })),
      setSelectedFlatId: (flatId) =>
        set((state) => ({
          ...state,
          selectedFlatId: flatId,
        })),
    }),
    { name: uniqueGlobalStoreName }
  )
);
