import api from 'api';
import { useProblemsStore } from 'store/ProblemsStore';

const getPrefix = (flatId) => '/flats/' + flatId + '/problems';

export const getProblemsApi = async (flatId, page, limit, status) => {
  let uri = getPrefix(flatId);

  const res = await api.get(uri, {
    params: {
      page,
      limit,
      status: status ?? undefined,
    },
  });
  useProblemsStore.setState({ problems: res.data });

  return res.data;
};

export const postProblemApi = async (
  flatId,
  taskTypeId,
  taskSubTypeId,
  description,
  file,
  phoneNumber
) => {
  const formData = new FormData();
  formData.append('type', taskTypeId);
  formData.append('subtype', taskSubTypeId);
  formData.append('description', description);
  formData.append('priority', 1);
  formData.append('file', file);
  formData.append('phoneNumber', phoneNumber);
  const res = await api.post(`${getPrefix(flatId)}/v3`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
};
