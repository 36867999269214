import pxToRem from '../../utils/helpers/pxToRem';
import styled from 'styled-components';
import { breakpoints } from '../../style/breakpoints';
import HomeRepairIcon from 'assets/img/home_repair_service.svg';
import PainterTollRepairIcon from 'assets/img/painter.svg';
import LocationIcon from 'assets/img/location.svg';
import PhoneRepairIcon from 'assets/img/phone.svg';
import CeramistLocationIcon from 'assets/img/ceramist.svg';
import ElectricanTollRepairIcon from 'assets/img/electrician.svg';
import PlumerTollRepairIcon from 'assets/img/plumer.svg';
import {useDashboardStore} from "../../store/DashboardStore";

const Craftsman = () => {

  const building = useDashboardStore(store=> store.building);
  const city=building?.city ?? 'Niš'

  return (
    <Wrapper>
      <PageHeader>
        <TableHeaderInfo>
          <div>
            <img
              src={HomeRepairIcon}
              alt="home repair icon"
            />
          </div>
          <h2>Majstori</h2>
        </TableHeaderInfo>
        <RepairWrapper>
          <InfoWrapper>
            <RepairInfo>
              <div>
                <img
                    src={PainterTollRepairIcon}
                    alt="tool repair icon"
                />
              </div>
              <h2>Moler</h2>
            </RepairInfo>
            <CityInfo>
              <div>
                <img
                    src={LocationIcon}
                    alt="location icon"
                />
              </div>
              <h2>Grad: <span>{city}</span></h2>
            </CityInfo>
            <ContactInfo>
              <div>
                <img
                    src={PhoneRepairIcon}
                    alt="phone icon"
                />
              </div>
              <a href={ 'tel: 0648701757' }>+381 64 87 01 757</a>
            </ContactInfo>
          </InfoWrapper>
          <InfoWrapper>
            <RepairInfo>
              <div>
                <img
                    src={ElectricanTollRepairIcon}
                    alt="tool repair icon"
                />
              </div>
              <h2>Električar</h2>
            </RepairInfo>
            <CityInfo>
              <div>
                <img
                    src={LocationIcon}
                    alt="location icon"
                />
              </div>
              <h2>Grad: <span>{city}</span></h2>
            </CityInfo>
            <ContactInfo>
              <div>
                <img
                    src={PhoneRepairIcon}
                    alt="phone icon"
                />
              </div>
              <a href={ 'tel: 0641892722' }>+381 64 189 2722</a>
            </ContactInfo>
          </InfoWrapper>
          <InfoWrapper>
            <RepairInfo>
              <div>
                <img
                    src={CeramistLocationIcon}
                    alt="tool repair icon"
                />
              </div>
              <h2>Keramičar</h2>
            </RepairInfo>
            <CityInfo>
              <div>
                <img
                    src={LocationIcon}
                    alt="location icon"
                />
              </div>
              <h2>Grad: <span>{city}</span></h2>
            </CityInfo>
            <ContactInfo>
              <div>
                <img
                    src={PhoneRepairIcon}
                    alt="phone icon"
                />
              </div>
              <a href={ 'tel: 0652651996' }>+381 65 265 1996</a>
            </ContactInfo>
          </InfoWrapper>
          <InfoWrapper>
            <RepairInfo>
              <div>
                <img
                    src={PlumerTollRepairIcon}
                    alt="tool repair icon"
                />
              </div>
              <h2>Vodoinstalater</h2>
            </RepairInfo>
            <CityInfo>
              <div>
                <img
                    src={LocationIcon}
                    alt="location icon"
                />
              </div>
              <h2>Grad: <span>{city}</span></h2>
            </CityInfo>
            <ContactInfo>
              <div>
                <img
                    src={PhoneRepairIcon}
                    alt="phone icon"
                />
              </div>
              <a href={ 'tel: 0642443587' }>+381 64 244 35 87</a>
            </ContactInfo>
          </InfoWrapper>
          <InfoWrapper>
            <RepairInfo>
              <div>
                <img
                    src={CeramistLocationIcon}
                    alt="tool repair icon"
                />
              </div>
              <h2>Krojač zavesa</h2>
            </RepairInfo>
            <CityInfo>
              <div>
                <img
                    src={LocationIcon}
                    alt="location icon"
                />
              </div>
              <h2>Grad: <span>{city}</span></h2>
            </CityInfo>
            <ContactInfo>
              <div>
                <img
                    src={PhoneRepairIcon}
                    alt="phone icon"
                />
              </div>
              <a href={ 'tel: 0652446159' }>+381 65 24 46 159</a>
            </ContactInfo>
          </InfoWrapper>
        </RepairWrapper>
      </PageHeader>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.whiteColor};
  border-radius: 8px;
  padding: 12px 16px 24px 16px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: 0;
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.darkBlueColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.darkBlueColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
`;

const RepairWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media ${breakpoints.xl} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${breakpoints.md} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const InfoWrapper = styled.div`
  border-radius: 8px;
  height: 194px;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.lightBlueColor};
`;

const RepairInfo = styled.div`
  display: flex;
  padding: 8px 8px 32px 8px;
  align-items: center;
  grid-gap: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.whiteColor};
  
  div {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.whiteColor};
    position: relative;
  }
  
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.whiteColor};
  }
`;

const CityInfo = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  padding:  2px 8px 2px 8px;
  margin-top: 1rem;

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.whiteColor};
    
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  padding:  2px 8px 2px 8px;
  
  a {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.whiteColor};
    transition: all .3s ease-in-out;

    :hover {
      font-weight: 600;
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.whiteColor};

    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
`;

export default Craftsman;
