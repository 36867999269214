const SvgComponent = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.375 16.5C3.075 16.5 2.8125 16.3875 2.5875 16.1625C2.3625 15.9375 2.25 15.675 2.25 15.375V11.6625L4.78125 8.86876L5.5875 9.67501L3.375 12.0938H14.625L12.5438 9.73126L13.35 8.92501L15.75 11.6625V15.375C15.75 15.675 15.6375 15.9375 15.4125 16.1625C15.1875 16.3875 14.925 16.5 14.625 16.5H3.375ZM8.2875 10.7813L5.30625 7.80001C5.06875 7.56251 4.9625 7.29688 4.9875 7.00313C5.0125 6.70938 5.1375 6.45001 5.3625 6.22501L9.3375 2.25001C9.55 2.03751 9.8125 1.92813 10.125 1.92188C10.4375 1.91563 10.7063 2.02501 10.9313 2.25001L13.9125 5.23126C14.125 5.44376 14.2344 5.69688 14.2406 5.99063C14.2469 6.28438 14.125 6.55626 13.875 6.80626L9.9 10.7813C9.6875 10.9938 9.425 11.1063 9.1125 11.1188C8.8 11.1313 8.525 11.0188 8.2875 10.7813ZM12.9563 5.96251L10.1438 3.15001L6.24375 7.05001L9.05625 9.86251L12.9563 5.96251Z"
            fill={props?.fill ? props.fill : 'black'}
        />

    </svg>
);

export default SvgComponent;
