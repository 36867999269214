import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SettingsLogoutIcon from 'assets/img/settings_logout.svg';
import { fontSizes } from '../../../style/fontSizes';
import routes from '../../../routes';
import { useAuthStore } from '../../../store/AuthStore';
import { useModalStore } from 'store/ModalStore';
import { modalTypeNames } from 'utils/constants/modalTypes';
import { useGlobalStore } from 'store/GlobalStore';

export const FooterSettings = () => {
  const removeAccessToken = useAuthStore((state) => state.removeToken);
  const navigate = useNavigate();
  const toggleModal = useModalStore((state) => state.toggleModal);
  const setSelectedFlatId = useGlobalStore((store) => store.setSelectedFlatId);
  const handleLogout = () => {
    removeAccessToken();
    toggleModal();
    // Redirect
    navigate(routes.logIn.path, { replace: true });
  };

  const onLogoutClick = () => {
    toggleModal(modalTypeNames.confirmationModal, {
      confirmationMessage: 'Da li ste sigurni da želite da se izlogujete?',
      onConfirm: handleLogout,
    });
    setSelectedFlatId(undefined);
  };

  return (
    <LogoutPlace>
      <Link onClick={onLogoutClick}>
        Odjava
        <span>
          <img
            src={SettingsLogoutIcon}
            alt="Logout icon"
          />
        </span>
      </Link>
    </LogoutPlace>
  );
};

const LogoutPlace = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundColor};
  margin-top: 32px;
  display: flex;
  justify-content: end;

  a {
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-size: ${fontSizes.md};
    font-weight: 600;

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }

  span {
    margin-left: 8px;
  }
`;
