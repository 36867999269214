import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useModalStore } from 'store/ModalStore';
import styled from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import { useEtableStore } from '../../store/EtableStore';
import { breakpoints } from '../../style/breakpoints';
import { getEtableApi } from '../../api/eTable';
import transformDate from '../../utils/constants/transformDate';
import EtableIcon from 'assets/img/e-table_icon.svg';
import transformDateTime from '../../utils/constants/transformDateTime';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

const Etable = () => {
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const tableHeaders = [
    {
      name: 'createdAt',
      displayName: 'Datum',
      hasOrder: true,
    },
    {
      name: 'title',
      displayName: 'Naslov',
      hasOrder: true,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);

  const fillStore = useEtableStore((state) => state.fillStore);

  const selectedFlatId = useSelectedFlatId();

  // TODO: Use this from API.
  const [Etable, setEtable] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // TODO: Use this from API.
  const [paginationData, setPaginationData] = useState({
    // Current page
    page: 1,
    // Total number of records from db
    totalRecords: 0,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: 0,
    // Per page !
    pageSize: pageSizeOptions[0],

    // Sorting info
    sortBy: '',
    sortOrder: '',

    // Filter info - search
    filter: '',
  });

  useEffect(() => {
    setIsLoading(true);
  }, [
    paginationData.page,
    paginationData.pageSize.value,
    paginationData.sortBy,
    paginationData.sortOrder,
    paginationData.filter,
  ]);

  const getEtableData = async () => {
    const res = await getEtableApi(
      selectedFlatId,
      paginationData.page,
      paginationData.pageSize.value
    );

    const data = res.data.map((item) => {
      return {
        ...item,
        createdAt: transformDate(item.createdAt),
        title: <strong>{item.title}</strong>,
        expandedRowText: (
          <div style={{ whiteSpace: 'pre-wrap' }}>{item.text}</div>
        ),
        expandedRowDateTime: transformDateTime(item.createdAt),
      };
    });
    console.log({ x: res.data });
    fillStore(data);
    const eTable = useEtableStore.getState().eTable;

    setPaginationData((prevData) => ({
      ...prevData,
      totalRecords: res.count,
      pageNumbers: Math.ceil(Etable.length / paginationData.pageSize.value),
    }));

    setEtable(data);
    setIsLoading(false);

    return eTable;
  };

  const onChangePage = (value) => {
    setPaginationData({
      ...paginationData,
      page: value,
    });

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setIsLoading(true);

    getEtableData();
  }, [
    paginationData.page,
    paginationData.pageSize.value,
    paginationData.sortBy,
    paginationData.sortOrder,
    paginationData.filter,
    selectedFlatId,
  ]);

  const onChangeOrder = (value) => {
    setPaginationData({
      ...paginationData,
      sortBy: value.type,
      sortOrder: value.headerName,
    });
  };
  return (
    <Wrapper>
      <PageHeader>
        <TableHeaderInfo>
          <div>
            <img
              src={EtableIcon}
              alt="e-table list icon"
            />
          </div>
          <h2>Poslednja obaveštenja</h2>
        </TableHeaderInfo>
        <TableHeaderAction></TableHeaderAction>
      </PageHeader>

      {isLoading ? (
        <SpinnerPlace>
          <Spinner
            color="secondary"
            type="border"
          />
        </SpinnerPlace>
      ) : (
        <CustomTable
          headers={tableHeaders}
          items={Etable}
          isStriped
          paginationData={{
            page: paginationData.page,
            pageNumbers: paginationData.pageNumbers,
            pageSize: paginationData.pageSize.value,
            totalRecords: paginationData.totalRecords,
          }}
          onChangePage={onChangePage}
          onClickOnOrder={onChangeOrder}
          isExpandable={true}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: 0;
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const TableHeaderAction = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-right: ${pxToRem(12)};

  @media ${breakpoints.md} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SpinnerPlace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

export default Etable;
