import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import { useModalStore } from 'store/ModalStore';
import { breakpoints } from 'style/breakpoints';
import styled from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import BillsIcon from '../../assets/img/bills_icon.svg';
import { useDashboardStore } from '../../store/DashboardStore';
import getTranslatedBillMonthYearForDate from '../../utils/helpers/monthForDate';
import { lightTheme } from 'style/themes';
import { Link } from 'react-router-dom';
import { fontSizes } from '../../style/fontSizes';
import { ReactComponent as ShowAllIcon } from '../../assets/img/show_all_icon.svg';
import BuildingInfoIcon from '../../assets/img/building_info_icon.svg';
import { getBillPdf } from '../../api/bills';
import config from '../../config';
import { actionTypes } from '../../utils/constants/customTable/actions';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';
import {MD5} from "crypto-js";

const Profile = () => {
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const billTableHeaders = [
    {
      name: 'month',
      displayName: 'Mesec',
      hasOrder: true,
    },
    {
      name: 'amount',
      displayName: 'Iznos',
      hasOrder: true,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);
  const selectedFlatId = useSelectedFlatId();

  const [bills, building, accountBalance, showAccountBalance] =
    useDashboardStore((state) => [
      state.bills,
      state.building,
      state.accountBalance,
      state.showAccountBalance,
    ]);

  const roundedBalance = parseFloat(accountBalance).toFixed(2);

  // TODO: Use this from API.
  const [billList, setBillList] = useState([]);

  const changeBillList = async () => {
    const bills = useDashboardStore.getState().bills; // Accessing the 'bills' state from useDashboardStore

    const resBillList = bills.map((item) => {
      return {
        amount: item.amount.toFixed(2),
        month: getTranslatedBillMonthYearForDate(item.month),
        id: item.id,
      };
    });

    setBillList(resBillList);

    return bills;
  };

  const openBill = (billId) => {
    const hashedSelectedFlatId = MD5(String(selectedFlatId)).toString();
    const hashedBillId = MD5(String(billId)).toString();
    // Call the API function to update the schedule
    getBillPdf(selectedFlatId, billId)
      .then(() => {
        // Handle the API response here (if needed)
        window.open(
          `${config.storageUrl}/pdfBills/${hashedSelectedFlatId}/${hashedBillId}.pdf`,
          '_blank'
        );
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error('API Error:', error);
      });
  };

  const onClickOnAction = (details) => {
    if (details.actionType === actionTypes.dashboardOpenBill) {
      const billId = details.item.id;
      openBill(billId);
    }
  };

  useEffect(() => {
    changeBillList();
  }, [bills]);

  const billsTableData = bills.map((bill) => ({
    amount: bill.amount.toFixed(2),
    month: getTranslatedBillMonthYearForDate(bill.month),
    id: bill.id,
  }));

  return (
    <Wrapper style={{ backgroundColor: lightTheme.colors.backgroundColor }}>
      <div className={'row'}>
        <div className={'col-md-12 col-lg-6'}>
          <PageHeader>
            <TableHeaderInfo>
              <div>
                <img
                  src={BuildingInfoIcon}
                  alt="building info icon"
                />
              </div>
              <h2>Informacije o stambenoj zajednici</h2>
            </TableHeaderInfo>
          </PageHeader>

          <table
            style={{
              position: 'relative',
              borderColor: lightTheme.colors.table.border,
              backgroundColor: lightTheme.colors.whiteColor,
              borderRadius: '8px 8px 8px 8px',
              borderTop: 'none',
              width: '100%',
              whiteSpace: 'nowrap',
              marginBottom: '16px',
            }}
          >
            <tbody style={{ borderRadius: '8px 8px 8px 8px' }}>
              <RowWrapper>
                <td colSpan={1}>NAZIV:</td>
                <td colSpan={1}>{building?.address}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>PIB:</td>
                <td colSpan={1}>{building?.pib}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>MB:</td>
                <td colSpan={1}>{building?.matId}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>TEKUĆI RAČUN:</td>
                <td colSpan={1}>{building?.bankAccount}</td>
              </RowWrapper>
              {showAccountBalance && (
                <RowWrapper>
                  <td colSpan={1}>STANJE TEKUĆEG RAČUNA:</td>
                  <td colSpan={1}>{roundedBalance}</td>
                </RowWrapper>
              )}
              <RowWrapper>
                <td colSpan={1}>EMAIL ZGRADE:</td>
                <td colSpan={1}>{building?.email}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>VRSTA UPRAVE:</td>
                <td colSpan={1}>{building?.management}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>BROJ STANOVA:</td>
                <td colSpan={1}>{building?.flats}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>BROJ STANARA:</td>
                <td colSpan={1}>{building?.population}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>POVRŠINA ZGRADE:</td>
                <td colSpan={1}>{building?.buildingSize}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>GRAD:</td>
                <td colSpan={1}>{building?.city}</td>
              </RowWrapper>
              <RowWrapper>
                <td colSpan={1}>OPŠTINA:</td>
                <td colSpan={1}>{building?.municipality}</td>
              </RowWrapper>
            </tbody>
          </table>
        </div>

        <div className={'col-md-12 col-lg-6'}>
          <PageHeader>
            <TableHeaderInfo>
              <div>
                <img
                  src={BillsIcon}
                  alt="bills list icon"
                />
              </div>
              <h2>Moja zaduženja</h2>
            </TableHeaderInfo>
          </PageHeader>

          <CustomTable
            headers={billTableHeaders}
            items={billsTableData}
            isStriped
            actions={[actionTypes.dashboardOpenBill]}
            onClickOnAction={onClickOnAction}
          />
          {billsTableData.length > 0 && (
            <TableFooter>
              <Link to={'/bills'}>
                Pregled svih zaduženja
                <ShowAllIcon />
              </Link>
            </TableFooter>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const RowWrapper = styled.tr`
  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.table.striped};
  }

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.whiteColor};
  }

  td:first-child {
    color: ${(props) => props.theme.colors.darkBlueColor};
    font-size: ${fontSizes.sm};
    font-weight: 700;
  }

  td:not(:first-child) {
    color: ${(props) => props.theme.colors.lightBlackColor};
  }

  td {
    padding: ${pxToRem(10)};
    font-style: normal;
    font-size: ${fontSizes.md};
    color: ${(props) => props.theme.colors.lightBlackColor};
    vertical-align: middle;
    font-weight: 500;
    font-size: ${fontSizes.md};
    line-height: ${pxToRem(20)};
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
  }
`;

const TableFooter = styled.div`
  display: flex;
  justify-content: end;
  background-color: ${(props) => props.theme.colors.whiteColor};
  border-radius: 0 0 8px 8px;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding: 10px 0;

  a {
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-size: ${fontSizes.md};
    font-weight: 500;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    margin-right: 2rem;

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }
`;

export default Profile;
