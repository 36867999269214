const SvgComponent = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 7.9751C6.9 7.9751 6 7.6251 5.3 6.9251C4.6 6.2251 4.25 5.3251 4.25 4.2251C4.25 3.1251 4.6 2.2251 5.3 1.5251C6 0.825098 6.9 0.475098 8 0.475098C9.1 0.475098 10 0.825098 10.7 1.5251C11.4 2.2251 11.75 3.1251 11.75 4.2251C11.75 5.3251 11.4 6.2251 10.7 6.9251C10 7.6251 9.1 7.9751 8 7.9751ZM14.5 16.0001H1.5C1.0875 16.0001 0.734375 15.8532 0.440625 15.5595C0.146875 15.2657 0 14.9126 0 14.5001V13.6501C0 13.0168 0.158333 12.4751 0.475 12.0251C0.791667 11.5751 1.2 11.2334 1.7 11.0001C2.81667 10.5001 3.8875 10.1251 4.9125 9.8751C5.9375 9.6251 6.96667 9.5001 8 9.5001C9.03333 9.5001 10.0583 9.62926 11.075 9.8876C12.0917 10.1459 13.1577 10.5184 14.273 11.0049C14.7947 11.2404 15.213 11.5818 15.5278 12.0291C15.8426 12.4764 16 13.0168 16 13.6501V14.5001C16 14.9126 15.8531 15.2657 15.5594 15.5595C15.2656 15.8532 14.9125 16.0001 14.5 16.0001ZM1.5 14.5001H14.5V13.6501C14.5 13.3834 14.4208 13.1293 14.2625 12.8876C14.1042 12.6459 13.9083 12.4668 13.675 12.3501C12.6083 11.8334 11.6333 11.4793 10.75 11.2876C9.86667 11.0959 8.95 11.0001 8 11.0001C7.05 11.0001 6.125 11.0959 5.225 11.2876C4.325 11.4793 3.35 11.8334 2.3 12.3501C2.06667 12.4668 1.875 12.6459 1.725 12.8876C1.575 13.1293 1.5 13.3834 1.5 13.6501V14.5001ZM8 6.4751C8.65 6.4751 9.1875 6.2626 9.6125 5.8376C10.0375 5.4126 10.25 4.8751 10.25 4.2251C10.25 3.5751 10.0375 3.0376 9.6125 2.6126C9.1875 2.1876 8.65 1.9751 8 1.9751C7.35 1.9751 6.8125 2.1876 6.3875 2.6126C5.9625 3.0376 5.75 3.5751 5.75 4.2251C5.75 4.8751 5.9625 5.4126 6.3875 5.8376C6.8125 6.2626 7.35 6.4751 8 6.4751Z"
            fill={props?.fill ? props.fill : 'black'}
        />
    </svg>
);

export default SvgComponent;
