import styled from 'styled-components';
import error_404 from "../../assets/img/error_404.png";

const NotFound = () => {

    return (
        <Wrapper>
            <Wrapper404>
                <h1>Ups, ova stranica ne postoji</h1>
                <img src={error_404} alt="image for 404 page"/>
                <h2>Nije moguće pronaći traženu stranicu.</h2>
            </Wrapper404>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Wrapper404 = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);

  img {
    width: 100%;
    height: auto;
  }

  h1, h2 {
    text-align: center;
  }
`;

export default NotFound;
