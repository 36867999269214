import { create } from 'zustand';

const createInitState = () => {
  const state = {};
  state['bills'] = [];
  state['building'] = null;
  state['flat'] = null;
  state['notifications'] = [];
  state['statistics'] = null;
  state['accountBalance'] = [];
  state['showAccountBalance'] = null;

  return state;
};

export const useDashboardStore = create((set) => ({
  ...createInitState(),
  setState: (key, value) =>
    set(() => ({
      [key]: value,
    })),

  removeState: (key) =>
    set(() => ({
      [key]: null,
    })),

  fillStore: (obj) => set(obj),

  reset: () => set(() => createInitState()),
}));
