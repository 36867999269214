import CustomButton from 'components/Common/CustomButton';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import buttonTypes from '../../utils/constants/buttonTypes';
import pxToRem from '../../utils/helpers/pxToRem';
import { getUserFlats } from 'api/flat';
import { Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useModalStore } from 'store/ModalStore';
import { useGlobalStore } from 'store/GlobalStore';
import { modalTypeNames } from 'utils/constants/modalTypes';
import ModalTitle from 'components/Common/ModalTitle';
import { ReactComponent as UserLogo } from '../../assets/img/user_modal_logo.svg';
import { breakpoints } from 'style/breakpoints';
import {fontSizes} from "../../style/fontSizes";

const ChoseFlatModal = () => {
  const [userFlats, setUserFlats] = useState([]);
  const [selectedFlatId, setSelectedFlatId] = useGlobalStore((store) => [
    store.selectedFlatId,
    store.setSelectedFlatId,
  ]);
  const nav = useNavigate();
  const [toggleModal, setSize] = useModalStore((state) => [
    state.toggleModal,
    state.setSize,
  ]);
  const handleChooseFlat = (flatId) => {
    setSelectedFlatId(flatId);
    toggleModal();
    nav('/');
  };

  useEffect(() => {
    getUserFlats().then((flats) => setUserFlats(flats));
    setSize('lg');
  }, []);
  const flatsAreLoading = !userFlats.length;
  return (
    <Wrapper>
      <Form>
        {flatsAreLoading ? (
          <Spinner />
        ) : (
          <>
            <ModalTitle>Moje stambene jedinice</ModalTitle>
            <div className="flatsList">
              {userFlats
                .map((flat) => ({
                  ...flat,
                  isSelected: flat.id === selectedFlatId,
                }))
                .sort((_, curr) => (curr.isSelected ? 1 : -1))
                .map(
                  ({
                    id,
                    displayFlatId,
                    buildingAddress,
                    fullName,
                    isSelected,
                  }) => (
                    <FlatCard
                      key={id}
                      isSelected={isSelected}
                    >
                      <div
                        className="ownerCard"
                        onClick={() => handleChooseFlat(id)}
                      >
                        <div className="ownerHeader">
                          <UserLogo />
                          <span className="ownerName">{fullName}</span>
                          <div className="ownerCircle">
                            <div className="outsideCircle">
                              <div className="insideCircle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="addressText">
                          <p className="mb-0">
                            Adresa i broj posebnog dela:{' '}
                            <span className="address">
                              {buildingAddress}/{displayFlatId}
                            </span>
                          </p>
                        </div>
                      </div>
                    </FlatCard>
                  )
                )}
            </div>
          </>
        )}
        <CustomButton
          onClick={() => toggleModal(modalTypeNames.addFlatModal)}
          type={buttonTypes.primary}
          height={pxToRem(44)}
        >
          Dodaj novu stambenu jedinicu
        </CustomButton>
      </Form>
    </Wrapper>
  );
};

const FlatCard = styled.div`
  min-width: 100%;
  margin-bottom: 1rem;

  @media ${breakpoints.sm} {
    padding: 0.5rem;
  }
  .ownerCard {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-shadow: ${(props) =>
      props.isSelected ? '0 4px 16px rgba(0, 0, 0, 0.10)' : 'none'};
    border: 1px solid ${(props) => props.theme.colors.whiteColor};
    .ownerHeader {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background-color: ${(props) =>
        props.isSelected ? props.theme.colors.lightBlueColor : '#E2E8EF'};
      padding: 0.54rem 1rem;
      border-radius: 8px 8px 0 0;
    }
    path {
      fill: ${(props) =>
        props.isSelected
          ? props.theme.colors.whiteColor
          : props.theme.colors.darkBlueColor};
    }
    :hover {
      border-radius: 8px;
      border: 1px solid ${(props) => props.isSelected ? 'none' : props.theme.colors.lightBlueColor};
      transition: border 0.3s ease-in-out 0s;
    }
  }
  .ownerName {
    color: ${(props) =>
      props.isSelected
        ? props.theme.colors.whiteColor
        : props.theme.colors.darkBlueColor};
    font-size: ${fontSizes.lg};
    font-weight: 600;
  }
  .addressText {
    font-size: ${fontSizes.md};
    color: ${(props) => props.theme.colors.darkBlueColor};
    border: 1px solid
      ${(props) =>
        props.isSelected ? props.theme.colors.lightBlueColor : '#E2E8EF'};
    padding: 0.54rem 1rem;
    border-radius: 0 0 8px 8px;
  }
  .address {
    font-weight: 600;
    font-size: 16px;
    margin-left: 1rem;
  }
  .ownerCircle {
    margin-left: auto;
    .outsideCircle {
      width: 18px;
      height: 18px;
      background-color: ${(props) => props.theme.colors.whiteColor};
      border-radius: 50%;
      position: relative;
    }
    .insideCircle {
      width: 12px;
      height: 12px;
      background-color: ${(props) => props.theme.colors.darkBlueColor};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: ${(props) => (props.isSelected ? 'block' : 'none')};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2em 3rem 2em;
  border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 8px 8px 0 0;

  .flatsList {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
  }
  @media ${breakpoints.sm} {
    button {
      width: 100%;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export default ChoseFlatModal;
