import PropTypes from 'prop-types';
import { breakpoints } from 'style/breakpoints';
import styled from 'styled-components';

const ModalTitle = ({ children, className }) => {
  return (
    <Wrapper className={className}>
      <h2>{children}</h2>
    </Wrapper>
  );
};

ModalTitle.propTypes = {
  className: PropTypes.string,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h2 {
    font-weight: 800;
    color: ${(props) => props.theme.colors.blackColor};
    text-align: center;
    font-size: 22px;
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
  }
`;

export default ModalTitle;
