const SvgComponent = (props) => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.833252 11.8333V0.166626H2.60409V11.8333H0.833252ZM3.33325 11.8333V0.166626H4.99992V11.8333H3.33325ZM5.83325 11.8333V0.166626H6.66659V11.8333H5.83325ZM8.33325 11.8333V0.166626H9.99992V11.8333H8.33325ZM10.8333 11.8333V0.166626H13.3333V11.8333H10.8333ZM14.1666 11.8333V0.166626H14.9999V11.8333H14.1666ZM16.6666 11.8333V0.166626H19.1666V11.8333H16.6666Z"
      fill={props?.fill ? props.fill : 'black'}
    />
  </svg>
);

export default SvgComponent;
