import api from 'api';

const url = '/auth/register';

export const postRegisterApi = async (email, password, hash_code) => {
  const res = await api.post(`${url}`, {
    hash_code : hash_code,
    email: email,
    password: password,
    terms: true
  });

  return res.data;
};
