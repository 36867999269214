import CustomInput from 'components/Common/CustomInput';
import styled from 'styled-components';
import inputVariants from 'utils/constants/inputVariants';
import pxToRem from 'utils/helpers/pxToRem';
import { useEffect, useState } from 'react';
import CustomButton from 'components/Common/CustomButton';
import buttonTypes from 'utils/constants/buttonTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import {
    sessionExpiredMessage,
    sessionExpiredParam,
} from 'utils/constants/auth';
import QrCodeIcon from 'assets/auth/inputs/QrCodeIcon';
import { useModalStore } from 'store/ModalStore';
import { postResetPassword } from "../../api/userProfile";
import {useAuthStore} from "../../store/AuthStore";
import { maxLength, minLength, nestValidators, validateFormAndReturnErrorsObjectOrNull, validateFormInput } from 'utils/helpers/validation';
import parseServerError from 'utils/helpers/parseServerError';

const ResetPassword = () => {
    const toggleModal = useModalStore((state) => state.toggleModal);
    const navigate = useNavigate();

    const location = useLocation();
    const [errors,setErrors]=useState({})
    const [serverError,setServerError]=useState("")
    const searchParams = new URLSearchParams(location.search);

    const sessionExpired = searchParams.get(sessionExpiredParam);

    const [formData, setFormData] = useState({
        code: searchParams.get('code') ?? '',
        passwordFirst: "",
        passwordSecond: ""
    });

    const [error, setError] = useState({
        field: '',
        message: '',
    });

    const [saveToken, removeToken, accessToken] = useAuthStore((state) => [
        state.saveToken,
        state.removeToken,
        state.token,
    ]);

    const onInput = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        const inputErrorMessage = validateFormInput(
            FORM_VALIDATORS,
            inputName,
            inputValue
          );
          setErrors((prev) => ({ ...prev, [inputName]: inputErrorMessage }));
          if(inputName==='passwordSecond' && inputValue!==formData.passwordFirst || (inputName==='passwordFirst' && inputValue!==formData.passwordSecond && formData.passwordSecond)){
              setErrors((prev) => ({ ...prev, passwordSecond:'Lozinke nisu iste' }));
          }
    

        return setFormData({
            ...formData,
            [inputName]: inputValue,
        });
    };

    const onResetPassword = async () => {
        const obj = {
            code: "",
            password: {
                first: "",
                second: ""
            }
        };

        obj.code = formData.code;
        obj.password.first = formData.passwordFirst;
        obj.password.second = formData.passwordSecond;
        const validationErrors = validateFormAndReturnErrorsObjectOrNull(
            formData,
            FORM_VALIDATORS
          );
         validationErrors ? setErrors(validationErrors) : setErrors({});
        if(validationErrors || formData.passwordFirst!==formData.passwordSecond) return
        
        try{
            const res = await postResetPassword(obj);
            navigate(routes.logIn.path, { replace: true });
        }
        catch(err){
            setServerError(parseServerError(err))
        }


    };

    useEffect(() => {
        if (accessToken) {
            navigate(routes.dashboard.path, { replace: true });
        }
    }, [accessToken]);

    return (
        <Wrapper>
            <LoginHeader>
                <h2>Nova Lozinka</h2>
            </LoginHeader>

            {sessionExpired && <p>{sessionExpiredMessage}</p>}
            <Form>
                <CustomInput
                    label="Kod za obnovu lozinke"
                    type="text"
                    name="code"
                    variant={inputVariants.auth}
                    onChange={onInput}
                    value={formData.code}
                    height={pxToRem(44)}
                    errorMessage={errors.code ?? serverError}
                />

                <CustomInput
                    label="Nova Lozinka"
                    type="password"
                    name="passwordFirst"
                    variant={inputVariants.auth}
                    iconHeigh={pxToRem(13)}
                    iconWidth={pxToRem(17)}
                    onChange={onInput}
                    value={formData.passwordFirst}
                    height={pxToRem(44)}
                    errorMessage={errors.passwordFirst}
                />

               <div>
               <CustomInput
                    label="Ponovi Novu Lozinku"
                    type="password"
                    name="passwordSecond"
                    variant={inputVariants.auth}
                    Icon={QrCodeIcon}
                    iconHeigh={pxToRem(13)}
                    iconWidth={pxToRem(17)}
                    onChange={onInput}
                    value={formData.passwordSecond}
                    height={pxToRem(44)}
                    errorMessage={errors.passwordSecond}
                    
                />
               </div>
                <CustomButton
                    height={pxToRem(44)}
                    className="mt-4"
                    type={buttonTypes.primary}
                    onClick={() => onResetPassword()}
                >
                    Pošalji
                </CustomButton>
            </Form>
        </Wrapper>
    );
};

const Wrapper = styled.div``;
const FORM_VALIDATORS = {
    passwordFirst: nestValidators(
      minLength(8, 'Lozinka mora imati 8 karaktera'),
      maxLength(16, 'Lozinka ne sme imati više od 16 karaktera')
    ),
    code:nestValidators(
        minLength(6, 'Kod treba da ima 6 karaktera'),
        maxLength(6, 'Kod treba da ima 6 karaktera')
    ),
  };

const LoginHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;

  h2 {
    font-weight: 800;
    color: ${(props) => props.theme.colors.blackColor};
    text-align: center;
    font-size: 22px;
  }
 }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export default ResetPassword;
