import PropTypes from 'prop-types';
import { fontSizes } from 'style/fontSizes';
import styled, { useTheme } from 'styled-components';
import Pagination from 'rc-pagination';
import RightChevron from 'assets/RightChevron';
import LeftChevron from 'assets/LeftChevron';

const CustomPagination = ({ paginationData, onChange }) => {
  const theme = useTheme();

  const isFirstPage = () => paginationData.page === 1;

  const isLastPage = () =>
    paginationData.page === paginationData.pageNumbers || isNoItems();

  const isNoItems = () => paginationData.totalRecords === 0;

  if (!paginationData) {
    return null;
  }

  return (
    <PaginationWrapper
      current={paginationData.page || 1}
      total={paginationData.totalRecords}
      pageSize={paginationData.pageSize}
      // hideOnSinglePage
      showLessItems
      jumpPrevIcon={() => <span style={{ fontWeight: '900' }}>...</span>}
      jumpNextIcon={() => <span style={{ fontWeight: '900' }}>...</span>}
      prevIcon={
        <Icon>
          <LeftChevron
            fill={isFirstPage() ? theme.colors.disabled : theme.colors.primary}
          />
        </Icon>
      }
      nextIcon={
        <Icon>
          <RightChevron
            fill={isLastPage() ? theme.colors.disabled : theme.colors.primary}
          />
        </Icon>
      }
      onChange={(page, pageSize) =>
        typeof onChange === 'function' && onChange(page, pageSize)
      }
    />
  );
};

CustomPagination.propTypes = {
  paginationData: PropTypes.shape({
    // Current page
    page: PropTypes.number,
    // Total number of records from db
    totalRecords: PropTypes.number,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: PropTypes.number,
    // Per page !
    pageSize: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

const PaginationWrapper = styled(Pagination)`
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 12px;

  font-style: normal;
  font-size: ${fontSizes.sm};

  color: ${(props) => props.theme.colors.darkBlueColor};
  font-weight: 400;

  .rc-pagination-item-active {
    color: ${(props) => props.theme.colors.darkBlueColor};
    font-style: normal;
    font-weight: 800;
  }

  * {
    cursor: pointer;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 600;
  }
`;

export default CustomPagination;
