import { useModalStore } from 'store/ModalStore';
import { useState } from 'react';
import styled from 'styled-components';
import CustomButton from 'components/Common/CustomButton';
import CustomInput from "../Common/CustomInput";
import inputVariants from "../../utils/constants/inputVariants";
import pxToRem from "../../utils/helpers/pxToRem";
import buttonTypes from "../../utils/constants/buttonTypes";
import {breakpoints} from "../../style/breakpoints";
import {postChangePassword} from "../../api/userProfile";
import { maxLength, minLength, nestValidators, validateFormAndReturnErrorsObjectOrNull, validateFormInput } from 'utils/helpers/validation';
import parseServerError from 'utils/helpers/parseServerError';
import ErrorMessage from 'components/Common/ErrorMessage';
import TickMark from "../../assets/img/tick_mark_icon.svg";
import VoteImage from "../../assets/img/vote_icon.svg";

const ChangePasswordModal = () => {
   const [serverError,setServerError]=useState("")

    const modalProps = useModalStore((state) => state.modalProps);

    const [formData, setFormData] = useState({
        currentPassword: "",
        passwordFirst: "",
        passwordSecond: ""
    });
    const [submitted,setSubmitted]=useState(false)

    const [errors,setErrors]=useState({})

    const onChangePassword = async () => {
        const obj = {
            currentPassword: "",
            password: {
                first: "",
                second: ""
            }
        };
        const validationErrors = validateFormAndReturnErrorsObjectOrNull(
            formData,
            FORM_VALIDATORS
          );
        validationErrors ? setErrors(validationErrors) : setErrors({});
        if(validationErrors || formData.passwordFirst!==formData.passwordSecond) return
        obj.currentPassword = formData.currentPassword;
        obj.password.first = formData.passwordFirst;
        obj.password.second = formData.passwordSecond;

        try{
            const res = await postChangePassword(obj);
            setSubmitted(true)
        }
        catch(err){
            setServerError(parseServerError(err))
        }
    }

    const onInput = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        const inputErrorMessage = validateFormInput(
          FORM_VALIDATORS,
          inputName,
          inputValue
        );

        setErrors((prev) => ({ ...prev, [inputName]: inputErrorMessage }));
        if(inputName==='passwordSecond' && inputValue!==formData.passwordFirst){
            setErrors((prev) => ({ ...prev, passwordSecond:'Lozinke nisu iste' }));
        }
      
        return setFormData({
            ...formData,
            [inputName]: inputValue,
        });
    };

    return (
        <Wrapper>
            <LoginHeader>
                <h2>Promeni Lozinku</h2>
            </LoginHeader>
            <Form>
                <CustomInput
                    label="Unesi trenutnu lozinku"
                    type="password"
                    name="currentPassword"
                    variant={inputVariants.auth}
                    onChange={onInput}
                    height={pxToRem(44)}
                    errorMessage={errors.currentPassword ?? serverError}
                 
                />
                <CustomInput
                    label="Unesi novu lozinku"
                    type="password"
                    name="passwordFirst"
                    variant={inputVariants.auth}
                    onChange={onInput}
                    height={pxToRem(44)}
                    errorMessage={errors.passwordFirst}
              
                />
                <CustomInput
                    label="Potvrdi lozinku"
                    type="password"
                    name="passwordSecond"
                    variant={inputVariants.auth}
                    onChange={onInput}
                    height={pxToRem(44)}
                    errorMessage={errors.passwordSecond}
        
                />
                {submitted ? (
                <div className="wrapper-success">
                    <img className="tick-mark" src={TickMark} alt="tick mark image"/>
                    <img className="vote-icon" src={VoteImage} alt="vote image"/>
                    <div className="text-wrapper">
                        <h4>
                            Hvala
                        </h4>
                        <h5>
                            Uspešno ste promenili lozinku
                        </h5>
                    </div>
                </div>
                ) : 
                <CustomButton
                    height={pxToRem(44)}
                    className="mt-4"
                    type={buttonTypes.primary}
                    onClick={() => onChangePassword()}
                    >
                    Pošalji
                </CustomButton>
                }
            </Form>
        </Wrapper>
    );
};

const FORM_VALIDATORS = {
    passwordFirst: nestValidators(
      minLength(8, 'Lozinka mora imati 8 karaktera'),
      maxLength(16, 'Lozinka ne sme imati više od 16 karaktera')
    ),
  };

const Wrapper = styled.div`
  font-size: 0.875rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 3.6rem 3rem 3.6rem;
  border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 8px 8px 0 0;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
  .wrapper-success {
    display: flex;
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    padding: .5rem;
    width: 100%;
    position: relative;
    
    .tick-mark {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    
    .vote-icon {
      margin-left: 1rem;
      margin-right: auto;
      padding-right: 2.5rem;
      border-right: 2px solid ${(props) => props.theme.colors.whiteColor};
    }
    .text-wrapper {
      margin-left: .5rem;
      padding: 0.5rem;
      width: 70%;
      text-align: center;
      background-color: ${(props) => props.theme.colors.whiteColor};
      
      h4 {
        font-size: 21px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.lightBlueColor};
      }
      
      h5 {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.darkBlueColor};
      }
    }
`;

const LoginHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;

  h2 {
    font-weight: 800;
    color: ${(props) => props.theme.colors.blackColor};
    text-align: center;
    font-size: 22px;
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
  }
`;

export default ChangePasswordModal;
