const SvgComponent = (props) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.45 4.8V1.5H5.55V4.8H4.05V0.75C4.05 0.5375 4.12188 0.359375 4.26562 0.215625C4.40938 0.071875 4.5875 0 4.8 0H15.2C15.4125 0 15.5906 0.071875 15.7344 0.215625C15.8781 0.359375 15.95 0.5375 15.95 0.75V4.8H14.45ZM16.475 8.675C16.675 8.675 16.85 8.6 17 8.45C17.15 8.3 17.225 8.125 17.225 7.925C17.225 7.725 17.15 7.55 17 7.4C16.85 7.25 16.675 7.175 16.475 7.175C16.275 7.175 16.1 7.25 15.95 7.4C15.8 7.55 15.725 7.725 15.725 7.925C15.725 8.125 15.8 8.3 15.95 8.45C16.1 8.6 16.275 8.675 16.475 8.675ZM5.55 16.5H14.45V11.7H5.55V16.5ZM5.55 18C5.1375 18 4.78438 17.8531 4.49062 17.5594C4.19688 17.2656 4.05 16.9125 4.05 16.5V13.6H0.75C0.5375 13.6 0.359375 13.5281 0.215625 13.3844C0.071875 13.2406 0 13.0625 0 12.85V7.45C0 6.69917 0.254167 6.06979 0.7625 5.56188C1.27083 5.05396 1.9 4.8 2.65 4.8H17.35C18.1008 4.8 18.7302 5.05396 19.2381 5.56188C19.746 6.06979 20 6.69917 20 7.45V12.85C20 13.0625 19.9281 13.2406 19.7844 13.3844C19.6406 13.5281 19.4625 13.6 19.25 13.6H15.95V16.5C15.95 16.9125 15.8031 17.2656 15.5094 17.5594C15.2156 17.8531 14.8625 18 14.45 18H5.55ZM18.5 12.1V7.44462C18.5 7.11487 18.3898 6.84167 18.1694 6.625C17.949 6.40833 17.6758 6.3 17.35 6.3H2.65C2.32417 6.3 2.05104 6.41021 1.83063 6.63063C1.61021 6.85104 1.5 7.12417 1.5 7.45V12.1H4.05V10.2H15.95V12.1H18.5Z"
            fill={props?.fill ? props?.fill : '#C1CBD7'}
        />
    </svg>
);

export default SvgComponent;
