const transformDateTime = (timestamp) => {
    return `${new Date(timestamp).getDate().toString().padStart(2, '0')}.
            ${(new Date(timestamp).getMonth() + 1).toString().padStart(2, '0')}.
            ${new Date(timestamp).getFullYear()}.
            ${new Date(timestamp).getHours().toString().padStart(2, '0')}:
            ${new Date(timestamp).getMinutes().toString().padStart(2, '0')}:
            ${new Date(timestamp).getSeconds().toString().padStart(2, '0')}
    `;
};

export default transformDateTime;
