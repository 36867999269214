import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useModalStore } from 'store/ModalStore';
import styled from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import { useEtableStore } from '../../store/EtableStore';
import { breakpoints } from '../../style/breakpoints';
import transformDate from '../../utils/constants/transformDate';
import ProblemsIcon from '../../assets/img/problems_icon.svg';
import { getProblemsApi } from '../../api/problems';
import CustomButton from '../../components/Common/CustomButton';
import { useProblemsStore } from '../../store/ProblemsStore';
import { lightTheme as themes } from '../../style/themes';
import { fontSizes } from '../../style/fontSizes';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

const Problems = () => {
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const tableHeaders = [
    {
      name: 'comment',
      displayName: '',
      hasOrder: true,
    },
    {
      name: 'status',
      displayName: 'Status',
      hasOrder: true,
    },
    {
      name: 'createdAt',
      displayName: 'Datum',
      hasOrder: true,
    },
    {
      name: 'taskType',
      displayName: 'Tip Problema',
      hasOrder: true,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);
  const selectedFlatId = useSelectedFlatId();

  const fillStore = useEtableStore((state) => state.fillStore);

  // TODO: Use this from API.
  const [Problems, setProblems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [currentStatus, setCurrentStatus] = useState(null);

  // TODO: Use this from API.
  const [paginationData, setPaginationData] = useState({
    // Current page
    page: 1,
    // Total number of records from db
    totalRecords: 0,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: 0,
    // Per page !
    pageSize: pageSizeOptions[0],

    // Sorting info
    sortBy: '',
    sortOrder: '',

    // Filter info - search
    filter: '',
  });

  const getProblemsData = async (status) => {
    let page = paginationData.page;

    if (status !== null) {
      if (currentStatus === status) {
        status = null;
      } else {
        page = 1;
      }
    } else {
      status = currentStatus;
    }

    setCurrentStatus(status);

    const res = await getProblemsApi(
      selectedFlatId,
      page,
      paginationData.pageSize.value,
      status
    );

    const data = res.data.map((item) => {
      const commentLine = item.comment ? (
        <p>
          <strong
            style={{
              color: themes.colors.darkBlueColor,
              fontSize: fontSizes.md,
            }}
          >
            Odgovor Upravnika:{' '}
          </strong>{' '}
          {item.comment}
        </p>
      ) : null;

      return {
        ...item,
        comment: item.comment === '' || item.comment === null ? (
          <></>
        ) : (
          <ComentCircleWrapper
            title="Ima odgovor upravnika"
          >
            <ComentCircle
              type={item.comment}
            />
          </ComentCircleWrapper>
        ),
        createdAt: transformDate(item.createdAt),
        status: item.status === 0 ? (
          <StatusBadge
            type={item.status}
          >
            Otvoren
          </StatusBadge>
        ) : item.status === 1 ? (
          <StatusBadge
            type={item.status}
          >
            U izradi
          </StatusBadge>
        ) : (
          <StatusBadge
            type={item.status}
          >
            Zatvoren
          </StatusBadge>
        ),
        taskType: <strong>{item.taskType}</strong>,
        expandedRowText: (
          <div>
            <p>
              <strong>Opis problema: </strong> {item.description}
            </p>
            {commentLine}
          </div>
        ),
        expandedRowDateTime: (
          <span
            style={{
              color: themes.colors.whiteColor,
              backgroundColor: themes.colors.darkBlueColor,
              fontSize: fontSizes.xs,
              padding: '4px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              borderRadius: '2rem',
              textAlign: 'center',
            }}
          >
            {item.taskSubtype}
          </span>
        ),
      };
    });
    fillStore(data);
    const problems = useProblemsStore.getState().problems;

    setPaginationData((prevData) => ({
      ...prevData,
      totalRecords: res.count,
      pageNumbers: Math.ceil(Problems.length / paginationData.pageSize.value),
      page: page,
    }));

    setProblems(data);
    setIsLoading(false);

    return problems;
  };

  const onChangePage = (value) => {
    setPaginationData({
      ...paginationData,
      page: value,
    });

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setIsLoading(true);

    getProblemsData(null);
  }, [
    paginationData.page,
    paginationData.pageSize.value,
    paginationData.sortBy,
    paginationData.sortOrder,
    paginationData.filter,
    selectedFlatId,
  ]);

  const onChangeOrder = (value) => {
    setPaginationData({
      ...paginationData,
      sortBy: value.type,
      sortOrder: value.headerName,
    });
  };

  return (
    <Wrapper>
      <PageHeader>
        <TableHeaderInfo>
          <div>
            <img
              src={ProblemsIcon}
              alt="e-table list icon"
            />
          </div>
          <h2>Lista prijavljenih problema</h2>
        </TableHeaderInfo>
        <TableHeaderAction>
          <CustomButton
            height={'30px'}
            width={'82px'}
            backgroundColor={
              currentStatus === 0
                ? themes.colors.lightBlueColor
                : themes.colors.whiteColor
            }
            color={
              currentStatus === 0
                ? themes.colors.whiteColor
                : themes.colors.darkBlueColor
            }
            style={{ fontSize: fontSizes.sm, borderRadius: '1.2rem' }}
            onClick={() => getProblemsData(0)}
          >
            Otvoren
          </CustomButton>

          <CustomButton
            height={'30px'}
            width={'82px'}
            backgroundColor={
              currentStatus === 1
                ? themes.colors.lightBlueColor
                : themes.colors.whiteColor
            }
            color={
              currentStatus === 1
                ? themes.colors.whiteColor
                : themes.colors.darkBlueColor
            }
            style={{ fontSize: fontSizes.sm, borderRadius: '1rem' }}
            onClick={() => getProblemsData(1)}
          >
            U Izradi
          </CustomButton>

          <CustomButton
            height={'30px'}
            width={'82px'}
            backgroundColor={
              currentStatus === 2
                ? themes.colors.lightBlueColor
                : themes.colors.whiteColor
            }
            color={
              currentStatus === 2
                ? themes.colors.whiteColor
                : themes.colors.darkBlueColor
            }
            style={{ fontSize: fontSizes.sm, borderRadius: '1.2rem' }}
            onClick={() => getProblemsData(2)}
          >
            Zatvoren
          </CustomButton>
        </TableHeaderAction>
      </PageHeader>

      {isLoading ? (
        <SpinnerPlace>
          <Spinner
            color="secondary"
            type="border"
          />
        </SpinnerPlace>
      ) : (
        <CustomTable
          headers={tableHeaders}
          items={Problems}
          isStriped
          paginationData={{
            page: paginationData.page,
            pageNumbers: paginationData.pageNumbers,
            pageSize: paginationData.pageSize.value,
            totalRecords: paginationData.totalRecords,
          }}
          onChangePage={onChangePage}
          onClickOnOrder={onChangeOrder}
          isExpandable={true}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const TableHeaderAction = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-right: ${pxToRem(12)};

  @media ${breakpoints.md} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SpinnerPlace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

const StatusBadge = styled.div`
  color: ${(props) => props.theme.colors.whiteColor};
  background-color: ${(props) =>
    (props.type === 0 ? props.theme.colors.status.inactive : props.type === 1 ? props.theme.colors.status.pending : props.theme.colors.status.active)};
  font-size: ${fontSizes.xs};
  padding: 0 4px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 2rem;
  text-align: center;
  width: 70px;
`;

const ComentCircleWrapper = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
`;

const ComentCircle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 50%;
  margin: auto;
`;

export default Problems;
