const SvgComponent = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5.00033 15.0001L2.72949 17.2709C2.53505 17.4654 2.30935 17.5105 2.05241 17.4063C1.79546 17.3022 1.66699 17.1112 1.66699 16.8334V2.91675C1.66699 2.58341 1.79199 2.29175 2.04199 2.04175C2.29199 1.79175 2.58366 1.66675 2.91699 1.66675H17.0837C17.417 1.66675 17.7087 1.79175 17.9587 2.04175C18.2087 2.29175 18.3337 2.58341 18.3337 2.91675V13.7501C18.3337 14.0834 18.2087 14.3751 17.9587 14.6251C17.7087 14.8751 17.417 15.0001 17.0837 15.0001H5.00033ZM2.91699 13.7501H17.0837V2.91675H2.91699V13.7501ZM10.0003 9.20841L11.7295 10.9376C11.8545 11.0626 12.0003 11.1251 12.167 11.1251C12.3337 11.1251 12.4795 11.0626 12.6045 10.9376C12.7295 10.8126 12.792 10.6667 12.792 10.5001C12.792 10.3334 12.7295 10.1876 12.6045 10.0626L10.8753 8.33341L12.6045 6.60425C12.7295 6.47925 12.792 6.33341 12.792 6.16675C12.792 6.00008 12.7295 5.85425 12.6045 5.72925C12.4795 5.60425 12.3337 5.54175 12.167 5.54175C12.0003 5.54175 11.8545 5.60425 11.7295 5.72925L10.0003 7.45841L8.27116 5.72925C8.14616 5.60425 8.00033 5.54175 7.83366 5.54175C7.66699 5.54175 7.52116 5.60425 7.39616 5.72925C7.27116 5.85425 7.20866 6.00008 7.20866 6.16675C7.20866 6.33341 7.27116 6.47925 7.39616 6.60425L9.12533 8.33341L7.39616 10.0626C7.27116 10.1876 7.20866 10.3334 7.20866 10.5001C7.20866 10.6667 7.27116 10.8126 7.39616 10.9376C7.52116 11.0626 7.66699 11.1251 7.83366 11.1251C8.00033 11.1251 8.14616 11.0626 8.27116 10.9376L10.0003 9.20841Z"
              fill={props?.fill ? props.fill : 'black'}
        />

    </svg>
);

export default SvgComponent;
