import { useState } from 'react';
import styled from 'styled-components';
import {
  maxLength,
  minLength,
  nestValidators,
  validateFormAndReturnErrorsObjectOrNull,
  validateFormInput,
} from 'utils/helpers/validation';
import pxToRem from '../../utils/helpers/pxToRem';
import { addFlatToResident } from 'api/flat';
import { useNavigate } from 'react-router-dom';
import { useModalStore } from 'store/ModalStore';
import CustomInput from 'components/Common/CustomInput';
import parseServerError from 'utils/helpers/parseServerError';
import { modalTypeNames } from 'utils/constants/modalTypes';
import ModalTitle from 'components/Common/ModalTitle';
import { breakpoints } from 'style/breakpoints';
import CustomButton from "../Common/CustomButton";
import buttonTypes from "../../utils/constants/buttonTypes";

const AddFlatModal = () => {
  const nav = useNavigate();
  const toggleModal = useModalStore((state) => state.toggleModal);
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const [formData, setFormData] = useState({
    code: '',
  });

  const onAddFlat = async () => {
    const validationErrors = validateFormAndReturnErrorsObjectOrNull(
      formData,
      FORM_VALIDATORS
    );
    validationErrors ? setErrors(validationErrors) : setErrors({});
    if (validationErrors) return;
    try {
      await addFlatToResident(formData.code);
      toggleModal(modalTypeNames.chooseFlatModal);
    } catch (err) {
      setServerError(parseServerError(err));
    }
  };

  const onInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const inputErrorMessage = validateFormInput(
      FORM_VALIDATORS,
      inputName,
      inputValue
    );

    setErrors((prev) => ({ ...prev, [inputName]: inputErrorMessage }));

    return setFormData({
      ...formData,
      [inputName]: inputValue,
    });
  };

  return (
    <Wrapper>
      <Form>
        <ModalTitle>Dodaj novu stambenu jedinicu</ModalTitle>
        <CustomInput
          label="Unesi kod sa računa"
          name="code"
          onChange={onInput}
          height={pxToRem(44)}
          errorMessage={errors.code ?? serverError}
        />
        <CustomButton
          onClick={onAddFlat}
          type={buttonTypes.primary}
          height={pxToRem(44)}
        >
          Potvrdi
        </CustomButton>
      </Form>
    </Wrapper>
  );
};

const FORM_VALIDATORS = {
  code: nestValidators(
    minLength(5, 'Kod treba da ima 5 karaktera'),
    maxLength(5, 'Kod treba da ima 5 karaktera')
  ),
};

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 3.6rem 3rem 3.6rem;
  border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
  border-radius: 8px 8px 0 0;
  @media ${breakpoints.sm} {
    button {
      min-width: 100%;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export default AddFlatModal;
