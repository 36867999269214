import styled from 'styled-components';

const NoRecordsFound = () => {
  return (
    <Wrapper>
      <h2>Nema podataka.</h2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  padding: 20px;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    font-weight: 700;
    color: ${(props) => props.theme.colors.textWeak};
  }
`;

export default NoRecordsFound;
