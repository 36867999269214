const SvgComponent = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5417 4.125L11.875 1.45834L12.75 0.583336C12.9861 0.347225 13.2812 0.232642 13.6354 0.239586C13.9896 0.24653 14.2847 0.368058 14.5208 0.604169L15.4167 1.5C15.6528 1.73611 15.7708 2.02778 15.7708 2.375C15.7708 2.72222 15.6528 3.01389 15.4167 3.25L14.5417 4.125ZM13.6667 5L3.16667 15.5H0.5V12.8333L11 2.33334L13.6667 5Z"
      fill={props?.fill ? props?.fill : 'black'}
    />
  </svg>
);
export default SvgComponent;
