import styled from 'styled-components';
import CoverImage from 'assets/img/login_background.jpg';
import { fontSizes } from 'style/fontSizes';
import pxToRem from 'utils/helpers/pxToRem';
import LogoImage from 'assets/img/codeus_logo.png';
import { breakpoints } from 'style/breakpoints';
import LogoLogin from 'assets/img/troskovi_logo.svg'
import CustomModal from "../../Common/CustomModal";
import {Link} from "react-router-dom";

const AuthLayout = ({ children }) => {
  return (
    <Wrapper>
        <CustomModal />
      <ImageWrapper
        image={CoverImage}
        className="col-12"
        loading="lazy"
      />
      <LogoLoginPlace>
          <Link to={'https://troskovi.com'} target={"_blank"}>
            <img src={LogoLogin} alt="logo login icon" />
          </Link>
      </LogoLoginPlace>
      <ContentWrapper className="col-10 col-md-8">
        <Content>{children}
          <LogoImageWrapper
            image={LogoImage}
            loading="lazy"
          />
        </Content>

      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding-top: ${pxToRem(60)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px;
  row-gap: 3vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
`;

const LogoImageWrapper = styled.div`
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 28px;
  background: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-position: center;

  @media (orientation: portrait) {
    display: none;
  }
`;

const Content = styled.div`
  margin: auto;
  width: min(500px, 100%);
  background-color: ${(props) => props.theme.colors.whiteColor};
  padding: 20px 60px;
  border-radius: 8px;
  border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};

  .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: ${fontSizes.xs};
    line-height: 18px;
  }

  input {
    background-color: ${(props) => props.theme.colors.inputColor} !important;
    font-size: ${pxToRem(16)};
    box-shadow: none;
    color: ${(props) => props.theme.colors.text} !important;
	opacity: 0.4 !important;

    :focus {
      border: 1px solid ${(props) => props.theme.colors.lightBlueColor} !important;
      background-color: ${(props) => props.theme.colors.inputFocusColor} !important;
      box-shadow: ${(props) => `4px 4px 16px ${props.theme.colors.inputShadowColor}`} !important;
	  opacity: 1 !important;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: ${fontSizes.md};
      color: ${(props) => props.theme.colors.blackColor};
      opacity: 0.5 !important;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: ${fontSizes.md};
      color: ${(props) => props.theme.colors.blackColor};
      opacity: 0.5 !important;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: ${fontSizes.md};
      color: ${(props) => props.theme.colors.blackColor};
      opacity: 0.5 !important;
    }
  }

  @media ${breakpoints.sm} {
    padding: 20px 20px;
  }
`;

const ImageWrapper = styled.div`
  background: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const LogoLoginPlace = styled.div`
  position: absolute;
  left: 20px;
  top: 15px;

  @media ${breakpoints.xs} {
    top: 10px;
  }
`;

export default AuthLayout;
