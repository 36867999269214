import pxToRem from '../../utils/helpers/pxToRem';
import styled from 'styled-components';
import { breakpoints } from '../../style/breakpoints';
import SettingsTableIcon from 'assets/img/settings_table_icon.svg';
import { modalTypeNames } from '../../utils/constants/modalTypes';
import { useModalStore } from '../../store/ModalStore';
import { fontSizes } from '../../style/fontSizes';
import { ReactComponent as SettingArrow } from '../../assets/img/settings_arrow.svg';
import { ReactComponent as SettingTerms } from '../../assets/img/settings_terms.svg';
import { ReactComponent as SettingChangePassword } from '../../assets/img/settings_change_password.svg';
import { ReactComponent as SettingRemoveAccount } from '../../assets/img/settings_remove_account.svg';

const Settings = () => {
  const toggleModal = useModalStore((state) => state.toggleModal);

  const onTermsClick = () => {
    toggleModal(modalTypeNames.termsModal, {
      modalName: 'Politika Privatnosti',
    });
  };
  const handleDeleteAccount = () => {
    //TODO API call for delete account
    toggleModal();
  };

  const onDeleteAccoutClick = () => {
    toggleModal(modalTypeNames.confirmationModal, {
      confirmationMessage: 'Da li ste sigurni da želite da obrišete nalog?',
      onConfirm: handleDeleteAccount,
    });
  };

  const onChangePasswordClick = () => {
    toggleModal(modalTypeNames.changePasswordModal, {
      modalName: 'Promeni lozinku',
    });
  };

  return (
    <>
      <PageHeader>
        <TableHeaderInfo>
          <div>
            <img
              src={SettingsTableIcon}
              alt="settings icon"
            />
          </div>
          <h2>Moja Podešavanja</h2>
        </TableHeaderInfo>
      </PageHeader>
      <SettingsWrapper>
        <a
            href="#"
            onClick={() => onChangePasswordClick()}
        >
          <IconWrapper>
            <SettingChangePassword />
          </IconWrapper>
          <TextWrapper>Promeni Lozinku</TextWrapper>
          <ArrowWrapper>
            <SettingArrow />
          </ArrowWrapper>
        </a>
        <a
          href="#"
          onClick={() => onTermsClick()}
        >
          <IconWrapper>
            <SettingTerms />
          </IconWrapper>
          <TextWrapper>Politika Privatnosti</TextWrapper>
          <ArrowWrapper>
            <SettingArrow />
          </ArrowWrapper>
        </a>
        <a
          href="#"
          onClick={onDeleteAccoutClick}
        >
          <IconWrapper>
            <SettingRemoveAccount />
          </IconWrapper>
          <TextWrapper>Obriši Nalog</TextWrapper>
          <ArrowWrapper>
            <SettingArrow />
          </ArrowWrapper>
        </a>
      </SettingsWrapper>
    </>
  );
};

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const SettingsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.whiteColor};
  border-radius: 8px;
  margin-top: -8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  a {
    font-size: ${fontSizes.lg};
    color: ${(props) => props.theme.colors.lightBlackColor};
    background-color: ${(props) => props.theme.colors.whiteColor};
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.whiteColor};
    transition: all 0.3s, color 0.3s;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    font-weight: 600;
    width: 100%;
    max-width: 367px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
    padding: 12px 0;

    :hover {
      border: 1px solid ${(props) => props.theme.colors.lightBlueColor};
    }
  }

  @media ${breakpoints.lg} {
    flex-direction: column;
  }
`;

const IconWrapper = styled.span`
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  border-radius: 8px;
  width: 100%;
  max-width: 80px;
  padding: 15px 20px;
  margin-left: 1rem;
  transition: all 0.3s, color 0.3s;

  a:hover & {
    background-color: ${(props) => props.theme.colors.lightBlueColor};

    svg path, svg g path {
      fill: ${(props) => props.theme.colors.whiteColor};
    }
  }
`;

const TextWrapper = styled.span`
  margin-left: 2rem;
  flex: 1;
`;

const ArrowWrapper = styled.span`
  margin-right: 1rem;
`;

export default Settings;
