import styled from "styled-components"
import Facebook from 'assets/img/facebook_footer.svg'
import Instagram from 'assets/img/insta_footer.svg'
import LinkedIn from 'assets/img/linkedIn_footer.svg'
import Youtube from 'assets/img/youtube_footer.svg'

export const Social = () => {
    return (
        <Socials>
            <a href="https://www.facebook.com/troskovicom/" target="_blank" rel="noopener noreferrer">
                <img src={Facebook} alt="facebook logo" />
            </a>
            <a href="https://www.instagram.com/troskovi_com_/" target="_blank" rel="noopener noreferrer">
                <img src={Instagram} alt="instagram logo" />
            </a>
            <a href="https://www.linkedin.com/company/tro%C5%A1kovi-program-za-upravnike-i-profesionalne-upravnike/about/" target="_blank" rel="noopener noreferrer">
                <img src={LinkedIn} alt="linkedIn logo" />
            </a>
            <a href="https://www.youtube.com/@troskovicodeus9882/featured" target="_blank" rel="noopener noreferrer">
                <img src={Youtube} alt="Youtube logo" />
            </a>
        </Socials>
    )
}

const Socials = styled.div`
    display: flex;
    grid-gap: 8px;
    padding: 5px 0;
    a {
        background-color: ${(props) => props.theme.colors.lightBlueColor};
        border-radius: 4px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 16px;
        height: 16px;
    }
`