import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import { useModalStore } from 'store/ModalStore';
import { breakpoints } from 'style/breakpoints';
import styled from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import BillsIcon from '../../assets/img/bills_icon.svg';
import PollsTable from '../../components/PollTable';
import { useDashboardStore } from '../../store/DashboardStore';
import getTranslatedBillMonthYearForDate from '../../utils/helpers/monthForDate';
import { lightTheme } from 'style/themes';
import transformDate from 'utils/constants/transformDate';
import { Link } from 'react-router-dom';
import { fontSizes } from '../../style/fontSizes';
import { ReactComponent as ShowAllIcon } from '../../assets/img/show_all_icon.svg';
import EtableIcon from 'assets/img/e-table_icon.svg';
import { actionTypes } from '../../utils/constants/customTable/actions';
import { getBillPdf } from '../../api/bills';
import config from '../../config';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';
import {MD5} from "crypto-js";

const Dashboard = () => {
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const billTableHeaders = [
    {
      name: 'month',
      displayName: 'Mesec',
      hasOrder: true,
    },
    {
      name: 'amount',
      displayName: 'Iznos',
      hasOrder: true,
    },
  ];

  const notificationTableHeaders = [
    {
      name: 'createdAt',
      displayName: 'Datum',
      hasOrder: true,
    },
    {
      name: 'title',
      displayName: 'Naslov',
      hasOrder: true,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);
  const selectedFlatId = useSelectedFlatId();

  const [bills, notifications] = useDashboardStore((state) => [
    state.bills,
    state.notifications,
  ]);

  // TODO: Use this from API.
  const [billList, setBillList] = useState([]);

  const changeBillList = async () => {
    const bills = useDashboardStore.getState().bills; // Accessing the 'bills' state from useDashboardStore
    const resBillList = bills.map((item) => {
      return {
        amount: item.amount.toFixed(2),
        month: getTranslatedBillMonthYearForDate(item.month),
        id: item.id,
      };
    });

    setBillList(resBillList);

    return bills;
  };

  const openBill = (billId) => {
    const hashedSelectedFlatId = MD5(String(selectedFlatId)).toString();
    const hashedBillId = MD5(String(billId)).toString();
    // Call the API function to update the schedule
    getBillPdf(selectedFlatId, billId)
      .then(() => {
        // Handle the API response here (if needed)
        window.open(
          `${config.storageUrl}/pdfBills/${hashedSelectedFlatId}/${hashedBillId}.pdf`,
          '_blank'
        );
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error('API Error:', error);
      });
  };

  const onClickOnAction = (details) => {
    if (details.actionType === actionTypes.dashboardOpenBill) {
      const billId = details.item.id;
      openBill(billId);
    }
  };

  useEffect(() => {
    changeBillList();
  }, [bills]);

  const billsTableData = bills.map((bill) => ({
    amount: bill.amount.toFixed(2),
    month: getTranslatedBillMonthYearForDate(bill.month),
    id: bill.id,
  }));

  const notificationTableData = notifications.map((notification) => ({
    createdAt: transformDate(notification.createdAt),
    title: notification.title,
  }));

  return (
    <Wrapper style={{ backgroundColor: lightTheme.colors.backgroundColor }}>
      <div className={'row'}>
        <div className={'col-md-12 col-lg-6'}>
          <PageHeader>
            <TableHeaderInfo>
              <div>
                <img
                  src={EtableIcon}
                  alt="bills list icon"
                />
              </div>
              <h2>Poslednja obaveštenja</h2>
            </TableHeaderInfo>
          </PageHeader>

          <CustomTable
            headers={notificationTableHeaders}
            items={notificationTableData}
            isStriped
          />
          {notificationTableData.length > 0 && (
            <TableFooter>
              <Link to={'/e-table'}>
                Pregled svih obaveštenja
                <ShowAllIcon />
              </Link>
            </TableFooter>
          )}
        </div>
        <div className={'col-md-12 col-lg-6'}>
          <PageHeader>
            <TableHeaderInfo>
              <div>
                <img
                  src={BillsIcon}
                  alt="bills list icon"
                />
              </div>
              <h2>Moja zaduženja</h2>
            </TableHeaderInfo>
          </PageHeader>

          <CustomTable
            headers={billTableHeaders}
            items={billsTableData}
            isStriped
            actions={[actionTypes.dashboardOpenBill]}
            onClickOnAction={onClickOnAction}
          />
          {billsTableData.length > 0 && (
            <TableFooter>
              <Link to={'/bills'}>
                Pregled svih zaduženja
                <ShowAllIcon />
              </Link>
            </TableFooter>
          )}
        </div>
      </div>
      <PollsTable />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const TableFooter = styled.div`
  display: flex;
  justify-content: end;
  background-color: ${(props) => props.theme.colors.whiteColor};
  border-radius: 0 0 8px 8px;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding: 10px 0;

  a {
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-size: ${fontSizes.md};
    font-weight: 500;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    margin-right: 2rem;

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }
`;

export default Dashboard;
