import { useLocation } from 'react-router-dom';
import { useGlobalStore } from 'store/GlobalStore';
import { breakpoints } from 'style/breakpoints';
import styled from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import { Copyright } from './copyright';
import { Social } from './social';
import { SocialLogin } from './socialLogin';

export const Footer = () => {
  const isExpanded = useGlobalStore((state) => state.isExpanded);
  const { pathname } = useLocation();
  const authPrefix = '/auth';
  const isOnAuth = pathname.includes(authPrefix);
  return (
    <StyledFooter
      isRouteAuth={isOnAuth}
      isSideExpanded={isExpanded}
    >
      {isOnAuth ? <Copyright /> : ''}
      {isOnAuth ? <SocialLogin /> : <Social />}
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isRouteAuth ? 'space-between' : 'end')};
  align-items: center;
  padding: 5px 20px;
  position: fixed;
  bottom: 0;
  background-color: ${(props) =>
    props.isRouteAuth
      ? props.theme.colors.darkBlueColor
      : props.theme.colors.whiteColor};
  z-index: 2;
  width: ${(props) =>
    props.isRouteAuth
      ? '100%'
      : props.isSideExpanded
      ? 'calc(100% - 230px)'
      : 'calc(100% - 90px)'};
  margin-left: ${(props) =>
    props.isRouteAuth
      ? '0'
      : props.isSideExpanded
      ? pxToRem(230)
      : pxToRem(90)};
  transition: margin-left 0.3s ease-in-out;

  @media ${breakpoints.sm} {
    width: ${(props) => (props.isRouteAuth ? '100%' : 'calc(100% - 90px)')};
    margin-left: ${(props) => (props.isRouteAuth ? '0' : pxToRem(90))};
  }
`;
