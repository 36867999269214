import CustomButton from "../Common/CustomButton";
import buttonTypes from "../../utils/constants/buttonTypes";
import {fontSizes} from "../../style/fontSizes";
import {ReactComponent as IncomeIcon} from "../../assets/img/income_button_icon.svg";
import {ReactComponent as InflowIcon} from "../../assets/img/inflow_button_icon.svg";
import {useSearchParams} from "react-router-dom";
import styled from "styled-components";
import {useDashboardStore} from "../../store/DashboardStore";
import {breakpoints} from "../../style/breakpoints";
import {StatisticsBox} from "../Common/StatisticsBox";
import accountBalanceIcon from "../../assets/img/account_balance.svg";

export const FondActions = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const isInflow = !searchParams.get('type') || searchParams.get('type') === 'inflow'
    const [showAccountBalance, accountBalance] = useDashboardStore((state) => [
        state.showAccountBalance,
        state.accountBalance
    ]);

    const roundedBalance = parseFloat(accountBalance).toFixed(2);

    return <FondWrapper>
        {showAccountBalance &&
            <StatisticsBox
                title='Stanje tekućeg računa stambene zajednice'
                info={roundedBalance}
                icon={accountBalanceIcon}
            />
        }

        <CustomButton
            type={isInflow ? buttonTypes.secondary : buttonTypes.white}
            style={{fontSize: fontSizes.md, borderRadius: '8px'}}
            height={'48px'}
            width={'182px'}
            border={'none'}
            onClick={() => {
                setSearchParams({type: 'inflow'})
            }}
        >
        <span style={{position: 'absolute', left: '8px'}}>
            <IncomeIcon fill={isInflow ? buttonTypes.white : buttonTypes.secondary}/>
        </span>
            Priliv
        </CustomButton>

        <CustomButton
            style={{fontSize: fontSizes.md, borderRadius: '8px'}}
            type={isInflow ? buttonTypes.white : buttonTypes.secondary}
            height={'48px'}
            width={'182px'}
            border={'none'}
            onClick={() => {
                setSearchParams({type: 'outflow'})
            }}
        >
            <span style={{position: 'absolute', left: '8px'}}>
                <InflowIcon fill={isInflow ? buttonTypes.secondary : buttonTypes.white}/>
            </span>
                Odliv
        </CustomButton>
    </FondWrapper>
}

const FondWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
  }
`;
