import api from 'api';
import { useEtableStore } from 'store/EtableStore';

export const getEtableApi = async (flatId, page, limit) => {
  const url = '/flats/' + flatId + '/notifications';
  const res = await api.get(url, { params: { page: page, limit: limit } });
  useEtableStore.setState({ eTable: res.data });

  return res.data;
};
