import CustomInput from 'components/Common/CustomInput';
import styled from 'styled-components';
import inputVariants from 'utils/constants/inputVariants';
import MailIcon from 'assets/auth/inputs/MailIcon';
import pxToRem from 'utils/helpers/pxToRem';
import { useEffect, useState } from 'react';
import CustomButton from 'components/Common/CustomButton';
import buttonTypes from 'utils/constants/buttonTypes';
import { postLoginApi } from 'api/auth';
import { postRegisterApi } from 'api/register';
import { useAuthStore } from 'store/AuthStore';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import useEnterKeyPress from 'utils/hooks/useEnterKeyPress';
import {
  sessionExpiredMessage,
  sessionExpiredParam,
} from 'utils/constants/auth';
import { fontSizes } from 'style/fontSizes';
import { breakpoints } from 'style/breakpoints';
import ImageButton from 'assets/img/login_image_button.svg';
import QrCodeIcon from 'assets/auth/inputs/QrCodeIcon';
import { Link } from 'react-router-dom';
import { modalTypeNames } from 'utils/constants/modalTypes';
import { useModalStore } from 'store/ModalStore';
import {
  isTrue,
  isValidEmail,
  maxLength,
  minLength,
  nestValidators,
  validateFormAndReturnErrorsObjectOrNull,
  validateFormInput,
} from 'utils/helpers/validation';
import { CustomCheckbox } from 'components/Common/CustomCheckbox';
import parseServerError from 'utils/helpers/parseServerError';
import decryptToken from "../../utils/helpers/decryptToken";

const Register = () => {
  const toggleModal = useModalStore((state) => state.toggleModal);
  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const hashedToken = searchParams.get('token');
  let tokenData = {};

  if (hashedToken) {
    const cleanedToken = hashedToken.replace(/\s/g, '');
    tokenData = decryptToken(cleanedToken);
  }

  const [formData, setFormData] = useState({
    email: Object.keys(tokenData).length ? tokenData.email : '',
    password: '',
    hash_code: Object.keys(tokenData).length ? tokenData.code : '',
    terms: false,
  });

  const sessionExpired = searchParams.get(sessionExpiredParam);

  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');

  const [saveToken, removeToken, accessToken] = useAuthStore((state) => [
    state.saveToken,
    state.removeToken,
    state.token,
  ]);

  const onInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const inputErrorMessage = validateFormInput(
      FORM_VALIDATORS,
      fieldName,
      fieldValue
    );
    setErrors((prev) => ({ ...prev, [fieldName]: inputErrorMessage }));
    return setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  const onLogin = async () => {
    try {
      const res = await postLoginApi(formData.email, formData.password);
      if (res) {
        saveToken(res.token);
      } else {
        removeToken();
      }
    } catch (err) {
      removeToken();
    }
  };

  const onRegister = async () => {
    const validationErrors = validateFormAndReturnErrorsObjectOrNull(
      formData,
      FORM_VALIDATORS
    );
    validationErrors ? setErrors(validationErrors) : setErrors({});
    if (validationErrors) return;
    try {
      setServerError("")
      const response = await postRegisterApi(
        formData.email,
        formData.password,
        formData.hash_code,
        formData.terms
      );

      if (response) {
        await onLogin();
      }
    } catch (err) {
      setServerError(parseServerError(err))
    }
  };

  useEnterKeyPress(() => {
    onRegister();
  });

  const onTermsClick = () => {
    toggleModal(modalTypeNames.termsModal, {
      modalName: 'Politika Privatnosti',
    });
  };

  useEffect(() => {
    if (accessToken) {
      navigate(routes.dashboard.path, { replace: true });
    }
  }, [accessToken]);

  return (
    <Wrapper>
      <LoginHeader>
        <h2>Registracija</h2>
        <p>
          Već imaš nalog
          <Link to={'/auth/login'}>Uloguj se</Link>
        </p>
      </LoginHeader>

      {sessionExpired && <p>{sessionExpiredMessage}</p>}
      <Form>
        <CustomInput
          label="Email Adresa"
          placeholder="Email Adresa"
          type="text"
          name="email"
          variant={inputVariants.auth}
          Icon={MailIcon}
          iconHeigh={pxToRem(13)}
          iconWidth={pxToRem(16)}
          onChange={onInput}
          value={formData.email}
          height={pxToRem(44)}
          errorMessage={errors.email}
        />

        <CustomInput
          label="Šifra"
          placeholder="Šifra"
          type="password"
          name="password"
          variant={inputVariants.auth}
          iconHeigh={pxToRem(13)}
          iconWidth={pxToRem(17)}
          onChange={onInput}
          value={formData.password}
          height={pxToRem(44)}
          errorMessage={errors.password}
        />

        <CustomInput
          label="Kod sa Računa"
          placeholder="Kod sa Računa"
          type="text"
          name="hash_code"
          variant={inputVariants.auth}
          Icon={QrCodeIcon}
          iconHeigh={pxToRem(13)}
          iconWidth={pxToRem(17)}
          onChange={onInput}
          value={formData.hash_code}
          height={pxToRem(44)}
          errorMessage={errors.hash_code ?? serverError}
        />
      </Form>

      <CustomButton
        height={pxToRem(44)}
        className="mt-4"
        type={buttonTypes.primary}
        onClick={() => onRegister()}
      >
        <Image
          src={ImageButton}
          alt="button arrow image"
        />
        Registruj se
      </CustomButton>

      <LoginFooter>
        <CustomCheckbox
          type="checkbox"
          name="terms"
          errorMessage={errors.terms}
          onChange={onInput}
          value={formData.terms}
        >
          <label>
            Prihvatam uslove
          </label>
          <a
            href="#"
            onClick={() => onTermsClick()}
          >
            Uslovi korišćenja
          </a>
        </CustomCheckbox>
      </LoginFooter>
    </Wrapper>
  );
};

const FORM_VALIDATORS = {
  email: isValidEmail(),
  password: nestValidators(
    minLength(8, 'Lozinka mora imati 8 karaktera'),
    maxLength(16, 'Lozinka ne sme imati više od 16 karaktera')
  ),
  hash_code:nestValidators(
    minLength(5, 'Kod treba da ima 5 karaktera'),
    maxLength(5, 'Kod treba da ima 5 karaktera')
  ),
  terms: isTrue('Morate da prihvatite uslove'),
};
const Wrapper = styled.div``;

const LoginHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;

  h2 {
    font-weight: 800;
    color: ${(props) => props.theme.colors.blackColor};
    text-align: center;
    font-size: 22px;
  }

  p {
    color: ${(props) => props.theme.colors.blackColor};
    font-size: ${fontSizes.sm};
    margin-bottom: 0;

    @media ${breakpoints.sm} {
      text-align: center;
    }
  }

  a {
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-size: ${fontSizes.sm};
    font-weight: bold;
    text-align: end;
    padding-left: 5px;

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }

    @media ${breakpoints.sm} {
      text-align: center;
    }
  }

  img {
    margin-right: 4px;
    margin-bottom: 3px;
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
  }
`;

const Form = styled.div`
  margin-top: ${pxToRem(42)};
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

const Image = styled.img`
  position: absolute;
  left: 20px;
`;

const LoginFooter = styled.div`
  display: flex;
  justify-content: start;
  margin: 1rem 0;

  a {
    font-size: ${fontSizes.sm};
    color: ${(props) => props.theme.colors.lightBlueColor};
    font-weight: 600;

    :hover {
      color: ${(props) => props.theme.colors.darkBlueColor};
    }
  }

  label {
    font-size: ${fontSizes.sm};
    color: ${(props) => props.theme.colors.lightBlackColor};
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
`;

export default Register;
