import styled from 'styled-components';
import { fontSizes } from '../../../../style/fontSizes';

export const TenantInfo = ({ members, email, oldDebt, size }) => {
  return (
    <TenantInfoBox>
        <section>
            <h5>Broj korisnika:</h5>
            <h6>{members}</h6>
        </section>
        <section>
            <h5>Email:</h5>
            <h6 style={{textTransform: 'none'}}>{email}</h6>
        </section>
        <section>
            <h5>Neto površina:</h5>
            <h6>
                {size} m<sup>2</sup>
            </h6>
        </section>
      <section>
        <h5>Stari dug:</h5>
        <h6>{oldDebt} RSD</h6>
      </section>
    </TenantInfoBox>
  );
};

const TenantInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background: ${(props) => props.theme.colors.whiteColor};

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.lightBlackColor};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderInfoBoxColor};

    :last-child {
      border-bottom: none;
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.lightBlackColor};
    text-transform: uppercase;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderInfoBoxColor};

    :last-child {
      border-bottom: none;
    }
  }
  h5 {
    font-size: ${fontSizes.md};
    font-weight: 500;
  }

  h6 {
    font-size: ${fontSizes.md};
    font-weight: 800;
  }
`;
