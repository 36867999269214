import styled from 'styled-components';
import PollsTable from "../../components/PollTable";

const Polls = () => {
    return (
        <Wrapper>
            <PollsTable>
            </PollsTable>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export default Polls;
