import Login from 'pages/Auth/Login';
import HomeIcon from 'assets/side/menu/HomeIcon';
import BillsIcon from 'assets/side/menu/BillsIcon';
import Dashboard from 'pages/Dashboard';
import Bills from 'pages/Bills';
import Polls from 'pages/PollList';
import Fond from 'pages/Fond';
import routeProtectionTypes from 'utils/constants/routeTypes';
import FondIcon from '../assets/table/actions/FondIcon';
import Register from 'pages/Register';
import PollIcon from '../assets/side/menu/PollIcon';
import EtableIcon from '../assets/side/menu/EtableIcon';
import Etable from '../pages/Etable';
import { dashboardBoxes } from 'utils/constants/dashboardBoxes';
import ProblemsIcon from '../assets/side/menu/ProblemsIcon';
import Problems from '../pages/Problems';
import CustomButton from '../components/Common/CustomButton';
import buttonTypes from '../utils/constants/buttonTypes';
import { modalTypeNames } from '../utils/constants/modalTypes';
import { useModalStore } from '../store/ModalStore';
import { fontSizes } from '../style/fontSizes';
import { FondActions } from '../components/FondActions';
import SettingsIcon from '../assets/side/menu/SettingsIcon';
import Settings from '../pages/Settings';
import { FooterSettings } from '../components/Common/FooterSettings';
import ResetPassword from '../pages/ResetPassword';
import NotFound from '../pages/404';
import Profile from '../pages/Profile';
import ProfileIcon from "../assets/side/menu/ProfileIcon";
import {FooterInfoBoxes} from "../components/Common/FooterInfoBoxes";
import Craftsman from "../pages/Craftsman";
import CraftsmanIcon from "assets/side/menu/Craftsman";

// All routes need to have: a "path" and "component" property !
// Also can have a property "name" which are optional.
const routes = {
  dashboard: {
    path: '/',
    component: Dashboard,
    name: 'Početna',
    headline: '',
    dashboardBoxes: [
      dashboardBoxes.personalInfo,
      dashboardBoxes.debt,
      dashboardBoxes.statisticsBox,
    ],
    icon: (props) => <HomeIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
    // Can have same keys as main route (path , component, name, icon and so on)
    // subRoute: {},
  },
  myProfile: {
    path: '/profile',
    component: Profile,
    headline: '',
    name: 'Moj Profil',
    dashboardBoxes: [
      dashboardBoxes.personalInfo,
      dashboardBoxes.debt,
      dashboardBoxes.infoBox,
    ],
    icon: (props) => <ProfileIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
    FooterInfo: <FooterInfoBoxes />,
  },
  eTable: {
    path: '/e-table',
    component: Etable,
    name: 'E-Oglasna Tabla',
    dashboardBoxes: [
      dashboardBoxes.personalInfo,
      dashboardBoxes.debt,
      dashboardBoxes.statisticsBox,
    ],
    icon: (props) => <EtableIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
  },
  poll: {
    path: '/polls',
    component: Polls,
    name: 'E-Glasanje',
    dashboardBoxes: [
      dashboardBoxes.personalInfo,
      dashboardBoxes.debt,
      dashboardBoxes.statisticsBox,
    ],
    icon: (props) => <PollIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
  },
  problems: {
    path: '/problems',
    component: Problems,
    name: 'Prijava Problema',
    icon: (props) => <ProblemsIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
    Actions: (
      <CustomButton
        type={buttonTypes.primary}
        style={{ fontSize: fontSizes.md, borderRadius: '8px' }}
        height={'46px'}
        width={'332px'}
        border={'none'}
        onClick={() => {
          const toggleModal = useModalStore.getState().toggleModal;
          toggleModal(modalTypeNames.problemsModal, {
            modalName: 'Forma za prijavu problema',
          });
        }}
      >
        Prijavi Problem
      </CustomButton>
    ),
  },
  bills: {
    path: '/bills',
    component: Bills,
    name: 'Računi',
    headline: 'Moja Zaduženja',
    icon: (props) => <BillsIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
  },
  fond: {
    component: Fond,
    name: 'Prilivi / Odlivi',
    Actions: <FondActions />,
    notInMainMenu: false,
    path: '/fond',
    icon: (props) => <FondIcon {...props} />,
    routeProtection: routeProtectionTypes.fondRoute,
  },
  craftsman: {
    component: Craftsman,
    name: 'Majstori',
    notInMainMenu: false,
    path: '/craftsman',
    icon: (props) => <CraftsmanIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
  },
  settings: {
    path: '/settings',
    component: Settings,
    name: 'Podešavanja',
    icon: (props) => <SettingsIcon {...props} />,
    routeProtection: routeProtectionTypes.protectedRoute,
    FooterActions: <FooterSettings />,
  },

  // Auth Pages
  logIn: {
    path: '/auth/login',
    component: Login,
    name: 'LogIn',
    notInMainMenu: true,
  },

  register: {
    path: '/auth/register',
    component: Register,
    name: 'Register',
    notInMainMenu: true,
  },

  resetPassword: {
    path: '/auth/reset-password',
    component: ResetPassword,
    name: 'ResetPassword',
    notInMainMenu: true,
  },

  notFound: {
    headline: '',
    path: '*',
    component: NotFound,
    name: 'NotFound',
    notInMainMenu: true,
  },
};

export default routes;

// Find specific route by pathname - can using when we have subitems
export const findRoute = (pathname) => {
  let route = null;
  route = getRoutesAsArray().find((route) => route.path === pathname);

  return route || null;
};

// Transform routes object to an array for mapping ans similar function using on array
export const getRoutesAsArray = () => {
  const routesArr = [];

  Object.keys(routes).map((key) => {
    const route = routes[key];
    routesArr.push(route);

    if (route.subRoute) {
      Object.keys(route.subRoute).map((subKey) => {
        routesArr.push(route.subRoute[subKey]);
      });
    }
  });

  return routesArr;
};
