import { authKeys } from 'utils/constants/localStorageKeys';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const uniqueAuthStoreName = 'auth';

const createInitState = () => {
  const state = {};
  Object.keys(authKeys).map((key) => {
    state[key] = null;
  });

  return state;
};

export const useAuthStore = create(
  persist(
    (set) => ({
      ...createInitState(),
      saveToken: (token) =>
        set((state) => ({ ...state, [authKeys.token]: token })),
      removeToken: () => set((state) => ({ ...state, [authKeys.token]: null })),
    }),

    {
      name: uniqueAuthStoreName,
    }
  )
);
