import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useModalStore } from 'store/ModalStore';
import { ModalTypes } from 'utils/constants/modalTypes';
import { breakpoints } from '../../../style/breakpoints';

const CustomModal = () => {
  const isModalOpened = useModalStore((state) => state.isOpen);
  const [toggleModal, size] = useModalStore((state) => [
    state.toggleModal,
    state.size,
  ]);
  const type = useModalStore((state) => state.type);

  const modalProps = useModalStore((state) => state.modalProps);

  const SelectedModal = ModalTypes[type];

  return (
    <Wrapper
      isOpen={isModalOpened}
      toggle={() => toggleModal()}
      centered={true}
      size={size}
      // fade={false}
    >
      {/* If type exist - call modal type with props passed by store*/}
      {type && <SelectedModal {...modalProps} />}
    </Wrapper>
  );
};

const Wrapper = styled(Modal)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 80%;
    margin: auto;
  }
`;

CustomModal.propTypes = {
  isActive: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default CustomModal;
