export const sizes = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
};

export const breakpoints = {
  xs: `(max-width: ${sizes.xs}px)`,
  sm: `(max-width: ${sizes.sm}px)`,
  md: `(max-width: ${sizes.md}px)`,
  lg: `(max-width: ${sizes.lg}px)`,
  xl: `(max-width: ${sizes.xl}px)`,
};
