import PropTypes from 'prop-types';
import CustomButton from 'components/Common/CustomButton';
import { ModalFooter } from 'reactstrap';
import { useModalStore } from 'store/ModalStore';
import buttonTypes from 'utils/constants/buttonTypes';
import { useEffect, useState } from 'react';
import transformDate from 'utils/constants/transformDate';
import { postVoteApi } from '../../api/polls';
import pxToRem from '../../utils/helpers/pxToRem';
import styled from 'styled-components';
import { fontSizes } from '../../style/fontSizes';
import { breakpoints } from '../../style/breakpoints';
import TickMark from '../../assets/img/tick_mark_icon.svg';
import VoteImage from '../../assets/img/vote_icon.svg';
import { lightTheme } from '../../style/themes';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

const PollDetailsModal = ({ poll }) => {
  const selectedFlatId = useSelectedFlatId();
  const toggleModal = useModalStore((state) => state.toggleModal);
  const [status, setStatus] = useState('getting status...');
  const [flatVoted, setFlatVoted] = useState(false);
  const [votedFor, setVotedFor] = useState(
    poll.flatsVotedFor.includes(selectedFlatId)
  );
  const [countYesVotes, setCountYesVotes] = useState(poll.flatsVotedFor.length);
  const [countNoVotes, setCountNoVotes] = useState(
    poll.flatsVotedAgainst.length
  );

  let voteInfo = 'Aktivna';
  if (poll.ended) {
    voteInfo = 'Neaktivna';
  }

  const onVote = async (flatId, value, option) => {
    try {
      const res = await postVoteApi(flatId, poll.id, value);

      if (option) {
        poll.flatsVotedFor.push(selectedFlatId);
        const newValue = countYesVotes + 1;
        setCountYesVotes(newValue);
        setVotedFor(true);
      } else {
        poll.flatsVotedAgainst.push(selectedFlatId);
        const newValue = countNoVotes + 1;
        setCountNoVotes(newValue);
        setVotedFor(false);
      }

      setFlatVoted(true);
    } catch (e) {
      //error
    }
  };

  useEffect(() => {
    setFlatVoted(
      poll.flatsVotedFor.includes(selectedFlatId) ||
        poll.flatsVotedAgainst.includes(selectedFlatId)
    );
  }, [flatVoted, countNoVotes, countYesVotes, selectedFlatId]);

  const modalProps = useModalStore((state) => state.modalProps);
  const modalName = modalProps?.modalName;

  return (
    <Wrapper>
      <ModalHeader>
        <div className="modal-title">
          <div
            className="header-text"
            title={modalName}
          >
            {modalName}
          </div>
          <div className="header-info">{voteInfo}</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="polls-date">
          <div className="start-date w-50 mr-3">
            <div className="poll-text mb-2">Početak Glasanja</div>
            <div className="poll-date">{transformDate(poll.createdAt)}</div>
          </div>

          <div className="end-date w-50 ml-3">
            <div className="poll-text mb-2">Kraj Glasanja</div>
            <div className="poll-date">{transformDate(poll.endsAt)}</div>
          </div>
        </div>

        <div className="poll-text mt-2">Tema Glasanja</div>
        <div className="poll-description">{poll.description}</div>

        {flatVoted ? (
          <div className="wrapper-success">
            <img
              className="tick-mark"
              src={TickMark}
              alt="tick mark image"
            />
            <img
              className="vote-icon"
              src={VoteImage}
              alt="vote image"
            />
            <div className="text-wrapper">
              <h4>Hvala</h4>
              <h5>
                Uspešno ste glasali{' '}
                {votedFor ? (
                  <span
                    style={{
                      color: lightTheme.colors.lightBlueColor,
                      fontWeight: '700',
                    }}
                  >
                    ZA
                  </span>
                ) : (
                  <span
                    style={{
                      color: lightTheme.colors.dangerColor,
                      fontWeight: '700',
                    }}
                  >
                    NE
                  </span>
                )}
              </h5>
            </div>
          </div>
        ) : (
          ''
        )}
      </ModalBody>
      <p>Trenutni Rezultat Glasanja</p>
      <ModalFooter>
        <CustomButton
          onClick={() =>
            onVote(selectedFlatId, { value: poll.pollOptions[1].id }, true)
          }
          type={buttonTypes.primary}
          disabled={poll.ended || flatVoted}
        >
          <BtnContent>
            <span className="optionText">
              {poll.pollOptions[1]?.description}
            </span>
            <span
              style={{ paddingLeft: '16px', borderLeft: '1px solid white' }}
            >
              {countYesVotes}
            </span>
          </BtnContent>
        </CustomButton>

        <CustomButton
          font-size={fontSizes.sm}
          onClick={() =>
            onVote(selectedFlatId, { value: poll.pollOptions[0].id }, false)
          }
          type={buttonTypes.warning}
          disabled={poll.ended || flatVoted}
        >
          <BtnContent>
            <span className="optionText">
              {poll.pollOptions[0]?.description}
            </span>
            <span
              style={{ paddingLeft: '16px', borderLeft: '1px solid white' }}
            >
              {countNoVotes}
            </span>
          </BtnContent>
        </CustomButton>
      </ModalFooter>
    </Wrapper>
  );
};

PollDetailsModal.propTypes = {
  poll: PropTypes.any,
};

const Wrapper = styled.div`
  .modal-body {
    font-size: ${fontSizes.md};
    flex-direction: column;
    display: flex;
    gap: 12px;
    font-weight: 500;
  }
  .optionText {
    text-transform: uppercase;
  }

  .modal-footer {
    border-top: none;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3.4rem 2rem 3.4rem;

    @media ${breakpoints.sm} {
      justify-content: center;
    }

    button {
      height: ${pxToRem(36)};
      width: ${pxToRem(178)};
      font-size: ${fontSizes.md};
    }
  }

  p {
    font-size: ${fontSizes.md};
    color: ${(props) => props.theme.colors.lightBlackColor};
    font-weight: 600;
    margin: 2rem 0 0 3.6rem;

    @media ${breakpoints.sm} {
      margin: 2rem 0 0 0;
      text-align: center;
    }
  }
`;

const ModalHeader = styled.div`
  border-bottom: 0;
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 3.6rem;

    .header-text {
      border-bottom: none;
      position: relative;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: ${pxToRem(24)};
      color: ${(props) => props.theme.colors.whiteColor};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 70%;
    }

    .header-info {
      color: ${(props) => props.theme.colors.whiteColor};
      background-color: ${(props) => props.theme.colors.lightBlueColor};
      font-size: ${fontSizes.md};
      width: 88px;
      font-weight: 700;
      border-radius: 2rem;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 2px;
    }
  }
`;

const ModalBody = styled.div`
  font-size: 0.875rem;
  flex-direction: column;
  display: flex;
  gap: 12px;
  font-weight: 500;
  padding: 1.2rem 3.6rem;

  .polls-date {
    display: flex;
    justify-content: space-between;
    grid-gap: 1.2rem;
  }

  .poll-text {
    font-size: ${fontSizes.md};
    color: ${(props) => props.theme.colors.lightBlackColor};
    font-weight: bold;
  }

  .poll-date {
    font-size: 16px;
    color: ${(props) => props.theme.colors.lightBlackColor};
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    padding: 1rem;
  }

  .poll-description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.lightBlackColor};
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    padding: 1rem;
    max-height: 7rem;
    overflow-y: auto;
  }

  .wrapper-success {
    display: flex;
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    padding: 0.5rem;
    width: 100%;
    position: relative;

    .tick-mark {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .vote-icon {
      margin-left: 1rem;
      margin-right: auto;
      padding-right: 2.5rem;
      border-right: 2px solid ${(props) => props.theme.colors.whiteColor};
    }

    .text-wrapper {
      margin-left: 0.5rem;
      padding: 0.5rem;
      width: 70%;
      text-align: center;
      background-color: ${(props) => props.theme.colors.whiteColor};

      h4 {
        font-size: 21px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.lightBlueColor};
      }

      h5 {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.darkBlueColor};
      }
    }

    p {
      margin: 0;
    }
  }
`;

const BtnContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0 8px;
`;

export default PollDetailsModal;
