const SvgComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.456535 0.376914C0.595424 0.265803 0.748201 0.206776 0.914868 0.199831C1.08154 0.192887 1.22737 0.251914 1.35237 0.376914L5.01904 4.04358L8.70654 0.356081C8.81765 0.24497 8.96695 0.192887 9.15445 0.199831C9.34195 0.206776 9.49126 0.265804 9.60237 0.376915C9.74126 0.515804 9.80723 0.665109 9.80029 0.824831C9.79334 0.984554 9.72737 1.12691 9.60237 1.25191L5.45654 5.39775C5.38709 5.46719 5.31765 5.5158 5.2482 5.54358C5.17876 5.57136 5.10237 5.58525 5.01904 5.58525C4.9357 5.58525 4.85931 5.57136 4.78987 5.54358C4.72042 5.5158 4.65098 5.46719 4.58154 5.39775L0.456535 1.27275C0.331535 1.14775 0.272508 1.00191 0.279451 0.835248C0.286396 0.668581 0.345424 0.515803 0.456535 0.376914Z"
      fill={props?.fill ? props.fill : 'black'}
    />
  </svg>
);
export default SvgComponent;
