const SvgComponent = (props) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.4752 5.74379L0.932066 2.20066C0.671679 1.94028 0.611232 1.63804 0.750725 1.29396C0.890218 0.949874 1.14596 0.777832 1.51794 0.777832L8.6042 0.777832C8.97618 0.777832 9.23192 0.949874 9.37141 1.29396C9.5109 1.63804 9.45046 1.94028 9.19007 2.20066L5.64694 5.74379C5.55394 5.83679 5.46095 5.90189 5.36795 5.93909C5.27496 5.97628 5.17266 5.99488 5.06107 5.99488C4.94947 5.99488 4.84718 5.97628 4.75418 5.93909C4.66119 5.90189 4.56819 5.83679 4.4752 5.74379Z"
      fill={props?.fill ? props.fill : 'black'}
    />
  </svg>
);

export default SvgComponent;
