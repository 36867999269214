import {CircularProgressbar} from "react-circular-progressbar";
import DebtGraphBackground from "../../../../assets/img/debtGraphBackground.svg";
import styled from "styled-components";
import {breakpoints} from "../../../../style/breakpoints";
import {fontSizes} from "../../../../style/fontSizes";
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from "prop-types";

export const TenantDebtGraph = ({percentage, text = 'Ukupno plaćenih računa'}) => {

    return (
        <DebtGraph>
            <header>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    circleRatio={0.75}
                    strokeWidth="16"
                    styles={{
                        trail: {
                            strokeLinecap: 'round',
                            transform: 'rotate(-135deg)',
                            transformOrigin: 'center center',
                            stroke: '#E2E8EF',
                            strokeWidth: '12',
                        },
                        path: {
                            strokeLinecap: 'round',
                            transform: 'rotate(-135deg)',
                            transformOrigin: 'center center',
                            stroke: '#4A8CEA',
                            strokeWidth: '12',
                        },
                        text: {
                            fill: '#0B183E',
                            fontSize: '28px',
                            fontWeight: '700',
                        },
                    }}
                />
            </header>
            <section>
                <img src={DebtGraphBackground} alt="debt graph background image"/>
            </section>
            <aside>
                <h2>
                    {text}
                </h2>
            </aside>
        </DebtGraph>
    )
}

TenantDebtGraph.propTypes = {
    percentage: PropTypes.number,
    text: PropTypes.string,
}

const DebtGraph = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.whiteColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;

  img {
    display: flex;
    margin: 1rem auto 0 auto;
    
    @media ${breakpoints.xs} {
      height: 130px;
      padding-top: 10px;
    }
  }

  aside {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    border-top: 1px solid ${(props) => props.theme.colors.darkBlueColor};

    h2 {
      color: ${(props) => props.theme.colors.lightBlackColor};
      font-size: ${fontSizes.lg};
      font-weight: 600;
      align-self: center;
      margin: 0 1rem 0 0;
    }
  }
  
  header {
    width: 132px;
    height: 132px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: ${(props) => props.theme.colors.whiteColor};
  }
`;
