export const lightTheme = {
  colors: {
    primary: '#C72A1C',
    secondary: '#091D56',
    disabled: '#C7C7C9',
    warning: '#c72a1c',
    textPrimary: '#1F2129',
    textSecondary: '#FFFFFF',
    textWeak: '#C7C7C9',
    uploadFileBg: '#c7c7c9',

    // nase boje
    darkBlueColor: '#091D56',
    lightBlueColor: '#4A8CEA',
    blackColor: '#000000',
    whiteColor: '#FFFFFF',
    inputColor: '#C1CBD7',
    inputFocusColor: '#F6FBFF',
    inputShadowColor: '#F2F2F2',
    lightBlackColor: '#252525',
    activeLinkColor: '#0B183E',
    backgroundColor: '#F5F5F5',
    dangerColor: '#EB2E5B',
    borderInfoBoxColor: '#F1F4F7',
    dropdownHoverColor: '#FAFBFC',
    dropdownBorderColor: '#F9F9F9',
    darkGray: '#7D8288',

    borderColor: '',
    toggleBackground: '',
    icons: {
      primary: '#F2F2F2',
      secondary: '#1F2129',
    },

    sidebar: {
      background: '#091D56',
      items: {
        active: '#1F2129',
        noActive: '#F2F2F2',
        activeBg: '#F2F2F2',
        noActiveBg: '#1F2129 ',
      },
    },

    input: {
      border: '#c7c7c9',
      background: '#f2f2f2',
      boxShadow: 'rgba(31, 33, 41, 0.1)',
      disabled: '#555555',
    },

    mainMenuButton: {},
    authLayout: {},

    topBar: {},

    sideMenuColors: {},

    notifications: {},

    buttons: {
      disabled: {},

      standard: {},

      outlineStandard: {
        background: '#FFFFFF',
        borderColor: '#FFFFFF',
      },

      primary: {},

      secondary: {},

      outlineSecondary: {},

      warning: {},
    },

    navTabs: {},
    hr: {},

    scrollbar: {
      track: '',
      thumb: '',
      bg: '',
    },

    table: {
      borderTh: '#ffffff',
      borderTd: '#F2F2F2',
      border: '#F2F2F2',
      bodyBackground: '#ffffff',
      bodyColor: '#757575',
      mainCell: '#1F1F1F;',
      headers: {
        color: '#1F2129',
        background: '#F2F2F2',
      },
      row: {
        hoveredBg: 'rgba(242, 242, 242, 0.5);',
      },
      activeRow: {
        boxShadow: 'rgba(14, 18, 62, 0.08)',
      },
      arrowIcons: 'black',
      striped: '#E2E8EF',
    },
    status: {
      active: '#4BD181',
      inactive: '#F26668',
      pending: '#f39c12',
    },

    alerts: {},
  },

  fonts: {
    fontBody: 'Lato',
  },
};
