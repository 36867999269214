import styled from 'styled-components';
import pxToRem from '../../../utils/helpers/pxToRem';
import { breakpoints } from '../../../style/breakpoints';
import { StatisticsBox } from '../StatisticsBox';
import accountBalanceIcon from '../../../assets/img/account_balance.svg';
import tenantsIcon from '../../../assets/img/tenants.svg';
import problemsIcon from '../../../assets/img/problems_profile.svg';
import notificationIcon from '../../../assets/img/notification.svg';
import { useDashboardStore } from '../../../store/DashboardStore';
import { useProblemsStore } from '../../../store/ProblemsStore';
import { getEtableApi } from '../../../api/eTable';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

export const FooterInfoBoxes = () => {
  const [building, showAccountBalance, accountBalance] = useDashboardStore(
    (state) => [state.building, state.showAccountBalance, state.accountBalance]
  );
  const [notificationCount, setNotificationCount] = useState();
  const roundedBalance = parseFloat(accountBalance).toFixed(2);
  const problems = useProblemsStore.getState().problems;
  const selectedFlatId = useSelectedFlatId();
  const getNotificationCount = async (flatId) => {
    const res = await getEtableApi(flatId);
    setNotificationCount(res?.count);
  };

  useEffect(() => {
    getNotificationCount(selectedFlatId);
  }, [selectedFlatId]);

  return (
    <Wrapper>
      {showAccountBalance ? (
        <AccountBalanceStatistics
          title="Stanje tekućeg računa stambene zajednice"
          info={roundedBalance}
          icon={accountBalanceIcon}
        />
      ) : (
        <Link to={'/e-table'}>
          <AccountBalanceStatistics
            title="Broj obaveštenja u stambenoj zajednici"
            info={notificationCount}
            icon={notificationIcon}
          />
        </Link>
      )}
      <AccountBalanceStatistics
        title="Ukupan broj stanara u stambenoj zajednici"
        info={building?.population}
        icon={tenantsIcon}
      />
      <Link to={'/problems'}>
        <AccountBalanceStatistics
          title="Broj prijavljenih problema u stambenoj zajednici"
          info={problems?.count || 0}
          icon={problemsIcon}
        />
      </Link>
    </Wrapper>
  );
};

const AccountBalanceStatistics = styled(StatisticsBox)({
  paddingTop: '12px',
  paddingBottom: '12px',
  height: '83px',
  minWidth: '362px',
  fontSize: '16px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0 5px 15px',
  border: '1px solid white',
  transition: 'border 0.3s ease-in-out',
  '&:hover': {
    border: '1px solid #4A8CEA',
  },
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: ${pxToRem(12)};
  margin-bottom: ${pxToRem(52)};
  background: ${(props) => props.theme.colors.whiteColor};
  padding: 15px 0;
  border-radius: 8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
    margin-bottom: ${pxToRem(52)};
  }
`;
