const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const parseNumber = strVal => {
    const number = Number.parseInt(strVal, 10)
    return isNaN(number) ? null : number
}


const valueObjHasLength = value => value ? typeof value.length === 'number' : false


export const min = (minNumber, message) => value => {
    const numericValue = parseNumber(value);
    return numericValue !== null && numericValue >= minNumber ? null : message ?? `Unet broj mora biti veći ili jednak ${minNumber}`;
};

export const max = (minNumber, message) => value => {
    const numericValue = parseNumber(value);
    return numericValue !== null && numericValue <= minNumber ? null : message ?? `Uneta vrednost mora biti manji ili jednak ${minNumber}`;
};

export const minLength = (minLength, message) => value => valueObjHasLength(value) && value.length >= minLength ? null : message ?? `Minimalna dužina mora biti ${minLength}`;

export const maxLength = (maxLength, message) => value => valueObjHasLength(value) && value.length <= maxLength ? null : message ?? `Maksimalna dozvoljena dužina je ${maxLength}`;


export const isTrue = (message) => value => value === true ? null : message

export const isNotNull = (message) => value => value !== null ? null : message ?? 'Polje je obavezno'

export const isNotEmpty = (message) => value => value.length > 0 ? '' : message ?? 'Polje je obavezno'

export const isValidEmail = message => value => value && EMAIL_REGEX.test(value) ? null : message ?? 'Uneta adresa nije u validnom formatu'

export const nestValidators = (...validators) => value => {
    let errorMessage = null
    validators.every((validator) => {
        errorMessage = validator(value)
        return !errorMessage
    })
    return errorMessage
}

export const validateFormAndReturnErrorsObjectOrNull = (formValues, validationObject) => {
    const objectPropertiesForValidation = Object.keys(validationObject)
    let hasError = false
    const errorsObj = objectPropertiesForValidation.reduce((acc, curr) => {

        if (!validationObject[curr]) {
            return acc
        }
        const errorMessage = validationObject[curr](formValues?.[curr])
        if (errorMessage) {
            acc[curr] = errorMessage
            hasError = true
        }
        return acc
    }, {})
    return hasError ? errorsObj : null
}

export const validateFormInput = (validationObject, inputName, inputValue) => {
    const validationErrorMessage = validationObject?.[inputName] ? validationObject[inputName](inputValue) : null
    return validationErrorMessage
}



