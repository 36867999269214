import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { baseFontSize, fontSizes } from './fontSizes';

const GlobalStyle = createGlobalStyle`
  :root
  {
    font-size: ${baseFontSize};
  }

  .test
  {
    border: 2px solid red;
    background-color: #ff000831;
  }

  .icon-align
  {
    *
    {
      margin: auto;
      margin-top: -3px;
    }
  }

  button
  {
    user-select: none;
  }

  ::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  /* Vertical track */
  ::-webkit-scrollbar-track {
    background: #F2F2F2; /* Background color of the track */
  }

  ::-webkit-scrollbar-thumb {
    background: #091D56; /* Color of the scrollbar handle */
  }
  
  body
  {
    margin: 0;
    padding: 0;
    *
    {
      box-sizing: border-box;
      font-family: ${(props) => props.theme.fonts.fontBody};
    }

    a
    {
      text-decoration: none;
    }
  }

  // Change opacity on hover
  .selectable {
    cursor: pointer;
    :hover
    {
      opacity: 0.7;
    }
  }
  
  // Typography
  .text-xs
  {
    font-size: ${fontSizes.xs}; // About 10px
  }

  .text-sm
  {
    font-size: ${fontSizes.sm}; // About 12px
  }


  .text-md
  {
    font-size: ${fontSizes.md}; // About 14px
  }

  .text-lg
  {
    font-size: ${fontSizes.lg}; // About 18px
  }
  
  h3 {
    font-size: 24px;
    color: ${(props) => props.theme.colors.darkBlueColor};
    font-weight: 800;
  }

  .modal-backdrop.show {
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    opacity: 0.9;
  }
`;

export default GlobalStyle;
