import api from 'api';
const getPrefix = (flatId) => '/flats/' + flatId + '/bills';
export const getAllBillsApi = async (flatId, page, limit) => {
  const url = getPrefix(flatId);
  const res = await api.get(`${url}?page=${page}&limit=${limit}`);
  return res.data;
};

export const getBillPdf = async (flatId, billId) => {
  const url = getPrefix(flatId);
  const res = await api.get(`${url}` + '/' + billId);
  return res.data;
};
