import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';
import { useState } from 'react';
import pxToRem from 'utils/helpers/pxToRem';
import { fontSizes } from 'style/fontSizes';
import UploadIcon from 'assets/common/UploadIcon';
import inputVariants from 'utils/constants/inputVariants';
import PasswordEye from 'assets/auth/inputs/PasswordEye';
import PasswordShowEye from 'assets/auth/inputs/PasswordShowEye';
import ErrorMessage from '../ErrorMessage';
import routes from "../../../routes";

const CustomInput = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  width,
  height,
  border,
  label,
  errorMessage,
  Icon,
  className,
  disabled,
  multiple,
  accept,
  variant,
}) => {

  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const isResetPassword = location.pathname === routes.resetPassword.path;
  const { pathname } = location;
  const authPrefix = '/auth';
  const isOnAuth = pathname.includes(authPrefix);
  const [fileNames, setFileNames] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChangeFile = (e) => {
    const files = e.target?.files;
    const filenames = Object.keys(files).map((key) => files[key].name);
    setFileNames(filenames.toString());
    if (typeof onChange === 'function') {
      onChange(files);
    }
  };

  const [showLabel, setShowLabel] = useState(false);

  const InputComponent = type === 'textarea' ? TextAreaInput : CInput;

  return (
    <Wrapper
      width={width}
      height={height}
    >
      {/* Label */}
      <Label>{label}</Label>

      <InputPlace>
        {/* Icon */}
        {type === 'password' && (
          <ImageWrapper
            onClick={togglePasswordVisibility}
            style={{ cursor: type === 'password' ? 'pointer' : 'auto' }}
          >
            {!showPassword ? <PasswordEye /> : <PasswordShowEye />}
          </ImageWrapper>
        )}

        {type === 'text' && isOnAuth && !isResetPassword && (
          <ImageWrapper
            onClick={togglePasswordVisibility}
            style={{ cursor: type === 'password' ? 'pointer' : 'auto' }}
          >
            <Icon />
          </ImageWrapper>
        )}

        {/* Input type is not file  */}
        {type !== 'file' && (
          <InputComponent
            autoComplete="off"
            errorMessage={errorMessage}
            disabled={disabled}
            hasValue={!!value}
            className={`${className}   ${variant} `}
            type={showPassword ? 'text' : type}
            name={name}
            value={value}
            border={border}
            width={width}
            height={height}
            placeholder={!showLabel ? placeholder : ''}
            onChange={onChange}
            onFocus={() => setShowLabel(true)}
            onBlur={() => setShowLabel(false)}
          ></InputComponent>
        )}
      </InputPlace>

      {/* Input type - file  */}
      {type === 'file' && (
        <UploadFileContainer
          height={height}
          className={`input ${className}`}
          border={border}
          width={width}
          hasValue={fileNames || value}
        >
          <input
            name={name}
            type="file"
            disabled={disabled}
            id="file-input"
            onChange={(e) => onChangeFile(e)}
            multiple={multiple}
            accept={accept}
          />
          {fileNames || placeholder}
          <label id="upload-button">
            Dodaj Sliku
            <UploadIcon
              fill={
                !fileNames || disabled
                  ? theme.colors.lightBlueColor
                  : theme.colors.icons.secondary
              }
            />
          </label>
        </UploadFileContainer>
      )}

      {/* Error message */}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

CustomInput.propTypes = {
  border: PropTypes.string,
  errorMessage: PropTypes.string,
  height: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  Icon: PropTypes.func, // Actually Is JSX function Icon
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconWidth: PropTypes.string,
  iconHeigh: PropTypes.string,
  multiple: PropTypes.bool, // Used only when type = file - if user can upload multiple files
  accept: PropTypes.string, // Used only when type = file - Accept file types for upload
  variant: PropTypes.string,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputPlace = styled.div`
  position: relative;
`;

const UploadFileContainer = styled.div`
  background: ${(props) =>
    props.hasValue ? 'transparent' : props.theme.colors.whiteColor};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  grid-gap: 8px;
  color: ${(props) => props.theme.colors.lightBlackColor};
  align-items: center;
  border-radius: 6px;
  padding: ${pxToRem(10)} ${pxToRem(13)};
  padding-left: ${pxToRem(18)};
  padding-right: ${pxToRem(9)};
  font-weight: 600;
  opacity: 0.75;

  width: ${(props) => props.width || '100%'};
  border: 2px solid;
  border-color: ${(props) => props.theme.colors.inputColor};

  :hover {
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    border-color: ${(props) => props.theme.colors.lightBlueColor};
  }

  #file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    height: 100%;
    cursor: pointer;
  }

  #upload-button {
    background: ${(props) =>
      props.hasValue ? 'transparent' : props.theme.colors.whiteColor};
    box-shadow: ${(props) =>
      props.hasValue
        ? `1px 1px 3px 1px  ${props.theme.colors.input.boxShadow}`
        : '0 4px 16px rgba(0,0,0,.07)'};
    color: ${(props) =>
      props.hasValue
        ? props.theme.colors.textPrimary
        : props.theme.colors.lightBlackColor};
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin: 0 auto;
    width: ${pxToRem(118)};
    height: ${pxToRem(32)};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${pxToRem(12)};
    font-weight: 600;
  }
`;

const Label = styled.label`
  padding-bottom: 6px;
  font-size: ${fontSizes.sm};
  color: ${(props) => props.theme.colors.lightBlackColor};
  transition: transform 0.11s ease-in-out;
`;

const inputStyles = css`
  border: 2px solid;
  border-color: ${(props) => props.theme.colors.inputColor};
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: relative;
  font-size: ${fontSizes.md};

  font-weight: 500;
  color: ${(props) => props.theme.colors.blackColor};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};

  padding: ${pxToRem(18)} ${pxToRem(13)};
  padding-left: ${pxToRem(18)};
  letter-spacing: 0.3px;

  padding-right: 2.4rem;

  box-shadow: ${(props) =>
    props.errorMessage
      ? `0 0 3px 1px ${props.theme.colors.warning} !important`
      : ''};
  border: ${(props) => (props.errorMessage ? 'none !important' : '')};

  ::placeholder {
    color: ${(props) => props.theme.colors.textWeak};
  }

  :hover {
    border: 1px solid ${(props) => props.theme.colors.lightBlueColor};
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    box-shadow: ${(props) =>
      `0px 3px 4px ${props.theme.colors.input.boxShadow}`};
    opacity: 1;
  }

  :disabled {
    background-color: ${(props) =>
      `${props.theme.colors.input.background} !important`};

    color: ${(props) => props.theme.colors.input.disabled} !important;
  }

  :focus {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.lightBlueColor};
    outline: none;
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    box-shadow: ${(props) =>
      `0px 3px 4px ${props.theme.colors.input.boxShadow}`};
    opacity: 1;
  }

  &.${inputVariants.auth} {
    background: ${(props) =>
      props.hasValue ? 'transparent' : props.theme.colors.input.background};

    :focus {
      border: 1px solid;
      border-color: ${(props) => props.theme.colors.secondary};
      outline: none;
      background-color: transparent;
      box-shadow: ${(props) =>
        `0px 3px 4px ${props.theme.colors.input.boxShadow}`};
    }
  }

  &.${inputVariants.standard} {
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.secondary};
    box-shadow: none;
  }
`;
const CInput = styled.input`
  ${inputStyles}
`;

const TextAreaInput = styled.textarea`
  ${inputStyles};
  resize: none;
`;

const ImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: ${pxToRem(16)};
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  * {
    height: 100%;
    overflow: hidden;
  }
`;

export default CustomInput;
