import config from "../../config";

const decryptToken = (token) => {
    const CryptoJS = require("crypto-js");

    const key = config.encryptionKey;
    const ivHex = config.encryptionIvHex;

    const ciphertext = CryptoJS.enc.Base64.parse(token);
    const encryptionKey = CryptoJS.enc.Utf8.parse(key);

    const iv = CryptoJS.enc.Hex.parse(ivHex);

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext },
        encryptionKey,
        { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

    if (plaintext) {
        return JSON.parse(plaintext);
    }

    return {};
}

export default decryptToken;
