import api from 'api';

const changePasswordUrl = '/profile/change-password';
const forgotPasswordUrl = '/auth/forgot';
const resetPasswordUrl = '/auth/reset';

export const postChangePassword = async (obj) => {
    const res = await api.post(`${changePasswordUrl}`, obj);
    return res.data;
};

export const postForgotPassword = async (obj) => {
    const res = await api.post(`${forgotPasswordUrl}`, obj);
    return res.data;
};

export const postResetPassword = async (obj) => {
    const res = await api.post(`${resetPasswordUrl}`, obj);
    return res.data;
};
