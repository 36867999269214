import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useModalStore } from 'store/ModalStore';
import styled from 'styled-components';
import { postProblemApi } from '../../api/problems';
import ProblemImageButton from '../../assets/img/send_problem_icon.svg';
import TickMark from '../../assets/img/tick_mark_icon.svg';
import VoteImage from '../../assets/img/vote_icon.svg';
import { fontSizes } from '../../style/fontSizes';
import buttonTypes from '../../utils/constants/buttonTypes';
import { problemTypes } from '../../utils/constants/problemTypes';
import pxToRem from '../../utils/helpers/pxToRem';
import CustomButton from '../Common/CustomButton';
import CustomInput from '../Common/CustomInput';
import CustomSelect from '../Common/CustomSelect';
import {
  isNotNull,
  maxLength,
  minLength,
  nestValidators,
  validateFormAndReturnErrorsObjectOrNull,
  validateFormInput,
} from 'utils/helpers/validation';
import ErrorMessage from 'components/Common/ErrorMessage';
import parseServerError from 'utils/helpers/parseServerError';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

const ProblemsModal = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const modalProps = useModalStore((state) => state.modalProps);
  const modalName = modalProps?.modalName;
  const flatId = useSelectedFlatId();
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const [formData, setFormData] = useState({
    taskType: null,
    taskSubType: null,
    description: '',
    phoneNumber: '',
  });
  const parseProblemTypesToSelectOptions = ({ type, text }) => ({
    value: type,
    label: text,
  });
  const selectedTaskType = formData?.taskType?.value;
  const problemTypeOptions = problemTypes.map(parseProblemTypesToSelectOptions);
  const problemSubtypeOptions = selectedTaskType
    ? problemTypes
        .find((type) => type.type === selectedTaskType)
        ?.subtypes?.map(parseProblemTypesToSelectOptions) ?? []
    : [];

  const handleFieldValidation = (name, value) => {
    const inputErrorMessage = validateFormInput(FORM_VALIDATORS, name, value);
    setErrors((prev) => ({ ...prev, [name]: inputErrorMessage }));
  };
  const onInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    handleFieldValidation(fieldName, fieldValue);
    return setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  const onFileInput = (files) => {
    return setFormData({
      ...formData,
      file: files?.[0],
    });
  };

  const onSelectInputChange = (name, newValue) => {
    if (name === 'taskSubType' && problemSubtypeOptions.length === 0) {
      setErrors((prev) => ({ ...prev, taskSubType: null }));
    } else {
      handleFieldValidation(name, newValue);
    }
    return setFormData({
      ...formData,
      [name]: newValue,
      ...(name === 'taskType' && { taskSubType: null }),
    });
  };

  useEffect(() => {
    onSelectInputChange('taskType', problemTypeOptions[0]);
  }, []);
  const onReportProblem = async () => {
    const formValidators = problemSubtypeOptions.length
      ? FORM_VALIDATORS
      : { ...FORM_VALIDATORS, taskSubType: undefined }; //dont validate subType if array of options is empty

    const validationErrors = validateFormAndReturnErrorsObjectOrNull(
      formData,
      formValidators
    );

    validationErrors ? setErrors(validationErrors) : setErrors({});
    if (validationErrors) return;
    try {
      await postProblemApi(
        flatId,
        formData?.taskType?.value,
        formData.taskSubType?.value,
        formData.description,
        formData.file,
        formData.phoneNumber
      );
      setIsSubmitted(true);
    } catch (e) {
      setServerError(parseServerError(e));
      setIsSubmitted(false);
    }
  };
  return (
    <Wrapper>
      <ModalHeader>
        <div className="modal-title">{modalName}</div>
      </ModalHeader>
      <ModalBody>
        <CustomSelect
          label="* Izaberi tip problema"
          placeholder="Tip problema"
          name="taskType"
          width="100%"
          height={pxToRem(52)}
          options={problemTypeOptions}
          value={formData.taskType}
          onChange={(newValue) => onSelectInputChange('taskType', newValue)}
          errorMessage={errors.taskType}
        />

        {!!problemSubtypeOptions.length && (
          <CustomSelect
            label="* Izaberi podtip problema"
            placeholder="Podtip problema"
            name="taskSubType"
            value={formData.taskSubType}
            width="100%"
            height={pxToRem(52)}
            options={problemSubtypeOptions}
            onChange={(newValue) =>
              onSelectInputChange('taskSubType', newValue)
            }
            errorMessage={errors.taskSubType}
          />
        )}

        <CustomInput
          type="textarea"
          label="* Opis Problema"
          name="description"
          errorMessage={errors.description}
          height={pxToRem(94)}
          onChange={onInput}
        />

        <CustomInput
          type="text"
          label="* Broj telefona"
          name="phoneNumber"
          errorMessage={errors.phoneNumber}
          height={pxToRem(44)}
          onChange={onInput}
          value={formData.phoneNumber}
        />

        <div>
          <CustomInput
            label="* Dodaj Fotografiju"
            placeholder="Samo JPEG, max 12MB"
            name="file"
            type="file"
            onChange={onFileInput}
            height={pxToRem(94)}
            accept={'image/jpeg'}
          />
          <ErrorMessage>{serverError}</ErrorMessage>
        </div>

        {isSubmitted ? (
          <div className="wrapper-success">
            <img
              className="tick-mark"
              src={TickMark}
              alt="tick mark image"
            />
            <img
              className="vote-icon"
              src={VoteImage}
              alt="vote image"
            />
            <div className="text-wrapper">
              <h4>Hvala</h4>
              <h5>Uspešno ste prijavili problem</h5>
            </div>
          </div>
        ) : (
          <CustomButton
            type={buttonTypes.primary}
            width="100%"
            height={pxToRem(52)}
            onClick={onReportProblem}
            disabled={isSubmitted}
            style={{
              fontSize: fontSizes.lg,
              fontWeight: '600',
              marginTop: '16px',
            }}
          >
            <ProblemImage
              src={ProblemImageButton}
              alt="button image"
            />
            Pošalji
          </CustomButton>
        )}
      </ModalBody>
    </Wrapper>
  );
};

ProblemsModal.propTypes = {
  terms: PropTypes.any,
};

const FORM_VALIDATORS = {
  taskType: isNotNull('Morate odabrati tip problema'),
  taskSubType: isNotNull('Morate odabrati podtip problema'),
  description: nestValidators(
    minLength(10, 'Opis mora imati bar 10 karaktera'),
    maxLength(100, 'Opis ne sme imati više od 100 karaktera')
  ),
  phoneNumber: maxLength(32, 'Broj telefona ne sme imati više od 32 karaktera'),
};

const Wrapper = styled.div`
  .modal-body {
    flex-direction: column;
    display: flex;
    gap: 12px;
    font-weight: 500;
  }
`;

const ModalHeader = styled.div`
  border-bottom: 0;

  .modal-title {
    padding: 2rem 3rem 1rem 3.6rem;
    font-size: 22px;
    font-weight: 600;
    border-radius: 8px 8px 0 0;
    border-top: 8px solid ${(props) => props.theme.colors.lightBlueColor};
  }
`;

const ModalBody = styled.div`
  font-size: 0.875rem;
  flex-direction: column;
  display: flex;
  gap: 12px;
  font-weight: 500;
  padding: 2rem 3.6rem 3rem 3.6rem;

  .wrapper-success {
    display: flex;
    background-color: ${(props) => props.theme.colors.inputFocusColor};
    padding: 0.5rem;
    width: 100%;
    position: relative;

    .tick-mark {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .vote-icon {
      margin-left: 1rem;
      margin-right: auto;
      padding-right: 2.5rem;
      border-right: 2px solid ${(props) => props.theme.colors.whiteColor};
    }

    .text-wrapper {
      margin-left: 0.5rem;
      padding: 0.5rem;
      width: 70%;
      text-align: center;
      background-color: ${(props) => props.theme.colors.whiteColor};

      h4 {
        font-size: 21px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.lightBlueColor};
      }

      h5 {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.darkBlueColor};
      }
    }

    p {
      margin: 0;
    }
  }
`;

const ProblemImage = styled.img`
  position: absolute;
  left: 20px;
  top: 15px;
`;

export default ProblemsModal;
