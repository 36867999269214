const SvgComponent = (props) => (
    <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.2507 8.24992C10.5562 8.24992 9.96593 8.00686 9.47982 7.52075C8.99371 7.03464 8.75065 6.44436 8.75065 5.74992C8.75065 5.05547 8.99371 4.4652 9.47982 3.97908C9.96593 3.49297 10.5562 3.24992 11.2507 3.24992C11.9451 3.24992 12.5354 3.49297 13.0215 3.97908C13.5076 4.4652 13.7507 5.05547 13.7507 5.74992C13.7507 6.44436 13.5076 7.03464 13.0215 7.52075C12.5354 8.00686 11.9451 8.24992 11.2507 8.24992ZM4.58398 11.1666C4.24023 11.1666 3.94596 11.0442 3.70117 10.7994C3.45638 10.5546 3.33398 10.2603 3.33398 9.91658V1.58325C3.33398 1.2395 3.45638 0.945231 3.70117 0.700439C3.94596 0.455648 4.24023 0.333252 4.58398 0.333252H17.9173C18.2611 0.333252 18.5553 0.455648 18.8001 0.700439C19.0449 0.945231 19.1673 1.2395 19.1673 1.58325V9.91658C19.1673 10.2603 19.0449 10.5546 18.8001 10.7994C18.5553 11.0442 18.2611 11.1666 17.9173 11.1666H4.58398ZM6.66732 9.91658H15.834C15.834 9.33325 16.0354 8.8402 16.4382 8.43742C16.8409 8.03464 17.334 7.83325 17.9173 7.83325V3.66658C17.334 3.66658 16.8409 3.4652 16.4382 3.06242C16.0354 2.65964 15.834 2.16659 15.834 1.58325H6.66732C6.66732 2.16659 6.46593 2.65964 6.06315 3.06242C5.66037 3.4652 5.16732 3.66658 4.58398 3.66658V7.83325C5.16732 7.83325 5.66037 8.03464 6.06315 8.43742C6.46593 8.8402 6.66732 9.33325 6.66732 9.91658ZM2.08398 13.6666C1.74023 13.6666 1.44596 13.5442 1.20117 13.2994C0.95638 13.0546 0.833984 12.7603 0.833984 12.4166V3.45825C0.833984 3.28117 0.894227 3.13273 1.01471 3.01294C1.13521 2.89315 1.28452 2.83325 1.46263 2.83325C1.64076 2.83325 1.78885 2.89315 1.9069 3.01294C2.02496 3.13273 2.08398 3.28117 2.08398 3.45825V12.4166H16.0423C16.2194 12.4166 16.3678 12.4768 16.4876 12.5973C16.6074 12.7178 16.6673 12.8671 16.6673 13.0452C16.6673 13.2234 16.6074 13.3714 16.4876 13.4895C16.3678 13.6076 16.2194 13.6666 16.0423 13.6666H2.08398Z"
            fill={props?.fill ? props?.fill : '#4A8CEA'}
        />
    </svg>
);

export default SvgComponent;
