import { default as axios } from 'axios';
import routes from 'routes';
import { useAuthStore } from 'store/AuthStore';

// Init the axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const responseInterceptor = api.interceptors.response.use(
    (response) => {
        // Process successful responses
        return response;
    },
    (error) => {
        const removeToken = useAuthStore.getState().removeToken
        if (error.response && error.response.status === 401) {
            // Delete user info from Auth Store
            removeToken();
            // Redirect the user to the login page using React Router

            // If user not request login API point
            if (error.config.url !== routes.logIn.path) {
                // navigate(`${routes.logIn.path}?${sessionExpiredParam}=true`);
                location.href = routes.logIn.path;
            }
        }
        return Promise.reject(error);
    }
);

const requestInterceptor = (config) => {
    const token = useAuthStore.getState().token;
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
};

api.interceptors.request.use(requestInterceptor)
api.interceptors.response.use(responseInterceptor)


export default api;
