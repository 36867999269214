import styled from 'styled-components';
import { fontSizes } from '../../../style/fontSizes';
import PropTypes from 'prop-types';

export const StatisticsBox = (props) => {
  const { title, info, icon, className } = props;

  return (
    <StatisticsWrapper className={className}>
      <p>{title}</p>
      <h2>{info}</h2>
      <img
        src={icon}
        alt="info icon"
      />
    </StatisticsWrapper>
  );
};

StatisticsBox.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  info: PropTypes.any,
  icon: PropTypes.string,
};

const StatisticsWrapper = styled.section`
  background: ${(props) => props.theme.colors.whiteColor};
  border-radius: 6px;
  width: 100%;
  max-width: 362px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 6px;
  padding: 5px;

  p {
    font-size: ${fontSizes.md};
    color: ${(props) => props.theme.colors.darkBlueColor};
    width: 33%;
    margin-bottom: 0;
    flex: 1;
    font-weight: 600;
  }

  h2 {
    font-size: 28px;
    color: ${(props) => props.theme.colors.lightBlueColor};
    margin-bottom: 0;
    font-weight: 600;
    flex: 1;
    text-align: center;
  }

  img {
    max-height: 50px;
    max-width: 37px;
  }
`;
