import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useModalStore } from 'store/ModalStore';
import styled from 'styled-components';
import { modalTypeNames } from 'utils/constants/modalTypes';
import pxToRem from 'utils/helpers/pxToRem';
import { getPollsApi } from '../../api/polls';
import { usePollListStore } from '../../store/PollListStore';
import PollListIcon from 'assets/img/pollListIcon.svg';
import CustomButton from '../../components/Common/CustomButton';
import buttonTypes from '../../utils/constants/buttonTypes';
import { fontSizes } from '../../style/fontSizes';
import { breakpoints } from '../../style/breakpoints';
import { lightTheme } from '../../style/themes';
import transformDate from '../../utils/constants/transformDate';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';

const PollsTable = () => {
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const tableHeaders = [
    {
      name: 'createdAt',
      displayName: 'Datum',
      hasOrder: true,
    },
    {
      name: 'title',
      displayName: 'Naslov',
      hasOrder: true,
    },
    {
      name: 'status',
      displayName: 'Status',
      hasOrder: false,
    },
    {
      name: 'action',
      displayName: 'Akcija',
      hasOrder: false,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);

  const fillStore = usePollListStore((state) => state.fillStore);
  const selectedFlatId = useSelectedFlatId();

  const [currentStatus, setCurrentStatus] = useState('active');

  // TODO: Use this from API.
  const [pollsList, setPollsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // TODO: Use this from API.
  const [paginationData, setPaginationData] = useState({
    // Current page
    page: 1,
    // Total number of records from db
    totalRecords: 0,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: 0,
    // Per page !
    pageSize: pageSizeOptions[0],

    // Sorting info
    sortBy: '',
    sortOrder: '',

    // Filter info - search
    filter: '',
  });

  useEffect(() => {
    setIsLoading(true);
    getPollsData(currentStatus);
  }, [
    paginationData.page,
    paginationData.pageSize.value,
    paginationData.sortBy,
    paginationData.sortOrder,
    paginationData.filter,
  ]);

  const getPollsData = async (status) => {
    setCurrentStatus(status);

    if (status !== currentStatus) {
      paginationData.page = 1;
    }

    const res = await getPollsApi(
      selectedFlatId,
      paginationData.page,
      paginationData.pageSize.value,
      status
    );

    const data = res.data.map((item) => {
      const poll = item;
      let statusTitle = 'Aktivan';
      let actionTitle = 'Glasaj';
      let buttonColor = lightTheme.colors.lightBlueColor;
      if (status !== 'active') {
        statusTitle = 'Neaktivan';
        actionTitle = 'Pogledaj';
        buttonColor = lightTheme.colors.dangerColor;
      }

      let flatsVotedFor = [];
      let flatsVotedAgainst = [];

      poll.pollOptions.map((pollOption) => {
        pollOption.pollOptionsFlats.map((flat) => {
          if (pollOption.description === 'Da') {
            flatsVotedFor.push(flat.id);
          } else {
            flatsVotedAgainst.push(flat.id);
          }
        });
      });

      poll.flatsVotedFor = flatsVotedFor;
      poll.flatsVotedAgainst = flatsVotedAgainst;

      return {
        ...item,
        createdAt: transformDate(item.createdAt),
        title: <strong>{item.title}</strong>,
        status: (
          <div
            style={{
              color: lightTheme.colors.whiteColor,
              backgroundColor: buttonColor,
              fontSize: '10px',
              width: '58px',
              borderRadius: '2rem',
              textAlign: 'center',
            }}
          >
            {statusTitle}
          </div>
        ),
        action: (
          <CustomButton
            type={buttonTypes.primary}
            style={{ fontSize: '10px', borderRadius: '2rem' }}
            height={'20px'}
            width={'58px'}
            border={'none'}
            onClick={() => onPollClick({ poll })}
          >
            {actionTitle}
          </CustomButton>
        ),
      };
    });

    fillStore(data);
    const polls = usePollListStore.getState().pollsList;

    setPaginationData((prevData) => ({
      ...prevData,
      totalRecords: res.count,
      pageNumbers: Math.ceil(polls.length / paginationData.pageSize.value),
    }));

    setPollsList(data);
    setIsLoading(false);

    return polls;
  };

  const onChangePage = (value) => {
    setPaginationData({
      ...paginationData,
      page: value,
    });

    window.scrollTo(0, 0);
  };

  const onChangeOrder = (value) => {
    setPaginationData({
      ...paginationData,
      sortBy: value.type,
      sortOrder: value.headerName,
    });
  };

  // Modal handlers
  const onPollClick = ({ poll }) => {
    toggleModal(modalTypeNames.pollDetailsModal, {
      modalName: poll.title,
      poll,
    });
  };

  return (
    <>
      <PageHeader>
        <TableHeaderInfo>
          <div>
            <img
              src={PollListIcon}
              alt="poll list icon"
            />
          </div>
          <h2>
            {currentStatus === 'inactive'
              ? 'Neaktivna glasanja'
              : 'Aktivna glasanja'}
          </h2>
        </TableHeaderInfo>
        <TableHeaderAction>
          <CustomButton
            font-size={fontSizes.md}
            type={
              currentStatus === 'active'
                ? buttonTypes.primary
                : buttonTypes.secondary
            }
            height={'32px'}
            width={'156px'}
            border={'1px solid white'}
            onClick={() => getPollsData('active')}
          >
            AKTIVNA
          </CustomButton>

          <CustomButton
            font-size={fontSizes.md}
            type={
              currentStatus === 'inactive'
                ? buttonTypes.primary
                : buttonTypes.secondary
            }
            height={'32px'}
            width={'156px'}
            border={'1px solid white'}
            onClick={() => getPollsData('inactive')}
          >
            ISTORIJA
          </CustomButton>
        </TableHeaderAction>
      </PageHeader>

      {isLoading ? (
        <SpinnerPlace>
          <Spinner
            color="secondary"
            type="border"
          />
        </SpinnerPlace>
      ) : (
        <CustomTable
          headers={tableHeaders}
          items={pollsList}
          isStriped
          paginationData={{
            page: paginationData.page,
            pageNumbers: paginationData.pageNumbers,
            pageSize: paginationData.pageSize.value,
            totalRecords: paginationData.totalRecords,
          }}
          onChangePage={onChangePage}
          onClickOnOrder={onChangeOrder}
        />
      )}
    </>
  );
};

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: ${pxToRem(12)};
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const TableHeaderAction = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-right: ${pxToRem(12)};

  @media ${breakpoints.md} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SpinnerPlace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

export default PollsTable;
