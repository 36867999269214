const getTranslatedBillMonthYearForDate = (date) => {
    // Create a new Date object with the provided date string
    const dateObj = new Date(date);
    let month = dateObj.getMonth();
    let year = dateObj.getFullYear();

    if (month === 0) {
        month = 12;
        year -= 1;
    }

    const translatedMonth = getTranslatedMonth(month);
    return translatedMonth + ' ' + year;
}

const getTranslatedMonth = (month) => {
    let monthName;

    switch (month) {
        case 1:
            monthName = "Januar";
            break;
        case 2:
            monthName = "Februar";
            break;
        case 3:
            monthName = "Mart";
            break;
        case 4:
            monthName = "April";
            break;
        case 5:
            monthName = "Maj";
            break;
        case 6:
            monthName = "Jun";
            break;
        case 7:
            monthName = "Jul";
            break;
        case 8:
            monthName = "Avgust";
            break;
        case 9:
            monthName = "Septembar";
            break;
        case 10:
            monthName = "Oktobar";
            break;
        case 11:
            monthName = "Novembar";
            break;
        case 12:
            monthName = "Decembar";
            break;
        default:
            monthName = "Nepoznat mesec";
    }

    return monthName;
}

export default getTranslatedBillMonthYearForDate;
