import api from 'api';

const url = '/auth/login';

export const postLoginApi = async (email, password) => {
  const res = await api.post(`${url}`, {
    email: email,
    password: password,
  });
  return res.data;
};
