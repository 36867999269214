const { useEffect } = require('react');

/**
 * Hook which should we used on Modals, DropDowns or something else like that
 * where we can call some function when user
 * click outside border of that component.
 *
 * @param  {React.MutableRefObject} ref a reference to the element on which we want to listen for the onClickOutside event
 * @param  {Function} handler handler function which handle onClickOutside
 * @param  {Array} preventRefs array of refs on which we want not to affect onClickOutside event (like a button which already has logic which handle hide/show component)
 */
const useOnClickOutside = (ref, handler, preventRefs) => {
  useEffect(() => {
    const listener = (event) => {
      // Prevent close component if click happened on itself
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      // Prevent close if click happened on some of preventRefs item
      // Using when we have some button which show/hide component on click
      let isPrevent = false;
      if (typeof preventRefs !== 'undefined' && preventRefs.length > 0) {
        isPrevent = preventRefs.some((el) => {
          if (el.current?.contains(event.target)) {
            return true;
          }
        });
      }

      if (isPrevent) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, preventRefs]);
};

export default useOnClickOutside;
