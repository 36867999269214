import DeleteIcon from 'assets/table/actions/DeleteIcon.jsx';
import EditIcon from 'assets/table/actions/EditIcon.jsx';
import ViewIcon from 'assets/table/actions/ViewIcon.jsx';
import CustomButton from 'components/Common/CustomButton';
import DashboardOpenBillIcon from "assets/table/actions/DashboardOpenBillIcon.jsx";

export const actionTypes = {
  deleteAction: 'deleteAction',
  editAction: 'editAction',
  scheduleUpdate: 'scheduleUpdate',
  openBill: 'openBill',
  dashboardOpenBill: 'dashboardOpenBill'
};

export const actionIcons = {
  [actionTypes.deleteAction]: DeleteIcon,
  [actionTypes.editAction]: EditIcon,
  [actionTypes.scheduleUpdate]: CustomButton,
  [actionTypes.openBill]: ViewIcon,
  [actionTypes.dashboardOpenBill]: DashboardOpenBillIcon
};
