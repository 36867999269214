import CustomTable from 'components/Common/CustomTable';
import { useEffect, useState } from 'react';
import { getAllBillsApi, getBillPdf } from '../../api/bills';
import { useBillsStore } from '../../store/BillsStore';
import pxToRem from '../../utils/helpers/pxToRem';
import { Spinner } from 'reactstrap';
import { actionTypes } from '../../utils/constants/customTable/actions';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useModalStore } from '../../store/ModalStore';
import BillIcon from 'assets/table/actions/BillIcon';
import BillPaymentIcon from '../../assets/table/actions/BillPaymentIcon';
import transformDate from '../../utils/constants/transformDate';
import { breakpoints } from '../../style/breakpoints';
import BillsIcon from 'assets/img/bills_icon.svg';
import config from '../../config';
import getTranslatedBillMonthYearForDate from '../../utils/helpers/monthForDate';
import useSelectedFlatId from 'utils/hooks/useSelectedFlatId';
import { MD5 } from 'crypto-js';

const Bills = () => {
  const pageSizeOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
  ];

  //name is the field name in data
  const tableHeaders = [
    {
      name: 'debit',
      displayName: '',
      hasOrder: true,
    },
    {
      name: 'month',
      displayName: 'Datum',
      hasOrder: true,
    },
    {
      name: 'comment',
      displayName: 'Opis',
      hasOrder: true,
    },
    {
      name: 'amount',
      displayName: 'Iznos',
      hasOrder: true,
    },
  ];

  const toggleModal = useModalStore((state) => state.toggleModal);

  const fillStore = useBillsStore((state) => state.fillStore);

  const selectedFlatId = useSelectedFlatId();

  const getBillsData = async () => {
    const res = await getAllBillsApi(
      selectedFlatId,
      paginationData.page,
      paginationData.pageSize.value
    );

    const data = res.data.map((item) => {
      const isDebit = item.debit === 1;
      return {
        ...item,
        comment: item.comment ? 'Obračun' : 'Uplata',
        debit:
          item.debit === 1 ? (
            <div>
              <BillIcon />
            </div>
          ) : (
            <div>
              <BillPaymentIcon />
            </div>
          ),
        debitInfo: item.debit,
        month: isDebit
          ? getTranslatedBillMonthYearForDate(item.month)
          : transformDate(item.month),
        amount: item.amount.toFixed(2) + ' ' + 'din',
      };
    });

    fillStore(data);
    const bills = useBillsStore.getState().bills; // Accessing the 'bills' state from useDashboardStore

    setPaginationData((prevData) => ({
      ...prevData,
      totalRecords: res.count,
      pageNumbers: Math.ceil(bills.length / paginationData.pageSize.value),
    }));

    setBillList(data);
    setBillType(data);

    setIsLoading(false);

    return bills;
  };

  // TODO: Use this from API.
  const [billList, setBillList] = useState([]);

  const [billType, setBillType] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [paginationData, setPaginationData] = useState({
    // Current page
    page: 1,
    // Total number of records from db
    totalRecords: 0,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: 0,
    // Per page !
    pageSize: pageSizeOptions[0],

    // Sorting info
    sortBy: '',
    sortOrder: '',

    // Filter info - search
    filter: '',
  });

  useEffect(() => {
    setIsLoading(true);

    getBillsData();
  }, [
    paginationData.page,
    paginationData.pageSize.value,
    paginationData.sortBy,
    paginationData.sortOrder,
    paginationData.filter,
  ]);

  const openBill = (billId) => {
    const hashedSelectedFlatId = MD5(String(selectedFlatId)).toString();
    const hashedBillId = MD5(String(billId)).toString();
    // Call the API function to update the schedule
    getBillPdf(selectedFlatId, billId)
      .then(() => {
        // Handle the API response here (if needed)
        window.open(
          `${config.storageUrl}/pdfBills/${hashedSelectedFlatId}/${hashedBillId}.pdf`,
          '_blank'
        );
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error('API Error:', error);
      });
  };

  const onChangePage = (value) => {
    setPaginationData({
      ...paginationData,
      page: value,
    });

    window.scrollTo(0, 0);
  };

  const onChangeOrder = (value) => {
    setPaginationData({
      ...paginationData,
      sortBy: value.type,
      sortOrder: value.headerName,
    });
  };

  const onClickOnAction = (details) => {
    if (details.actionType === actionTypes.openBill) {
      const billId = details.item.id;
      openBill(billId);
    }
  };

  const debouncedChangeFilter = useDebouncedCallback((e) => {
    onChangeFilter(e.target.value);
  }, 500);

  const onChangeFilter = (value) => {
    setPaginationData({ ...paginationData, filter: value });
  };

  const onChangePageSize = (value) => {
    setPaginationData({ ...paginationData, pageSize: value, page: 1 });
  };

  return (
    <Wrapper>
      <PageHeader>
        <TableHeaderInfo>
          <div>
            <img
              src={BillsIcon}
              alt="bills list icon"
            />
          </div>
          <h2>Moja zaduženja</h2>
        </TableHeaderInfo>
        <TableHeaderAction></TableHeaderAction>
      </PageHeader>

      {isLoading ? (
        <SpinnerPlace>
          <Spinner
            color="secondary"
            type="border"
          />
        </SpinnerPlace>
      ) : (
        <CustomTable
          actions={[actionTypes.openBill]}
          type={billType}
          headers={tableHeaders}
          items={billList}
          isStriped
          paginationData={{
            page: paginationData.page,
            pageNumbers: paginationData.pageNumbers,
            pageSize: paginationData.pageSize.value,
            totalRecords: paginationData.totalRecords,
          }}
          onChangePage={onChangePage}
          onClickOnOrder={onChangeOrder}
          onClickOnAction={onClickOnAction}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(24)};
  background-color: ${(props) => props.theme.colors.darkBlueColor};
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: -8px;

  @media ${breakpoints.md} {
    flex-direction: column;
    align-items: center;
    row-gap: 0;
  }
`;

const TableHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  h2 {
    font-weight: 800;
    font-size: 16px;
    color: ${(props) => props.theme.colors.whiteColor};
    margin: 0 0 0 ${pxToRem(12)};
    padding: 8px;
  }

  div {
    width: 28px;
    height: 28px;
    background-color: ${(props) => props.theme.colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: ${pxToRem(12)};
  }
`;

const TableHeaderAction = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-right: ${pxToRem(12)};

  @media ${breakpoints.md} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const SpinnerPlace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
`;

export default Bills;
