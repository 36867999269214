const SvgComponent = (props) => (
    <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.875 12C1.56563 12 1.30078 11.8898 1.08047 11.6695C0.860156 11.4492 0.75 11.1844 0.75 10.875V2.25H1.875V10.875H15V12H1.875ZM4.125 9.75C3.81563 9.75 3.55078 9.63984 3.33047 9.41953C3.11016 9.19922 3 8.93438 3 8.625V1.125C3 0.815625 3.11016 0.550781 3.33047 0.330469C3.55078 0.110156 3.81563 0 4.125 0H16.125C16.4344 0 16.6992 0.110156 16.9195 0.330469C17.1398 0.550781 17.25 0.815625 17.25 1.125V8.625C17.25 8.93438 17.1398 9.19922 16.9195 9.41953C16.6992 9.63984 16.4344 9.75 16.125 9.75H4.125ZM6 8.625C6 8.1 5.81875 7.65625 5.45625 7.29375C5.09375 6.93125 4.65 6.75 4.125 6.75V8.625H6ZM14.25 8.625H16.125V6.75C15.6 6.75 15.1563 6.93125 14.7938 7.29375C14.4313 7.65625 14.25 8.1 14.25 8.625ZM10.125 7.125C10.75 7.125 11.2812 6.90625 11.7188 6.46875C12.1562 6.03125 12.375 5.5 12.375 4.875C12.375 4.25 12.1562 3.71875 11.7188 3.28125C11.2812 2.84375 10.75 2.625 10.125 2.625C9.5 2.625 8.96875 2.84375 8.53125 3.28125C8.09375 3.71875 7.875 4.25 7.875 4.875C7.875 5.5 8.09375 6.03125 8.53125 6.46875C8.96875 6.90625 9.5 7.125 10.125 7.125ZM4.125 3C4.65 3 5.09375 2.81875 5.45625 2.45625C5.81875 2.09375 6 1.65 6 1.125H4.125V3ZM16.125 3V1.125H14.25C14.25 1.65 14.4313 2.09375 14.7938 2.45625C15.1563 2.81875 15.6 3 16.125 3Z"
            fill={props?.fill ? props?.fill : 'white'}
        />
    </svg>
);

export default SvgComponent;
