import api from 'api';

const prefix = '/Safes';

export const getListDeviceApi = async (
  page,
  pageSize,
  sortBy,
  sortOrder,
  filter
) => {
  const res = await api.get(`${prefix}/pages`, {
    withCredentials: true,
    params: {
      Page: page,
      PageSize: pageSize,
      SortBy: sortBy,
      SortOrder: sortOrder,
      Filter: filter,
    },
  });

  return res.data;
};

// TODO: Need to use deviceId for getting status for one device
export const getStatusDeviceApi = async (_deviceId) => {
  const res = await api.get(`${prefix}/Status`);

  return res.data;
};
