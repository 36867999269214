import styled from 'styled-components';
import pxToRem from 'utils/helpers/pxToRem';
import { useAuthStore } from 'store/AuthStore';
import { useRef, useState } from 'react';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { Footer } from '../Footer';
import LoginIcon from 'assets/img/login_icon.svg';
import { Link } from 'react-router-dom';
import { useGlobalStore } from 'store/GlobalStore';
import { breakpoints } from 'style/breakpoints';
import LogoutIcon from 'assets/img/dropdown_logout.svg';
import UserIcon from 'assets/img/dropdown_user.svg';
import ProfileIcon from 'assets/img/dropdown_profile.svg';
import SettingIcon from 'assets/img/dropdown_settings.svg';
import { useDashboardStore } from '../../../store/DashboardStore';
import { modalTypeNames } from 'utils/constants/modalTypes';
import { useModalStore } from 'store/ModalStore';
import { lightTheme } from '../../../style/themes';
import CustomButton from 'components/Common/CustomButton';
import PlusIcon from 'assets/img/plus_icon.svg'
import {fontSizes} from "../../../style/fontSizes";

const Topbar = () => {
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const dropDownRef = useRef();
  const profilePictureRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const isOnLogin = location.pathname === routes.logIn.path;
  const isResetPassword = location.pathname === routes.resetPassword.path;
  const removeAccessToken = useAuthStore((state) => state.removeToken);
  const isExpanded = useGlobalStore((state) => state.isExpanded);
  const { pathname } = location;
  const authPrefix = '/auth';
  const isOnAuth = pathname.includes(authPrefix);
  const toggleModal = useModalStore((state) => state.toggleModal);
  const setSelectedFlatId = useGlobalStore((store) => store.setSelectedFlatId);
  const [flat] = useDashboardStore((state) => [state.flat]);

  const handleLogout = () => {
    removeAccessToken();
    setSelectedFlatId(undefined);
    toggleModal();
    // Redirect
    navigate(routes.logIn.path, { replace: true });

    // Hide drop down
    setIsShowDropDown(false);
  };

  const onLogoutClick = () => {
    toggleModal(modalTypeNames.confirmationModal, {
      confirmationMessage: 'Da li ste sigurni da želite da se izlogujete?',
      onConfirm: handleLogout,
    });
  };

  const handleFlatButtonClick = () =>
    toggleModal(modalTypeNames.chooseFlatModal);

  useOnClickOutside(
    dropDownRef,
    () => {
      setIsShowDropDown(false);
    },
    [profilePictureRef]
  );

  return (
    <>
      <TopLine />
      <Wrapper
        isSideExpanded={isExpanded}
        isRouteAuth={isOnAuth}
      >
        {!isOnLogin && !isResetPassword ? (
          <Content
            isSideExpanded={isExpanded}
            style={{ cursor: 'pointer' }}
          >
            {isOnAuth && (
              <>
                <a
                  href={'../../../../Upustvo-portal-za-stanare.pdf'}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  className={'me-4'}
                  style={{ color: lightTheme.colors.darkBlueColor }}
                  onMouseEnter={(e) =>
                    (e.target.style.color = lightTheme.colors.lightBlueColor)
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.color = lightTheme.colors.darkBlueColor)
                  }
                >
                  Upustvo
                </a>
                <Link to={'/auth/login'}>
                  {<span>Uloguj se</span>}
                  <img
                    src={LoginIcon}
                    alt="Login"
                  />
                </Link>
              </>
            )}

            {!isOnAuth && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <CustomButton
                  width={pxToRem(240)}
                  onClick={handleFlatButtonClick}
                  type="primary"
                >
                  Moje stambene jedinice
                  <img
                      src={PlusIcon}
                      alt="plus icon"
                      style={{margin: '0 0 2px 5px'}}
                  />
                </CustomButton>
                <img
                  src={LoginIcon}
                  alt="Login"
                  onClick={() => setIsShowDropDown(!isShowDropDown)}
                />
                <Dropdown
                  ref={dropDownRef}
                  active={isShowDropDown}
                >
                  <MenuDropdown>
                    <MenuDropdownItem>
                      <Icon className="icon-align">
                        <img
                          src={UserIcon}
                          alt="user icon"
                        />
                      </Icon>
                      <MenuDropdownText>{flat?.fullName}</MenuDropdownText>
                    </MenuDropdownItem>

                    <Link to={'/profile'}>
                      <MenuDropdownItem>
                        <Icon className="icon-align">
                          <img
                            src={ProfileIcon}
                            alt="profile icon"
                          />
                        </Icon>
                        <MenuDropdownText>Moj profil</MenuDropdownText>
                      </MenuDropdownItem>
                    </Link>

                    <Link to={'/settings'}>
                      <MenuDropdownItem>
                        <Icon className="icon-align">
                          <img
                            src={SettingIcon}
                            alt="setting icon"
                          />
                        </Icon>
                        <MenuDropdownText>Podešavanja</MenuDropdownText>
                      </MenuDropdownItem>
                    </Link>

                    <MenuDropdownItem onClick={onLogoutClick}>
                      <Icon className="icon-align">
                        <img
                          src={LogoutIcon}
                          alt="logout icon"
                        />
                      </Icon>
                      <MenuDropdownText>Izloguj se</MenuDropdownText>
                    </MenuDropdownItem>
                  </MenuDropdown>
                </Dropdown>
              </div>
            )}
          </Content>
        ) : (
          <a
            href={'../../../../Upustvo-portal-za-stanare.pdf'}
            target={'_blank'}
            rel="noopener noreferrer"
            className={'me-4'}
            style={{ color: lightTheme.colors.darkBlueColor }}
            onMouseEnter={(e) =>
              (e.target.style.color = lightTheme.colors.lightBlueColor)
            }
            onMouseLeave={(e) =>
              (e.target.style.color = lightTheme.colors.darkBlueColor)
            }
          >
            Upustvo
          </a>
        )}
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  height: ${pxToRem(50)};
  padding-left: 32px;
  padding-right: 14px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: fixed;
  z-index: 5;
  background-color: ${(props) => props.theme.colors.whiteColor};
  width: ${(props) =>
    props.isSideExpanded ? 'calc(100% - 230px)' : 'calc(100% - 90px)'};
  margin-left: ${(props) =>
    props.isSideExpanded ? pxToRem(230) : pxToRem(90)};
  transition: margin-left 0.3s ease-in-out;

  @media ${breakpoints.sm} {
    width: ${(props) =>
      props.isRouteAuth ? 'calc(100% - 120px)' : 'calc(100% - 90px)'};
    margin-left: ${(props) => (props.isRouteAuth ? '120px' : pxToRem(90))};
    font-size: ${fontSizes.md};
  }
`;

const TopLine = styled.div`
  height: 4px;
  width: 100%;
  background: linear-gradient(90.05deg, #4a8cea 23.54%, #091d56 58.02%);
  position: absolute;
  top: 0;
  z-index: 6;
`;

const Dropdown = styled.div`
  background-color: ${(props) => props.theme.colors.whiteColor};
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: absolute;
  top: ${pxToRem(70)};
  right: ${pxToRem(15)};
  min-width: ${pxToRem(200)};
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: translateY(0);
  display: ${(props) => (props.active ? 'flex' : 'none')};

  a {
    color: ${(props) => props.theme.colors.darkBlueColor};
  }
`;

const Content = styled.div`
  color: ${(props) => props.theme.colors.darkBlueColor};
  margin-right: 0;

  span {
    color: ${(props) => props.theme.colors.darkBlueColor};
  }

  span:hover {
    color: ${(props) => props.theme.colors.lightBlueColor};
  }
`;

const MenuDropdown = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  & > :first-child {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.dropdownBorderColor};
    font-size: 16px;
    font-weight: 600;
    cursor: default;
  }

  & > :last-child {
    border-top: 1px solid ${(props) => props.theme.colors.dropdownBorderColor};
  }
`;

const MenuDropdownText = styled.div``;

const MenuDropdownItem = styled.li`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 22px;
  padding: 1rem 1.5rem;
  font-size: ${fontSizes.md};

  :hover {
    color: ${(props) => props.theme.colors.darkBlueColor};
    background-color: ${(props) => props.theme.colors.dropdownHoverColor};
    border-radius: 12px;
  }
`;

const Icon = styled.div`
  width: 21px;
`;

export default Topbar;
