import UserLogo from '../../../../assets/img/user_logo.svg';
import styled from 'styled-components';
import { fontSizes } from '../../../../style/fontSizes';
import PropTypes from 'prop-types';

export const TenatPersonalInfoBox = ({
  name,
  address,
  label = 'Adresa i broj posebnog dela',
}) => {
  return (
    <TenantInfo>
      <TenantName>
        <section>
          <img
            src={UserLogo}
            alt="user logo icon"
          />
          <aside>
            <h1>{name}</h1>
          </aside>
        </section>
      </TenantName>
      <TenantAddress>
        <h5>{label}:</h5>
        <h6>{address}</h6>
      </TenantAddress>
    </TenantInfo>
  );
};

TenatPersonalInfoBox.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  address: PropTypes.string,
  label: PropTypes.string,
};

const TenantInfo = styled.div`
  background: ${(props) => props.theme.colors.lightBlueColor};
  padding: 1rem;
`;

const TenantName = styled.section`
  section {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.whiteColor};
    padding-bottom: 1.2rem;

    img {
      align-self: baseline;
    }
  }

  h1 {
    color: ${(props) => props.theme.colors.whiteColor};
    font-size: 28px;
    font-weight: 600;
  }

  p {
    color: ${(props) => props.theme.colors.whiteColor};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

const TenantAddress = styled.div`
  h5 {
    color: ${(props) => props.theme.colors.whiteColor};
    font-size: 16px;
  }

  h6 {
    color: ${(props) => props.theme.colors.whiteColor};
    font-size: ${fontSizes.lg};
    font-weight: 800;
  }
`;
