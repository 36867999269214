import PropTypes from 'prop-types';
import CustomButton from 'components/Common/CustomButton';
import CustomInput from 'components/Common/CustomInput';
import ModalTitle from 'components/Common/ModalTitle';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useModalStore } from 'store/ModalStore';
import buttonTypes from 'utils/constants/buttonTypes';
import DeviceIdIcon from 'assets/modal/scheduleUpdateModal/DeviceIdIcon';
import DownIcon from 'assets/modal/scheduleUpdateModal/DownIcon';
import pxToRem from 'utils/helpers/pxToRem';
import { useEffect, useState } from 'react';
import { getStatusDeviceApi } from 'api/devices';

const ScheduleUpdateModal = ({ deviceId, onScheduleUpdate }) => {
  const toggleModal = useModalStore((state) => state.toggleModal);
  const [status, setStatus] = useState('getting status...');

  const [files, setFiles] = useState(null);

  const onUploadFile = (files) => {
    setFiles(files);
  };

  const changeStatus = async () => {
    const res = await getStatusDeviceApi(deviceId);

    // TODO: Set status from response from API - need be fixed on API side
    const selectedStatus = res.find((resStatus) => {
      return resStatus.CdsDeviceId === deviceId;
    });

    setStatus(selectedStatus?.Status || '');
  };

  useEffect(() => {
    changeStatus();
  }, [deviceId]);

  return (
    <>
      <ModalBody>
        <ModalTitle className={'mb-2'}>info</ModalTitle>
        <CustomInput
          disabled
          placeholder="Device ID"
          Icon={DeviceIdIcon}
          className="mb-3"
          iconHeigh={pxToRem(12)}
          iconWidth={pxToRem(18)}
          value={deviceId}
        />
        <CustomInput
          disabled
          placeholder="Status"
          Icon={DownIcon}
          iconHeigh={pxToRem(10)}
          iconWidth={pxToRem(10)}
          value={status}
        />
        <ModalTitle className={'mb-2 mt-2'}>Update</ModalTitle>
        <CustomInput
          type="file"
          placeholder="Upload File"
          onChange={onUploadFile}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type={buttonTypes.secondary}
          onClick={() => toggleModal()}
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={() => onScheduleUpdate({ deviceId, files })}
          type={buttonTypes.primary}
        >
          Schedule Update
        </CustomButton>
      </ModalFooter>
    </>
  );
};

ScheduleUpdateModal.propTypes = {
  deviceId: PropTypes.string,
  onScheduleUpdate: PropTypes.func,
};

export default ScheduleUpdateModal;
