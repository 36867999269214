import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import Select from 'react-select';
import pxToRem from 'utils/helpers/pxToRem';
import { fontSizes } from '../../../style/fontSizes';
import { lightTheme } from '../../../style/themes';
import SelectedProblemIcon from '../../../assets/img/problem_selected_icon.svg';
import ErrorMessage from '../ErrorMessage';

const CustomSelect = ({
  options,
  onChange,
  label,
  name,
  width,
  height,
  placeholder,
  border,
  isMulti,
  value,
  isClearable,
  defaultValue,
  className,
  errorMessage,
}) => {
  const theme = useTheme();

  const optionIcon = (option, state) => {
    const selectedValues =
      state?.selectValue?.map((option) => option.value) ?? [];
    const isOptionSelected = selectedValues.includes(option?.value);
    const optionIsInMenu = state.context === 'menu';
    const shouldShowIcon = isOptionSelected && optionIsInMenu;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {option.label}
        {shouldShowIcon ? (
          <img
            src={SelectedProblemIcon}
            alt=""
            style={{ marginRight: '8px' }}
          />
        ) : (
          <span></span>
        )}
      </div>
    );
  };

  return (
    <Wrapper>
      <FormLabel>{label}</FormLabel>
      <CSelect
        className={className}
        name={name}
        border={border}
        width={width}
        height={height}
        placeholder={placeholder}
        label={label}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        value={value}
        defaultValue={defaultValue}
        formatOptionLabel={optionIcon}
        classNamePrefix="react-select"
        // Overrides
        styles={{
          menu: (baseStyles) => {
            return {
              ...baseStyles,
              marginTop: '0',
              marginBottom: '0',
              borderRadius: '0 0 8px 8px',
            };
          },
          control: (baseStyles, state) => {
            const isOpen = state.menuIsOpen;
            return {
              ...baseStyles,
              borderColor: lightTheme.colors.inputColor,
              backgroundColor: isOpen
                ? lightTheme.colors.inputFocusColor
                : lightTheme.colors.whiteColor,
              boxShadow: 'none',
              padding: '7px 0',
              '&:hover': {
                borderColor: lightTheme.colors.lightBlueColor,
                backgroundColor: lightTheme.colors.inputFocusColor,
                boxShadow: '0px 1px 6px rgba(14, 18, 62, 0.15);',
              },
              '.react-select__indicator svg': {
                transform: isOpen ? undefined : 'rotate(-90deg)',
                transition: 'all .3s ease-in-out',
              },
            };
          },
          indicatorSeparator: () => ({
            display: 'none',
          }),
          option: (baseStyles) => {
            return {
              ...baseStyles,
              fontSizes: fontSizes.md,
              color: lightTheme.colors.lightBlackColor,
              backgroundColor: lightTheme.colors.whiteColor,
              ':hover': {
                background: lightTheme.colors.inputFocusColor,
                color: lightTheme.colors.lightBlackColor,
              },
            };
          },
        }}
        onChange={(event) => {
          if (typeof onChange === 'function') {
            onChange(event);
          }
        }}
      ></CSelect>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

CustomSelect.propTypes = {
  border: PropTypes.string,
  defaultValue: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  value: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
  height: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(6)};
`;

const FormLabel = styled.div`
  white-space: nowrap;
  font-size: ${fontSizes.sm};
`;

const CSelect = styled(Select)`
  border: 1px solid;
  border-color: ${(props) => props.border || props.theme.colors.inputColor};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  border-radius: 8px;
  height: auto;
`;

export default CustomSelect;
