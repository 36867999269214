const SvgComponent = (props) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
        d="M5.25 15.9999C3.81667 15.9999 2.58333 15.4832 1.55 14.4499C0.516667 13.4166 0 12.1832 0 10.7499C0 9.4499 0.4125 8.30407 1.2375 7.3124C2.0625 6.32074 3.125 5.7249 4.425 5.5249C4.75833 3.90824 5.54167 2.5874 6.775 1.5624C8.00833 0.537402 9.43333 0.0249023 11.05 0.0249023C12.9333 0.0249023 14.5125 0.704069 15.7875 2.0624C17.0625 3.42074 17.7 5.0499 17.7 6.9499V7.5499C18.9 7.51657 19.9167 7.90407 20.75 8.7124C21.5833 9.52074 22 10.5416 22 11.7749C22 12.9249 21.5833 13.9166 20.75 14.7499C19.9167 15.5832 18.925 15.9999 17.775 15.9999H11.75C11.35 15.9999 11 15.8499 10.7 15.5499C10.4 15.2499 10.25 14.8999 10.25 14.4999V8.0499L8.7 9.5999C8.55 9.7499 8.375 9.82074 8.175 9.8124C7.975 9.80407 7.8 9.7249 7.65 9.5749C7.5 9.4249 7.425 9.24574 7.425 9.0374C7.425 8.82907 7.5 8.6499 7.65 8.4999L10.475 5.6749C10.5583 5.59157 10.6417 5.53324 10.725 5.4999C10.8083 5.46657 10.9 5.4499 11 5.4499C11.1 5.4499 11.1917 5.46657 11.275 5.4999C11.3583 5.53324 11.4417 5.59157 11.525 5.6749L14.375 8.5249C14.525 8.6749 14.6 8.8499 14.6 9.0499C14.6 9.2499 14.525 9.4249 14.375 9.5749C14.225 9.7249 14.0458 9.7999 13.8375 9.7999C13.6292 9.7999 13.45 9.7249 13.3 9.5749L11.75 8.0499V14.4999H17.775C18.525 14.4999 19.1667 14.2332 19.7 13.6999C20.2333 13.1666 20.5 12.5249 20.5 11.7749C20.5 11.0249 20.2333 10.3832 19.7 9.8499C19.1667 9.31657 18.525 9.0499 17.775 9.0499H16.2V6.9499C16.2 5.46657 15.6958 4.19157 14.6875 3.1249C13.6792 2.05824 12.4333 1.5249 10.95 1.5249C9.46667 1.5249 8.21667 2.05824 7.2 3.1249C6.18333 4.19157 5.675 5.46657 5.675 6.9499H5.2C4.16667 6.9499 3.29167 7.3124 2.575 8.0374C1.85833 8.7624 1.5 9.65824 1.5 10.7249C1.5 11.7582 1.86667 12.6457 2.6 13.3874C3.33333 14.1291 4.21667 14.4999 5.25 14.4999H8C8.21667 14.4999 8.39583 14.5707 8.5375 14.7124C8.67917 14.8541 8.75 15.0332 8.75 15.2499C8.75 15.4666 8.67917 15.6457 8.5375 15.7874C8.39583 15.9291 8.21667 15.9999 8 15.9999H5.25Z"      fill={props?.fill ? props.fill : 'black'}
    />
  </svg>
);
export default SvgComponent;
