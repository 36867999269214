import PropTypes from 'prop-types';
import { Fragment, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { actionIcons, actionTypes } from 'utils/constants/customTable/actions';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import DownChevron from 'assets/DownChevron';
import ExpandedRow from './ExpandedRow';
import { fontSizes } from 'style/fontSizes';
import { breakpoints } from 'style/breakpoints';
import pxToRem from 'utils/helpers/pxToRem';
import CustomPagination from '../CustomPagination';
import CustomButton from '../CustomButton';
import buttonTypes from 'utils/constants/buttonTypes';
import NoRecordsFound from '../NoRecordsFound';
import RightArrow from "../../../assets/RightArrow";

const CustomTable = ({
  isExpandable,
  onExpandOnRow,
  onClickOnAction,
  isSelectable,
  actions = [],
  items = [],
  headers = [],
  className = '',
  isStriped,
  paginationData,
  onChangePage,
}) => {
  const theme = useTheme();

  // Using for onClickOutside functionality
  const tableRef = useRef();

  // Active row - row index on which user clicked
  const [activeIndex, setActiveIndex] = useState(-1);
  // Expanded row - row index on which user expanded (click on down arrow)
  const [expandedIndex, setExpandedIndex] = useState(-1);

  // Handler - when user expand some row
  const onExpandRow = (item, index) => {
    if (index === expandedIndex) {
      return setExpandedIndex(-1);
    }

    setExpandedIndex(index);

    if (typeof onExpandOnRow === 'function') {
      onExpandOnRow();
    }
  };

  // Handler - when user click on some action in table
  const onActiveAction = (actionType, item, index) => {
    if (typeof onClickOnAction === 'function') {
      onClickOnAction({ item, index, actionType });
    }
  };

  // Reset active and expand indexes when user click outside of table
  useOnClickOutside(tableRef, () => {
    setActiveIndex(-1);
    setExpandedIndex(-1);
  });

  // If not items prop or no headers prop
  if (items.length === 0 || headers.length === 0) {
    return <NoRecordsFound />;
  }

  return (
    <>
      <TableWrapper>
        <Table
          ref={tableRef}
          className={`table ${className}`}
        >
          <Thead>
            {/* Render all headers on the table head from the prop: headers.*/}
            <tr>
              {/* Render other headers from headers prop. */}
              {headers.map((header) => {
                return (
                  <th key={header.name}>
                    {header.displayName}
                  </th>
                );
              })}
              {/* Show this header only if table has expandable functionality. Passed prop isExpandable */}
              {isExpandable && <th></th>}
              {/* Adding header for actions if actions exist */}
              {actions.length > 0 && <th className="text-center">Akcija</th>}
            </tr>
            {items.length === 0 && (
              <tr>
                <td
                  colSpan={1000}
                  rowSpan={1000}
                >
                  <NoRecordsFound />
                </td>
              </tr>
            )}
          </Thead>

          <TBody
            isStriped={isStriped}
            isExpandable={isExpandable}
          >
            {/* Render all items from items array - order by headers */}
            {items.map((item, index) => {
              return (
                <Fragment key={item.id || index}>
                  <tr
                    {...(isExpandable && { onClick: () => onExpandRow(item, index) })}
                    className={
                      activeIndex === index && isSelectable ? 'active' : ''
                    }
                    key={index}
                  >
                    {/* Render other items from items array */}
                    {headers.map((header) => {
                      return <td key={header.name}>{item[header.name]}</td>;
                    })}
                    {/* Arrow icon which will be exist only if a table has expandable functionality. */}
                    {isExpandable && (
                      <TdArrow>
                        {index === expandedIndex ? (
                          <DownChevron
                            fill={theme.colors.table.arrowIcons}
                            width={10}
                          />
                        ) : (
                          <RightArrow
                            fill={theme.colors.table.arrowIcons}
                            width={6}
                          />
                        )}
                      </TdArrow>
                    )}

                    {/* Render all available actions from props actions */}
                    {actions.length > 0 && (
                    <TdAction>
                      {actions.map((action) => {
                        if (action !== actionTypes.scheduleUpdate) {
                          if (item.debitInfo !== 0) {
                            return (
                              <ActionIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onActiveAction(actionTypes[action], item, index);
                                }}
                                key={action}
                              >
                                {/* Get the icon for certain action */}
                                {actionIcons[action]({
                                  fill: theme.colors.icons.secondary,
                                  width: pxToRem(13),
                                  height: pxToRem(15),
                                })}
                              </ActionIcon>
                            );
                          }
                        } else {
                          return (
                            <CustomButton
                              width={pxToRem(120)}
                              height={pxToRem(25)}
                              type={buttonTypes.outlineStandard}
                              className="text-sm"
                              style={{ fontWeight: '400' }}
                              key={action}
                              disabled={item.isScheduleDisabled ? true : false}
                              onClick={() =>
                                onActiveAction(actionTypes[action], item, index)
                              }
                            >
                              Schedule Update
                            </CustomButton>
                          );
                        }
                      })}
                    </TdAction>
                        )}
                  </tr>

                  {/* When some row is expanded  */}
                  {index === expandedIndex && (
                    <tr className="expanded">
                      <TdExpanded colSpan="100">
                        <ExpandedRow
                          expandedRowText={item.expandedRowText}
                          expandedRowDateTime={item.expandedRowDateTime}
                        />
                      </TdExpanded>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </TBody>
        </Table>
      </TableWrapper>

      {paginationData && (
        <PaginationPlace>
          {paginationData.totalRecords === 0 ? (
            <PaginationInfo>Showed no item. </PaginationInfo>
          ) : (
            <PaginationInfo>
              Od {' '}
              {Number(
                paginationData.page * paginationData.pageSize -
                  (paginationData.pageSize - 1)
              )}{' '}
              do {' '}
              {paginationData.page * paginationData.pageSize >
              paginationData.totalRecords
                ? paginationData.totalRecords
                : paginationData.page * paginationData.pageSize}{' '}
              ukupno {paginationData.totalRecords} zapisa
            </PaginationInfo>
          )}
          <CustomPagination
            onChange={onChangePage}
            paginationData={paginationData}
          />
        </PaginationPlace>
      )}
    </>
  );
};

CustomTable.propTypes = {
  actions: PropTypes.array,
  className: PropTypes.string,
  expandedRowText: PropTypes.any,
  expandedRowDateTime: PropTypes.any,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // - represent the real property name in array items object
      displayName: PropTypes.string, // - represent the name which will be show on table header
      hasOrder: PropTypes.bool, // - represent is this header has order arrows
    })
  ),
  isExpandable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isStriped: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChangePage: PropTypes.func,
  onClickOnAction: PropTypes.func,
  onClickOnRow: PropTypes.func,
  onExpandOnRow: PropTypes.func,
  paginationData: PropTypes.shape({
    // Current page
    page: PropTypes.number,
    // Total number of records from db
    totalRecords: PropTypes.number,
    // Total page numbers - using only for show/hide next and prev icons - not for calculation
    pageNumbers: PropTypes.number,
    // Per page !
    pageSize: PropTypes.number,
  }),
  // onClickOnOrder: PropTypes.func,
};

const TableWrapper = styled.div`
  width: 100%;
  border-radius: 8px 8px 0 0;
`;

const Table = styled.table`
  position: relative;
  border-color: ${(props) => props.theme.colors.table.border};
  border-radius: 0 0 8px 8px;
  border-top: none;

  /* additions */
  white-space: nowrap;
`;

const Thead = styled.thead`
  font-style: normal;
  background-color: ${(props) => props.theme.colors.whiteColor};

  th {
    position: relative;
    padding: ${pxToRem(10)};
    border: none;
    font-size: ${fontSizes.sm};
    color: ${(props) => props.theme.colors.darkBlueColor};
    font-weight: 700;
    line-height: ${pxToRem(17)};
    letter-spacing: 0.1em;
    text-transform: uppercase;

    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;

    :first-child {
      border-top-left-radius: 8px;
    }
    :last-child {
      border-top-right-radius: 8px;
    }
  }
`;

const TBody = styled.tbody`
  background-color: ${(props) => props.theme.colors.table.bodyBackground};

  tr {
    &:nth-child(odd) {
      background: ${(props) =>
        props.isStriped ? props.theme.colors.table.striped : ''};
    }

    /* cursor: pointer; */
    :hover {
      /* background-color: ${(props) =>
        props.theme.colors.table.row.hoveredBg}; */
      /* opacity: 0.8; */
    }

    &.active {
      /* box-shadow: ${(props) =>
        `0px 1px 8px ${props.theme.colors.table.activeRow.boxShadow}`};

      :hover {
        background-color: transparent;
      } */
    }

    &.expanded {
      background-color: ${(props) => props.theme.colors.inputFocusColor};
    }
  }

  td {
    padding: ${pxToRem(10)};
    font-style: normal;
    font-size: ${fontSizes.md};
    color: ${(props) => props.theme.colors.lightBlackColor};
    vertical-align: middle;

    font-weight: 500;
    font-size: ${fontSizes.md};
    line-height: ${pxToRem(20)};

    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;

    &.tdActions {
      display: flex;
      justify-content: space-around;
      .actionIcon {
        :hover {
          opacity: 0.65;
        }
      }

      &.actionIcon {
        :hover {
          opacity: 0.65;
        }
      }
    }
  }
`;

const TdArrow = styled.td`
  user-select: none;
  height: 100%;
  width: ${pxToRem(30)};
  text-align: right;

  * {
    user-select: none;
  }

  @media ${breakpoints.xs} {
    text-align: left;
    max-width: 15px !important;
  }
`;

const TdAction = styled.td`
  display: flex;
  align-items: center;
  gap: ${pxToRem(15)};
  border: none;
  justify-content: center;
`;

const ActionIcon = styled.span`
  :hover {
    opacity: 0.65;
  }
  cursor: pointer;
`;

const TdExpanded = styled.td``;

const PaginationPlace = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${pxToRem(10)};
  margin-top: ${pxToRem(25)};
  padding: ${pxToRem(18)};
`;

const PaginationInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.xs};
`;

export default CustomTable;
